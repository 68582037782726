import React, { useEffect, useState } from "react";
import Search from "components/Search/Search";
import ActionHeader from "components/ActionHeader/ActionHeader";
import Table from "components/Table/Table";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { IDLE } from "constants/status";
import { useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import { selectAllStores } from "slices/storeSlice";
import { GROUPS_HEADINGS } from "constants/table-headings";
import { checkSearchQuery, getStoreCountFromUID } from "support/helpers";
import { ADD_GROUP_ROUTE, EDIT_GROUP_ROUTE } from "constants/routes";
import { useHistory } from "react-router";

const GroupManagementPage = () => {
  const groups = useSelector(selectAllStoreGroups);
  const stores = useSelector(selectAllStores);
  const groupsSubscriptionStatus = useSelector(
    (state) => state.storeGroups.status
  );
  const [filteredGoups, setFilteredGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();

  useEffect(() => {
    const preparedGroups = groups
      .map((group) => ({
        key: group.uid,
        values: {
          name: group.name,
          contact: group.contact.name,
          email: group.contact.email,
          storeCount: getStoreCountFromUID(group.uid, stores),
          status: group.isDisabled ? "Suspended" : "Active",
          action: (
            <ActionButton
              label="Edit"
              background="none"
              onClick={() =>
                history.push(EDIT_GROUP_ROUTE, { groupUID: group.uid })
              }
              type="button"
              icon="Pencil"
            />
          ),
        },
      }))
      .filter((group) => {
        const searchFields = [group.values.name, group.values.contact];
        return checkSearchQuery(searchQuery, searchFields);
      });
    setFilteredGroups(preparedGroups);
  }, [groups, stores, searchQuery, history]);

  return (
    <div id="group-management-page" className="h-full flex flex-col">
      {groupsSubscriptionStatus === IDLE ? (
        <div className="flex flex-grow justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <ActionHeader>
            <div className="flex justify-between w-full">
              <ActionButton
                label="Add group"
                onClick={() => history.push(ADD_GROUP_ROUTE)}
                type="button"
                icon="Add"
              />
              <div className="w-3/12 content-end flex-none">
                <Search
                  placeholder="Search for group or contact name"
                  onChange={(event) => setSearchQuery(event.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>
          </ActionHeader>
          <Table headings={GROUPS_HEADINGS} items={filteredGoups} />
        </>
      )}
    </div>
  );
};

export default GroupManagementPage;
