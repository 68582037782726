import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
};

const ErrorDialog = ({ title, description, onRetry }) => (
  <div className="bg-white rounded-md w-3/12 shadow">
    <div className="flex flex-grow flex-col p-4">
      <div className="text-xl font-bold border-b pb-2">{title}</div>
      <div className="pt-6">{description}</div>
    </div>
    <div className="flex p-2">
      <ActionButton
        className="m-2 w-full p-3 "
        onClick={onRetry}
        label="Retry"
      />
    </div>
  </div>
);

ErrorDialog.propTypes = propTypes;

export default ErrorDialog;
