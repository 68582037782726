export const CURTAIN = "curtain";
export const ROMAN_BLIND = "romanBlind";
export const ROMAN_BLIND_KIT = "romanBlindKit";
export const CUT_LENGTH = "cutLength";
export const CUSHION = "cushion";
export const VALANCE = "valance";
export const TIE_BACK = "tieBack";
export const JARDINIERE = "jardiniere";
export const ROLLER = "roller";
export const VERTICAL = "vertical";
export const ACCESSORIES = "accessories";

export const PRODUCT_SUBCATEGORY_SELECT = [
  { value: CURTAIN, label: "Curtain" },
  { value: ROMAN_BLIND, label: "Roman Blind" },
  { value: ROMAN_BLIND_KIT, label: "Roman Blind Kit" },
  { value: CUSHION, label: "Cushion" },
  { value: VALANCE, label: "Valance" },
  { value: TIE_BACK, label: "Tie Black" },
  { value: JARDINIERE, label: "Jardiniere" },
  { value: ROLLER, label: "Roller" },
  { value: VERTICAL, label: "Vertical" },
  { value: ACCESSORIES, label: "Accessories" },
];
