import React from "react";
import { Switch } from "react-router";
import Routes from "../../../config/routes";
import PublicRoute from "../PublicRoute/PublicRoute";
import PrivateRoute from "../PrivateRoute/PrivateRoute";

const RouteBuilder = () => {
  const routeMap = [...Routes.public, ...Routes.protected];

  return (
    <Switch>
      {Object.keys(routeMap).map((route) =>
        routeMap[route].isProtected ? (
          <PrivateRoute
            exact
            path={routeMap[route].path}
            component={routeMap[route].component}
            key={route}
            roles={routeMap[route].roles}
            showSidebar={routeMap[route].showSidebar}
          />
        ) : (
          <PublicRoute
            exact
            path={routeMap[route].path}
            component={routeMap[route].component}
            key={route}
          />
        )
      )}
    </Switch>
  );
};

export default RouteBuilder;
