import React, { useContext, useEffect, useState } from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import { useHistory, useLocation } from "react-router-dom";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { ADMIN, STORE_GROUP } from "constants/access-level";
import API from "services/API";
import FormGrid from "components/Form/FormGrid";
import { useSelector } from "react-redux";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import { showErrorToast, showSuccessToast } from "services/Toasts/toastService";
import { useDialog } from "providers/DialogProvider";
import { selectUserFromUID } from "slices/userSlice";
import { UserContext } from "providers/UserProvider";

const AddEditUserPage = () => {
  const location = useLocation();
  const history = useHistory();
  const userUID = location.state?.userUID;

  // Global states
  const groups = useSelector(selectAllStoreGroups);
  const user = useSelector((state) => selectUserFromUID(state, userUID));
  const [openDialog, closeDialog] = useDialog();
  const { currentUser } = useContext(UserContext);
  const isSelf = userUID === currentUser.uid;

  // Local states
  const [userType, setUserType] = useState({
    label: "Please select",
    value: "",
  });
  const [group, setGroup] = useState({
    label: "Please select",
    value: "",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [editing] = useState(userUID);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (editing && user) {
      setName(user.name);
      setEmail(user.emailAddress);
      setUserType(
        user.accessLevel === ADMIN
          ? { value: ADMIN, label: "Admin User" }
          : { value: STORE_GROUP, label: "Group User" }
      );
      if (user.accessLevel === STORE_GROUP) {
        const usersGroup = groups.find(
          (storeGroup) => storeGroup.uid === user.storeGroupUID
        );
        setGroup({
          value: usersGroup.uid,
          label: usersGroup.name,
        });
      }
    }
  }, [editing, user, groups]);

  const handleFormSubmission = async () => {
    setError(null);
    setPageLoading(true);
    try {
      const userData = {
        accessLevel: userType.value,
        emailAddress: email,
        name,
      };
      if (userType.value === STORE_GROUP) {
        userData.storeGroupUID = group.value;
      }
      if (editing) {
        userData.uid = userUID;
        if (userData.emailAddress !== user.emailAddress) {
          const authUpdates = {
            uid: userUID,
            updates: {
              email: userData.emailAddress,
            },
          };
          await API.users.updateAuthAccount(authUpdates);
        }
        await API.users.updateUserDocument(userData);
        if (userData.accessLevel === ADMIN) {
          await API.users.removeStoreGroupUID(userData);
        }
      } else {
        await API.users.createUser(userData);
      }
      history.goBack();
      const message = editing
        ? "The user was successfully updated."
        : "The user was successfully created. An email containing a link to set their password has been sent to the email address provided.";
      showSuccessToast("Success.", message, 5000);
    } catch (err) {
      setError(err.message);
      setPageLoading(false);
    }
  };

  const removeUser = async () => {
    try {
      await API.users.deleteUser(user);
      closeDialog();
      history.goBack();
      showSuccessToast("Success.", "The user was successfully removed.", 5000);
    } catch (err) {
      showErrorToast(
        "Error.",
        "An error occured whilst attempting to remove this user, please try again",
        5000
      );
    }
  };

  const fields = [
    {
      type: "select",
      startPosition: 1,
      span: 1,
      name: "userType",
      label: "User Type:",
      value: userType,
      onChange: setUserType,
      id: "userType",
      hidden: isSelf,
      options: [
        {
          value: ADMIN,
          label: "Admin User",
        },
        {
          value: STORE_GROUP,
          label: "Group User",
        },
      ],
    },
    {
      type: "select",
      startPosition: 2,
      span: 1,
      name: "group",
      label: "Group:",
      value: group,
      onChange: setGroup,
      id: "group",
      hidden: userType.value !== STORE_GROUP,
      options: groups.map((storeGroup) => ({
        value: storeGroup.uid,
        label: storeGroup.name,
      })),
    },
    {
      type: "text",
      startPosition: 1,
      span: 2,
      name: "name",
      label: "Name:",
      value: name,
      onChange: setName,
      id: "name",
      placeholder: "Name",
    },
    {
      type: "email",
      startPosition: 1,
      span: 2,
      name: "email",
      label: "Email Address:",
      value: email,
      onChange: setEmail,
      id: "email",
      placeholder: "Email Address",
    },
  ];

  return (
    <div id="add-edit-user-page">
      <ActionHeader>
        <div className="flex justify-between w-full">
          <ActionButton
            label={editing ? "Edit User" : "Add User"}
            onClick={() => history.goBack()}
            type="button"
            icon="BackArrow"
            iconSide="left"
            background="none"
            className="font-bold text-xl"
          />
          {editing && !isSelf && (
            <ActionButton
              label="Remove User"
              onClick={() =>
                openDialog({
                  title: "Remove User",
                  description: "Are you sure you want to remove this user?",
                  onSubmit: removeUser,
                  onClose: closeDialog,
                  submitButtonProps: {
                    background: "delete",
                    label: "Remove User",
                    icon: "Delete",
                  },
                })
              }
              type="button"
              icon="Delete"
              background="delete"
            />
          )}
        </div>
      </ActionHeader>

      <FormGrid
        fields={fields}
        onSubmit={handleFormSubmission}
        loading={pageLoading}
        error={error}
        setError={setError}
        buttonLabel={editing ? "Save Changes" : "Add User"}
      />
    </div>
  );
};

export default AddEditUserPage;
