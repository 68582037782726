import { ADDED, MODIFIED, REMOVED } from "constants/change-types";
import { ACTIVE } from "constants/status";
import {
  addStore,
  removeStore,
  updateStore,
  updateStoreStatus,
} from "slices/storeSlice";
import { firestore, functions } from "../../firebase";

const prepareStore = (doc) => {
  const store = doc.data();
  store.storeGroupUID = doc.ref.parent.parent.id;
  return store;
};

const handleDocumentChange = (dispatch, change) => {
  const store = prepareStore(change.doc);
  if (change.type === ADDED) {
    dispatch(addStore(store));
  }
  if (change.type === REMOVED) {
    dispatch(removeStore(store));
  }
  if (change.type === MODIFIED) {
    dispatch(updateStore(store));
  }
};

const subscribeToStores = (dispatch) =>
  firestore.collectionGroup("stores").onSnapshot((snapshot) => {
    if (snapshot.empty) {
      dispatch(updateStoreStatus(ACTIVE));
    }
    snapshot.docChanges().forEach((change) => {
      handleDocumentChange(dispatch, change);
    });
  });

const subscribeToStoresFromAGroup = (dispatch, storeGroupUID) =>
  firestore
    .collectionGroup("stores")
    .where("storeGroupUID", "==", storeGroupUID)
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        dispatch(updateStoreStatus(ACTIVE));
      }
      snapshot.docChanges().forEach((change) => {
        handleDocumentChange(dispatch, change);
      });
    });

const subscribeToStoreForUser = (dispatch, storeUID) =>
  firestore
    .collectionGroup("stores")
    .where("uid", "==", storeUID)
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        dispatch(updateStoreStatus(ACTIVE));
      }
      snapshot.docChanges().forEach((change) => {
        handleDocumentChange(dispatch, change);
      });
    });

const createStore = async (storeData) => {
  const onboardFunction = functions.httpsCallable("createStore");
  const payload = {
    storeData,
    continueUrl: window.location.origin,
  };
  return onboardFunction(payload);
};

const updateStoreDocument = async (storeData) => {
  firestore
    .collection("storeGroups")
    .doc(storeData.storeGroupUID)
    .collection("stores")
    .doc(storeData.uid)
    .set(storeData, { merge: true });
};

const deleteStore = async (storeData) => {
  const deleteFunction = functions.httpsCallable("deleteStoreAccount");
  return deleteFunction({
    storeUID: storeData.uid,
    storeGroupUID: storeData.storeGroupUID,
  });
};

const getStoreData = async (storeGroupUID, storeUID) => {
  const documentSnapShot = await firestore
    .collection("storeGroups")
    .doc(storeGroupUID)
    .collection("stores")
    .doc(storeUID)
    .get();
  return documentSnapShot.data();
};

export default {
  subscribeToStores,
  subscribeToStoresFromAGroup,
  subscribeToStoreForUser,
  createStore,
  updateStoreDocument,
  deleteStore,
  getStoreData,
};
