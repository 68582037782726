import React, { useState } from "react";
import PropTypes from "prop-types";
import { convertNumberToGBP, toTitleCase } from "support/helpers";
import {
  getFormFieldLabel,
  getSubcategoryName,
  sortFormFields,
} from "support/orderItemHelpers";
import FieldEntry from "components/ViewOrder/FieldEntry";
import { ROLLER, ROMAN_BLIND, ROMAN_BLIND_KIT } from "constants/product-subcategory";
import { QUANTITY, INSTALLATION_HEIGHT } from "constants/product-form-fields";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useDialog } from "providers/DialogProvider";
import { useDispatch, useSelector } from "react-redux";
import { removeOrderItem, selectOrder, updateOrderItem } from "slices/createOrderSlice";
import { useHistory } from "react-router";
import { PRODUCT_CONFIGURATION_ROUTE } from "constants/routes";
import { INPUT_TYPES } from "constants/form-input-types";
import { minInstallationHeight } from "constants/product-configuration-form-fields";

const propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  productCategory: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRemovable: PropTypes.bool,
};

const defaultProps = {
  isEditable: false,
  isRemovable: false,
};

const prepareDetails = (item, isEditable) => {
  const detailsArray = Object.keys(item.details)
    .sort(sortFormFields)
    .map((key) => ({
      label: getFormFieldLabel(key, item.productSubcategory),
      value: item.details[key],
    }));
  // Design and colour are not present for Roman Blind Kit
  if (item.productSubcategory !== ROMAN_BLIND_KIT) {
    detailsArray.unshift(
      {
        label: "Design",
        value: item.fabric.design,
      },
      {
        label: "Colour",
        value: item.fabric.color,
      }
    );
  }
  detailsArray.push({
    label: getFormFieldLabel(QUANTITY, item.productSubcategory),
    value: item.quantity,
  });
  if (item.installationHeight && !isEditable) {
    detailsArray.push({
      label: getFormFieldLabel(INSTALLATION_HEIGHT, item.installationHeight),
      value: item.installationHeight
    });
  }
  return detailsArray;
};

export const subcategoriesWithInstallationHeight = [ROMAN_BLIND, ROMAN_BLIND_KIT, ROLLER];

const Item = ({ item, productCategory, isEditable, isRemovable }) => {
  const [installationHeight, setInstallationHeight] = useState(item.installationHeight || "");
  const order = useSelector(selectOrder);
  const [openDialog, closeDialog] = useDialog();
  const dispatch = useDispatch();
  const history = useHistory();

  const removeProduct = () => {
    dispatch(removeOrderItem(item));
    closeDialog();
  };
  const handleInstallationHeightChange = (value) => {
    setInstallationHeight(value);
    dispatch(updateOrderItem({ ...item, installationHeight: value }));
  };

  const isInstallationHeightRequired = () => {
    return order.installationRequired && subcategoriesWithInstallationHeight.includes(item.productSubcategory);
  };

  const isFieldValid = () => {
    return parseFloat(installationHeight) >= parseFloat(minInstallationHeight);
  };

  return (
    <div className="rounded-md bg-white shadow mb-9">
      <div className="p-4 text-lg font-bold border-b flex justify-between">
        <div>
          {toTitleCase(productCategory)}{" "}
          {getSubcategoryName(item.productSubcategory)}
        </div>
        <div>{convertNumberToGBP(item.price)}</div>
      </div>
      <div className="grid grid-cols-3 gap-6 p-4">
        {prepareDetails(item, isEditable).map((entry) => (
          <FieldEntry key={`${entry.label}${entry.values}`} entry={entry}/>
        ))}
        {isInstallationHeightRequired() && (<div>
          <label className="font-bold" htmlFor={item.uid}>Installation Height (cm):</label>
          <input
            className={`w-full border border rounded  pl-2 py-2 ${!isFieldValid() ? "border-red-600" : ""}`}
            id={item.uid}
            onChange={(e) => handleInstallationHeightChange(e.target.value)}
            value={installationHeight}
            type={INPUT_TYPES.NUMBER}
            name="Installation Height"/>
          <div className={`text-sm ${!isFieldValid() ? "text-red-600" : ""}`}>Min Height: 180cm</div>
        </div>)}
      </div>
      {isEditable && (
        <div className="border-t flex">
          {isRemovable && (
            <ActionButton
              label="Remove"
              background="none"
              onClick={() =>
                openDialog({
                  title: "Remove Product",
                  description:
                    "Are you sure you want to remove this product? This action cannot be undone.",
                  onSubmit: removeProduct,
                  onClose: closeDialog,
                  submitButtonProps: {
                    background: "delete",
                    label: "Remove Product",
                  },
                })
              }
              icon="DeleteRed"
              className="text-scarlet-red border-r h-16 flex-grow"
              rounded={false}
            />
          )}
          <ActionButton
            label="Edit"
            background="none"
            onClick={() =>
              history.push(PRODUCT_CONFIGURATION_ROUTE, {
                orderItem: item,
                productSubcategory: item.productSubcategory,
                editing: true,
              })
            }
            icon="Pencil"
            rounded={false}
            className="h-16 flex-grow"
          />
        </div>
      )}
    </div>
  );
};

Item.propTypes = propTypes;

Item.defaultProps = defaultProps;

export default Item;
