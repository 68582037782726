import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import FinesseWhiteLogo from "components/Logo/FinesseWhiteLogo";
import Icon from "components/Icon/Icon";
import { auth } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { SIDEBAR_ITEMS } from "../../constants/sidebar-items";
import ActionButton from "../Buttons/ActionButton/ActionButton";

const Sidebar = () => {
  const { currentUser } = useContext(UserContext);
  const [sidebarItems, setSidebarItems] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setSidebarItems(SIDEBAR_ITEMS[currentUser.data.accessLevel]);
  }, [currentUser]);

  return (
    <div id="sidebar" className="sidebar w-1/6 bg-action p-9 relative">
      <FinesseWhiteLogo className="mb-10" />

      <ul>
        {sidebarItems.map((item) => (
          <li key={item.label}>
            {item.type === "button" ? (
              <ActionButton
                background="white"
                label={item.label}
                className="w-full p-2 mt-2.5"
                onClick={() => history.push(item.route)}
              />
            ) : (
              <NavLink
                exact
                activeClassName="is-active font-bold opacity-100"
                className="flex-1 flex items-center py-5 text-lg text-white opacity-50"
                to={item.route}
              >
                <span className="flex-1">{item.label}</span>
                <Icon type={item.iconName} className="ml-4 flex-shrink-0" />
              </NavLink>
            )}
          </li>
        ))}
      </ul>

      <button
        onClick={() => auth.signOut()}
        type="button"
        className="flex-1 flex items-center text-white left-0 bottom-10 w-full px-9 absolute text-lg"
      >
        <span className="flex-1 text-left">Log out</span>
        <Icon type="Logout" className="flex-shrink-0" />
      </button>
    </div>
  );
};

export default Sidebar;
