import {
  CENTER_SPLIT,
  CHAIN_COLOR,
  COLOR,
  CONTROL_POSITION,
  DROP,
  EXACT_DROP,
  EXACT_WIDTH,
  FIX_TYPE,
  INSTALLATION_HEIGHT,
  PAIR_SINGLE,
  PRODUCT_FORM_FIELD_PRIORITY,
  QUANTITY,
  WIDTH,
} from "constants/product-form-fields";
import {
  CURTAIN,
  CUT_LENGTH,
  JARDINIERE,
  ROLLER,
  ROMAN_BLIND,
  ROMAN_BLIND_KIT,
  TIE_BACK,
  VERTICAL,
} from "constants/product-subcategory";
import { toTitleCase } from "./helpers";
import dayjs from "dayjs";

export function getSubcategoryName(subcategory) {
  switch (subcategory) {
    case CUT_LENGTH:
      return "Cut Length";
    case ROMAN_BLIND:
      return "Roman Blind";
    case ROMAN_BLIND_KIT:
      return "Roman Blind Kit";
    case TIE_BACK:
      return "Tie Back";
    default:
      return toTitleCase(subcategory);
  }
}

export function getQuantityFieldLabel(productSubcategory) {
  switch (productSubcategory) {
    case CURTAIN:
    case JARDINIERE:
      return `Number of ${productSubcategory}s`;
    case ROMAN_BLIND:
    case ROMAN_BLIND_KIT:
    case ROLLER:
    case VERTICAL:
      return "Number of blinds";
    case TIE_BACK:
      return "Quantity of pairs";
    default:
      return "Quantity";
  }
}

export function getFormFieldLabel(field, productSubcategory) {
  switch (field) {
    case COLOR:
      return "Colour";
    case DROP:
    case WIDTH:
      return `${toTitleCase(field)} (cm)`;
    case EXACT_DROP:
    case EXACT_WIDTH:
      return `Exact Recess ${field.split("exact")[1]} (cm)`;
    case QUANTITY:
      return getQuantityFieldLabel(productSubcategory);
    case CHAIN_COLOR:
      return "Chain Colour";
    case CONTROL_POSITION:
      return "Control Position";
    case INSTALLATION_HEIGHT:
      return "Installation Height (cm)";
    case FIX_TYPE:
      return "Fix Type";
    case CENTER_SPLIT:
      return "Centre Split";
    case PAIR_SINGLE:
      return "Pair/Single";
    default:
      return toTitleCase(field);
  }
}

export function sortFormFields(a, b) {
  return (
    PRODUCT_FORM_FIELD_PRIORITY.indexOf(a) -
    PRODUCT_FORM_FIELD_PRIORITY.indexOf(b)
  );
}

export function getMinInstallationDate(category, slaDurations) {
  return dayjs(new Date(Date.now() + slaDurations?.[category])).format('YYYY-MM-DD');
}

export default {
  getSubcategoryName,
  getQuantityFieldLabel,
  getFormFieldLabel,
  sortFormFields,
  getMinInstallationDate
};
