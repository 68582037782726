import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  lineOneText: PropTypes.string,
  lineTwoText: PropTypes.string,
  button: PropTypes.node.isRequired,
  error: PropTypes.instanceOf(Object),
};

const defaultProps = {
  lineOneText: null,
  lineTwoText: null,
  error: null,
};

const OrderBottomBar = ({ lineOneText, lineTwoText, button, error }) => (
  <div>
    {error && (
      <div className="bg-red-500 px-9 py-4 text-white text-center">
        {error.message}
      </div>
    )}
    <div className="bg-white w-full py-7 px-9 flex justify-between items-center">
      <div className="flex flex-col ">
        <div className="text-lg mb-2">{lineOneText}</div>
        <div className="text-3xl font-bold">{lineTwoText}</div>
      </div>
      {button}
    </div>
  </div>
);

OrderBottomBar.propTypes = propTypes;

OrderBottomBar.defaultProps = defaultProps;

export default OrderBottomBar;
