import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import API from "services/API";
import { useDispatch } from "react-redux";
import { resetStoreGroupState } from "slices/storeGroupSlice";
import { resetUserState } from "slices/userSlice";
import { resetOrderState } from "slices/orderSlice";
import { resetStoreState } from "slices/storeSlice";
import { resetConfigState } from "slices/configSlice";
import {
  addPricingConfigJson,
  addProductConfigJson,
  addSlaDurationsConfig,
  updateProductPricingConfigSliceStatus,
} from "slices/productPricingConfigSlice";
import { UserContext } from "../../providers/UserProvider";
import { ADMIN, STORE, STORE_GROUP } from "../../constants/access-level";
import { CONFIRMED, ERROR, SAVED, SUCCESS } from "../../constants/status";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

const FirestoreWrapper = ({ children }) => {
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();

  useEffect(() => {
    const subscriptions = [];

    const retrieveProductPricingConfigData = async (userData) => {
      try {
        const userGroupData = await API.storeGroups.getGroupData(
          userData.storeGroupUID
        );
        const userConfigData = await API.configs.getConfigVersionData(
          userGroupData.configVersionUID
        );

        Object.keys(userConfigData.pricing).forEach((category) => {
          const categoryData = userConfigData.pricing[category];
          Object.keys(categoryData).forEach(async (subcategory) => {
            const timestamp = categoryData[subcategory];
            const configJson = await API.configs.getConfigJsonFile(
              timestamp,
              userGroupData.configVersionUID,
              "pricing",
              category,
              subcategory
            );
            dispatch(
              addPricingConfigJson({
                category,
                subcategory,
                configJson,
              })
            );
          });
        });

        Object.keys(userConfigData.product).forEach(async (category) => {
          const timestamp = userConfigData.product[category];
          const configJson = await API.configs.getConfigJsonFile(
            timestamp,
            userGroupData.configVersionUID,
            "product",
            category
          );
          dispatch(
            addProductConfigJson({
              category,
              configJson,
            })
          );
        });

        dispatch(addSlaDurationsConfig(userConfigData.slaDurations));
        dispatch(updateProductPricingConfigSliceStatus(SUCCESS));
      } catch (error) {
        dispatch(updateProductPricingConfigSliceStatus(ERROR));
      }
    };

    if (currentUser?.data?.accessLevel === ADMIN) {
      subscriptions.push({
        unsubscribe: API.storeGroups.subscribeToStoreGroups(dispatch),
        resetState: resetStoreGroupState(),
      });
      subscriptions.push({
        unsubscribe: API.users.subscribeToUsers(dispatch),
        resetState: resetUserState(),
      });
      subscriptions.push({
        unsubscribe: API.stores.subscribeToStores(dispatch),
        resetState: resetStoreState(),
      });
      subscriptions.push({
        unsubscribe: API.configs.subscribeToConfigs(dispatch),
        resetState: resetConfigState(),
      });
      API.orders.getOrdersForAllStoresByStatus(dispatch, CONFIRMED, true);
      API.orders.getOrdersForAllStoresByStatus(dispatch, SAVED, true);
    }

    if (currentUser?.data?.accessLevel === STORE) {
      const userData = currentUser.data;
      subscriptions.push({
        unsubscribe: API.stores.subscribeToStoresFromAGroup(
          dispatch,
          userData.storeGroupUID,
        ),
        resetState: resetStoreState(),
      });
      API.orders.getOrdersFromAStoreByStatus(
        dispatch,
        CONFIRMED,
        userData.storeGroupUID,
        userData.storeUID,
        true
      );
      API.orders.getOrdersFromAStoreByStatus(
        dispatch,
        SAVED,
        userData.storeGroupUID,
        userData.storeUID,
        true
      );
      retrieveProductPricingConfigData(userData);
    }

    if (currentUser?.data?.accessLevel === STORE_GROUP) {
      const userData = currentUser.data;
      subscriptions.push({
        unsubscribe: API.stores.subscribeToStoresFromAGroup(
          dispatch,
          userData.storeGroupUID
        ),
        resetState: resetStoreState(),
      });
      API.orders.getOrdersFromAGroupByStatus(
        dispatch,
        CONFIRMED,
        userData.storeGroupUID,
        true
      );
    }

    return function cleanup() {
      subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
        dispatch(subscription.resetState);
      });

      dispatch(resetOrderState());
    };
  }, [currentUser, dispatch]);

  return <>{children}</>;
};

FirestoreWrapper.propTypes = propTypes;

export default FirestoreWrapper;
