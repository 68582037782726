import { PINCH_PLEAT_WIDTH_BANDS } from "constants/pinch-pleat-width-bands";
import { BLIND, FABRIC, SHEER } from "constants/product-categories";
import {
  DROP,
  EDGE,
  HEADING,
  LINING,
  SIZE,
  WIDTH,
} from "constants/product-form-fields";
import {
  CURTAIN,
  CUSHION,
  CUT_LENGTH,
  JARDINIERE,
  ROLLER,
  ROMAN_BLIND,
  ROMAN_BLIND_KIT,
  TIE_BACK,
  VALANCE,
  VERTICAL,
} from "constants/product-subcategory";

const variableDetails = {
  [DROP]: {
    key: DROP,
    type: "range",
    minKey: "minDrop",
    maxKey: "maxDrop",
  },
  [WIDTH]: {
    key: WIDTH,
    type: "range",
    minKey: "minWidth",
    maxKey: "maxWidth",
  },
  [HEADING]: {
    key: HEADING,
    type: "equal",
  },
  [LINING]: {
    key: LINING,
    type: "equal",
  },
  [SIZE]: {
    key: SIZE,
    type: "equal",
  },
  [EDGE]: {
    key: EDGE,
    type: "equal",
  },
};

const requiredVariablesForProducts = {
  [SHEER]: {
    [CURTAIN]: [DROP, HEADING, LINING],
    [JARDINIERE]: [DROP, WIDTH],
    [ROMAN_BLIND]: [DROP, WIDTH, LINING],
    [CUT_LENGTH]: [],
  },
  [FABRIC]: {
    [CURTAIN]: [DROP, WIDTH, HEADING, LINING],
    [ROMAN_BLIND]: [DROP, WIDTH, LINING],
    [ROMAN_BLIND_KIT]: [WIDTH],
    [CUT_LENGTH]: [],
    [VALANCE]: [WIDTH, HEADING],
    [TIE_BACK]: [SIZE, EDGE],
    [CUSHION]: [SIZE, EDGE],
  },
  [BLIND]: {
    [ROLLER]: [DROP, WIDTH],
    [VERTICAL]: [DROP, WIDTH],
  },
};

export const generateProductPrice = (
  category,
  subcategory,
  formValues,
  designPricing
) => {
  const requiredVariables = requiredVariablesForProducts[category][
    subcategory
  ].map((variable) => variableDetails[variable]);

  try {
    const priceObject = designPricing.find((design) =>
      requiredVariables.every((variable) => {
        const { key, type, minKey, maxKey } = variable;
        let value = formValues[key];

        if (key === WIDTH && subcategory === JARDINIERE) {
          value *= 2.2;
        }

        return type === "equal"
          ? design[key] === value
          : design[minKey] <= value && design[maxKey] >= value;
      })
    );

    if (priceObject) {
      const { price } = priceObject;

      if (category === SHEER && subcategory === CURTAIN) {
        let { width } = formValues;

        if (formValues[HEADING].includes("Eyelet")) {
          const baseValue = Math.round((width * 2) / 36 + 0.5);
          width = baseValue * 36;
        }

        if (formValues[HEADING].includes("Pinch Pleat")) {
          width = PINCH_PLEAT_WIDTH_BANDS.find(
            (widthBand) => widthBand.min <= width && widthBand.max >= width
          ).value;
        }

        const priceMultiplier = width / 100;
        return price * priceMultiplier;
      }

      return price;
    }

    throw new Error("Price object was not found");
  } catch (error) {
    throw new Error(
      "The configuration you've entered is not available, please try again. If the problem persists, please contact the made to measure team."
    );
  }
};

export default {
  generateProductPrice,
};
