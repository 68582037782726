import React, { useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";

const propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.instanceOf(Object),
  closeButtonProps: PropTypes.instanceOf(Object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputType: PropTypes.oneOf(['textarea', 'text', 'number', 'email', 'tel']),
  isValid: PropTypes.func,
};

const defaultProps = {
  value: "",
  inputType: 'textarea',
  submitButtonProps: {
    label: "Submit",
    type: "button",
  },
  closeButtonProps: {
    label: "Cancel",
    type: "button",
    background: "outline",
  },
  isValid: () => true,
};

const InputDialog = ({
  title,
  onSubmit,
  onClose,
  submitButtonProps,
  closeButtonProps,
  value,
  inputType,
  isValid
}) => {
  const [inputValue, setInputValue] = useState(value);

  return (
    <div className="bg-white rounded-md w-3/12 shadow">
      <div className="flex flex-grow flex-col p-4">
        <div className="text-xl font-bold border-b pb-2">{title}</div>
      </div>
      <div className="w-full px-4 py-2">
        {(inputType === "textarea") ? <textarea
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          className="w-full border p-2 h-36"
        /> : <input
          value={inputValue}
          type={inputType}
          onChange={(event) => setInputValue(event.target.value)}
          className="w-full border p-2 "
        />}
      </div>

      <div className="flex p-2">
        <ActionButton
          className="m-2 w-full p-3"
          onClick={onClose}
          {...closeButtonProps}
        />
        <ActionButton
          className="m-2 w-full p-3"
          disabled={!isValid(inputValue)}
          onClick={() => onSubmit(inputValue)}
          {...submitButtonProps}
        />
      </div>
    </div>
  );
};

InputDialog.propTypes = propTypes;

InputDialog.defaultProps = defaultProps;

export default InputDialog;
