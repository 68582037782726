import ActionButton from "components/Buttons/ActionButton/ActionButton";
import FormSelect from "components/Form/FormSelect";
import OrderBottomBar from "components/OrderBottomBar/OrderBottomBar";
import OrderCreationHeader from "components/OrderCreationHeader/OrderCreationHeader";
import { COLOR, DESIGN } from "constants/product-form-fields";
import {
  ORDER_DETAILS_ROUTE,
  ORDER_SUMMARY_ROUTE,
  SELECT_PRODUCT_ROUTE,
} from "constants/routes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  replaceOrderItems,
  selectOrderCompletionState,
} from "slices/createOrderSlice";
import { selectProductConfig } from "slices/productPricingConfigSlice";
import { toTitleCase } from "support/helpers";
import { v4 as uuidv4 } from "uuid";

const SwatchConfigurationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { productSubcategory, orderItems, editing } = location.state ?? {};
  const categoryProductConfig = useSelector((state) =>
    selectProductConfig(state, productSubcategory)
  );
  const dispatch = useDispatch();
  const orderComplete = useSelector(selectOrderCompletionState);

  const generateSwatch = () => ({ design: null, color: null, uid: uuidv4() });
  const [swatches, setSwatches] = useState([generateSwatch()]);

  useEffect(() => {
    if (orderItems) {
      setSwatches(
        orderItems.map((item) => ({
          design: item.fabric.design,
          color: item.fabric.color,
          uid: item.uid,
        }))
      );
    }
  }, [orderItems]);

  const addSwatch = () =>
    setSwatches((prevState) => [...prevState, generateSwatch()]);

  const removeSwatch = (swatchUID) => {
    setSwatches((prevState) =>
      prevState.filter((swatch) => swatch.uid !== swatchUID)
    );
  };

  const inputChange = (index, key, value) => {
    const newState = [...swatches];
    if (key === DESIGN && swatches[index][DESIGN] !== value) {
      newState[index].color = null;
    }
    newState[index][key] = value;
    setSwatches(newState);
  };

  const getSelectionValue = (value) =>
    value
      ? {
          value,
          label: value,
        }
      : null;

  const getColorOptions = (selectedDesign) => {
    const { colors } = categoryProductConfig.designs.find(
      (design) => design.name === selectedDesign
    );
    return colors.map((color) => getSelectionValue(color));
  };

  const isSwatchFormInvalid = () =>
    swatches.some((swatch) => swatch.design === null || swatch.color === null);

  const addSwatchesToOrder = () => {
    const itemsToAdd = swatches.map((swatch) => ({
      fabric: {
        design: swatch.design,
        color: swatch.color,
      },
      productSubcategory,
      uid: swatch.uid,
      price: 0,
      quantity: 1,
      details: {},
    }));
    dispatch(replaceOrderItems(itemsToAdd));
    history.push(orderComplete ? ORDER_SUMMARY_ROUTE : ORDER_DETAILS_ROUTE);
  };

  return (
    <div className="flex flex-col h-full justify-between">
      <OrderCreationHeader showCancelButton>
        {!editing && (
          <ActionButton
            icon="BackArrow"
            iconSide="left"
            label="Back to Product Selection"
            background="outline"
            onClick={() => history.push(SELECT_PRODUCT_ROUTE)}
            className="px-14 h-full absolute"
          />
        )}
        <div className="w-full text-center text-lg font-bold">
          {`${toTitleCase(productSubcategory)} Swatches`}
        </div>
      </OrderCreationHeader>
      <div className="p-9 flex-grow overflow-y-auto">
        <div className="mb-10">
          You can select up to a maximum of 6 swatches, completely free of
          charge.
        </div>
        {swatches.map((swatch, index) => (
          <div className="grid grid-cols-3 gap-6 mb-10" key={swatch.uid}>
            <FormSelect
              name={DESIGN}
              label="Fabric Design"
              options={categoryProductConfig.designs.map((design) =>
                getSelectionValue(design.name)
              )}
              value={getSelectionValue(swatch.design)}
              onChange={(selectedValue) =>
                inputChange(index, DESIGN, selectedValue.value)
              }
            />
            <FormSelect
              name={COLOR}
              label="Fabric Color"
              options={swatch.design ? getColorOptions(swatch.design) : []}
              value={getSelectionValue(swatch.color)}
              onChange={(selectedValue) =>
                inputChange(index, COLOR, selectedValue.value)
              }
              disabled={!swatch.design}
            />
            <div className="flex">
              {swatches.length > 1 && (
                <ActionButton
                  label="Remove"
                  onClick={() => removeSwatch(swatch.uid)}
                  type="button"
                  icon="DeleteRed"
                  background="none"
                  className="self-end p-3 text-scarlet-red"
                />
              )}
            </div>
          </div>
        ))}
        {swatches.length < 6 && (
          <ActionButton
            label="Add Another Design +"
            onClick={addSwatch}
            type="submit"
            background="dashed"
            className="p-3"
          />
        )}
      </div>
      <OrderBottomBar
        button={
          <ActionButton
            label={editing ? "Save Changes" : "Add to order"}
            background="action"
            icon="Cart"
            iconSide="left"
            onClick={addSwatchesToOrder}
            className="px-12 h-16"
            disabled={isSwatchFormInvalid()}
          />
        }
      />
    </div>
  );
};

export default SwatchConfigurationPage;
