import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE, IDLE } from "constants/status";

const initialState = {
  users: [],
  status: IDLE,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUser(state, action) {
      state.status = ACTIVE;
      state.users.push(action.payload);
    },
    removeUser(state, action) {
      state.users = state.users.filter(
        (user) => user.uid !== action.payload.uid
      );
    },
    updateUser(state, action) {
      state.users = state.users.map((user) =>
        user.uid === action.payload.uid ? action.payload : user
      );
    },
    updateUserStatus(state, action) {
      state.status = action.payload;
    },
    resetUserState: () => initialState,
  },
});

export const {
  addUser,
  removeUser,
  updateUser,
  updateUserStatus,
  resetUserState,
} = userSlice.actions;

export const selectAllUsers = (state) => state.users.users;

export const selectUserFromUID = (state, userUID) =>
  state.users.users.find((user) => user.uid === userUID);

export default userSlice.reducer;
