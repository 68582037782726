import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastContent from "services/Toasts/toastContent";

export const showSuccessToast = (title, content, duration) =>
  toast.success(<ToastContent title={title} content={content} />, {
    autoClose: duration,
  });

export const showErrorToast = (title, content, duration) =>
  toast.error(<ToastContent title={title} content={content} />, {
    autoClose: duration,
  });

export default {
  showSuccessToast,
  showErrorToast,
};
