import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE, IDLE } from "constants/status";

const initialState = {
  configs: [],
  status: IDLE,
};

export const configSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    addConfig(state, action) {
      state.status = ACTIVE;
      state.configs.push(action.payload);
    },
    removeConfig(state, action) {
      state.configs = state.configs.filter(
        (config) => config.uid !== action.payload.uid
      );
    },
    updateConfig(state, action) {
      state.configs = state.configs.map((config) =>
        config.uid === action.payload.uid ? action.payload : config
      );
    },
    updateConfigSliceStatus(state, action) {
      state.status = action.payload;
    },
    resetConfigState: () => initialState,
  },
});

export const {
  addConfig,
  removeConfig,
  updateConfig,
  updateConfigSliceStatus,
  resetConfigState,
} = configSlice.actions;

export const selectAllConfigs = (state) => state.configs.configs;

export const selectConfigFromUID = (state, configUID) =>
  state.configs.configs.find((config) => config.uid === configUID);

export default configSlice.reducer;
