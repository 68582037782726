import React from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useHistory, useLocation } from "react-router";
import { CONFIGURATIONS_ROUTE } from "constants/routes";
import {
  PRICING_CONFIGURATION_INPUTS,
  PRODUCT_CONFIGURATION_INPUTS,
} from "constants/pricing-product-configs";
import { useSelector } from "react-redux";
import { selectConfigFromUID } from "slices/configSlice";
import ConfigRow from "components/ConfigRow/ConfigRow";
import { useDialog } from "providers/DialogProvider";
import { showErrorToast, showSuccessToast } from "services/Toasts/toastService";
import API from "services/API";
import { PRODUCT_OPTIONS } from "constants/product-options";
import {
  ALL_VARIABLES,
  SUBCATEGORY_REQUIRED_VARIABLES,
} from "constants/price-variables";
import { daysToMilliseconds, millisecondsToDays } from "support/helpers";

const EditConfigurationPage = () => {
  const location = useLocation();
  const history = useHistory();
  const configUID = location.state?.configUID;

  // Global state
  const config = useSelector((state) => selectConfigFromUID(state, configUID));
  const [openDialog, closeDialog, setDialogLoading] = useDialog();

  const processConfig = async (file, type, categoryKey, subcategoryKey) => {
    setDialogLoading(true);
    try {
      if (type === "pricing") {
        const priceVariables = ALL_VARIABLES.filter((variable) =>
          SUBCATEGORY_REQUIRED_VARIABLES[categoryKey][subcategoryKey].includes(
            variable.name
          )
        );
        await API.configs.updatePricingConfig(
          file,
          priceVariables,
          categoryKey,
          subcategoryKey,
          configUID
        );
      } else {
        const options = PRODUCT_OPTIONS[categoryKey];
        await API.configs.updateProductConfig(
          file,
          options,
          categoryKey,
          configUID
        );
      }
      closeDialog();
      showSuccessToast("Success.", "The file was successfully uploaded.", 5000);
    } catch (error) {
      setDialogLoading(false);
      showErrorToast(
        "CSV processing failed.",
        "Please ensure the correct template has been used and try again"
      );
    }
  };

  const updateConfigRecord = async (categoryKey, valueInDays, categoryLabel) => {
    setDialogLoading(true);
    const valueInMs = daysToMilliseconds(valueInDays)
    const configData = {uid: configUID, slaDurations: {[categoryKey]: valueInMs}}
    try {
      await API.configs.updateConfigRecord(configData)
      closeDialog();
      showSuccessToast("Success.", `${categoryLabel} SLA was successfully updated`, 5000);
    } catch (error) {
      setDialogLoading(false);
      showErrorToast(
        "Error",
        "An error occurred whilst updating the product SLA, please try again"
      );
    }
  }

  const openUploadDialog = (title, type, categoryKey, subcategoryKey) =>
    openDialog({
      type: "upload",
      title,
      onSubmit: (file) =>
        processConfig(file, type, categoryKey, subcategoryKey),
      onClose: closeDialog,
      submitButtonProps: {
        label: "Upload",
      },
      acceptedFileTypes: [".csv", "application/vnd.ms-excel", "text/csv"],
    });

  const openSlaDialog = (title, slaValue, categoryKey) => openDialog({
    type: "input",
    title: `Update ${title} SLA`,
    inputType: "number",
    value: slaValue,
    onSubmit: (value) => updateConfigRecord(categoryKey, value, title),
    onClose: closeDialog,
    submitButtonProps: {
      label: "Update"
    },
    isValid: (value) => value >= 0 && value < 1000,
  })

  const getLastUpdated = (type, category, subcategory) => {
    const configCategory = config[type][category];
    if (type === "pricing") {
      return configCategory ? configCategory[subcategory] : configCategory;
    }
    return configCategory;
  };

  const getSlaValue = (categoryKey) => {
    return millisecondsToDays(config.slaDurations?.[categoryKey] || 0)
  }
  return (
    <div id="add-edit-configuration-page" className="h-full flex flex-col">
      <div className="flex flex-col flex-grow overflow-y-auto min-h-0">
        <ActionHeader>
          <div className="flex justify-start">
            <ActionButton
              label={`Edit ${config.name} Configuration`}
              onClick={() => history.push(CONFIGURATIONS_ROUTE)}
              type="button"
              icon="BackArrow"
              iconSide="left"
              background="none"
              className="font-bold text-xl"
            />
          </div>
        </ActionHeader>

        <div className="grid grid-cols-4 gap-6 p-9">
          <div className="col-start-1 col-span-4">
            <div className="border-b border-secondary pb-2 pt-4 mb-6 text-lg font-bold">
              Pricing Configuration
            </div>

            {PRICING_CONFIGURATION_INPUTS.map((category) => (
              <div key={category.key} className="mb-6">
                <div className="font-bold mb-2">{category.label}</div>
                {category.subcategories.map((subcategory) => (
                  <ConfigRow
                    key={subcategory.key}
                    label={subcategory.label}
                    lastUpdated={getLastUpdated(
                      "pricing",
                      category.key,
                      subcategory.key
                    )}
                    onHandlePrimaryAction={() =>
                      openUploadDialog(
                        subcategory.label,
                        "pricing",
                        category.key,
                        subcategory.key
                      )
                    }
                  />
                ))}
              </div>
            ))}
          </div>

          <div className="col-start-1 col-span-4">
            <div className="border-b border-secondary pb-2 pt-4 mb-6 text-lg font-bold">
              Product Configuration
            </div>

            {PRODUCT_CONFIGURATION_INPUTS.map((category) => (
              <ConfigRow
                key={category.key}
                label={category.label}
                lastUpdated={getLastUpdated("product", category.key)}
                onHandlePrimaryAction={() =>
                  openUploadDialog(category.label, "product", category.key)
                }
                onHandleSecondaryAction={() => openSlaDialog(category.label, getSlaValue(category.key), category.key)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditConfigurationPage;
