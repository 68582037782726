import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAnalytics } from "slices/analyticsSlice";
import API from "services/API";
import FormSelect from "components/Form/FormSelect";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import { PRODUCT_SUBCATEGORY_SELECT } from "constants/product-subcategory";
import * as _ from "lodash";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import Spinner from "components/Spinner/Spinner";
import DatePicker from "react-datepicker";
import { showErrorToast } from "services/Toasts/toastService";
import { IDLE } from "constants/status";
import { Bar } from "react-chartjs-2";
import {
  backgroundColors,
  fabricColors,
  detailChartOptions,
  fabricChartOptions,
  colorChartOptions
} from "config/charts";

const AnalyticsPage = () => {
  const analytics = useSelector(selectAnalytics);
  const groups = useSelector(selectAllStoreGroups);
  const analyticsStatus = useSelector(state => state.analytics.status);

  const [analyticsData, setAnalyticsData] = useState({});
  const [filtersForm, setFiltersForm] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentFabricIndex, setCurrentFabricIndex] = useState(-1);

  const dispatch = useDispatch();

  const sortedSubcategories = _.sortBy(PRODUCT_SUBCATEGORY_SELECT, ["label"]);
  const currencyFormatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP"
  });

  const generalOrderFields = [
    { label: "Quantity of orders placed: ", value: analyticsData.totalNumberOfConfirmedOrders },
    { label: "Total number of quotations given: ", value: analyticsData.totalNumberOfSavedOrders },
    {
      label: "RSP value of orders placed: ",
      value: currencyFormatter.format(analyticsData.totalValueOfConfirmedOrders)
    },
    {
      label: "Total RSP value of quotations given: ",
      value: currencyFormatter.format(analyticsData.totalValueOfSavedOrders)
    },
    { label: "Number of products ordered: ", value: analyticsData.totalNumberOfProductsOrdered },
    { label: "Total number of swatches ordered: ", value: analyticsData.totalNumberOfOrderedSwatches }
  ];

  const productDetailsFields = [
    { label: "Chain Colour:", value: analyticsData.chainColors },
    { label: "Position:", value: analyticsData.controlPositions },
    { label: "Dimensions:", value: analyticsData.dimensionsArray },
    { label: "Rolls:", value: analyticsData.rolls },
  ];

  useEffect(() => {
    if (analyticsStatus === IDLE) {
      API.analytics.fetchFullAnalytics(dispatch).catch(() => {
        setIsLoading(false);
        showErrorToast(
          "Error.",
          "An error occured whilst attempting to filter orders, please try again",
          5000
        );
      });
    }
  }, []);

  useEffect(() => {
    setAnalyticsData(analytics);
    setIsLoading(false);
  }, [analytics]);

  const updateAnalytics = async () => {
    setIsLoading(true);
    const analyticsResponse = await API.analytics.updateAnalytics(filtersForm).catch(() => {
      setIsLoading(false);
      showErrorToast(
        "Error.",
        "An error occured whilst attempting to filter orders, please try again",
        5000
      );
    });
    setAnalyticsData(analyticsResponse);
    setIsLoading(false);
    setCurrentFabricIndex(-1);
  };

  const clearFilters = () => {
    setFiltersForm({});
    setAnalyticsData(analytics);
    setCurrentFabricIndex(-1);
  };

  const getDetailChartData = (detail) => {
    const datasets = detail.value.map((innerValue, index) => {
      return { label: innerValue.key, data: [innerValue.value], backgroundColor: backgroundColors[index] };
    });
    return { labels: [detail.label], datasets };
  };

  const getFabricsChartData = (fabrics) => {
    const labels = fabrics.map(fabric => fabric.key);
    const datasets = [{
      label: "Count",
      data: fabrics.map(fabric => fabric.count),
      backgroundColor: backgroundColors[1],
    }];
    return { labels, datasets };
  };
  const getColorChartData = (colors) => {
    const labels = colors.map(color => color.key);
    const bgColors = labels.map((label) => fabricColors[label.split(" ")[0].split("/")[0].split(",")[0].toLowerCase()]);
    const datasets = [{
      label: "Count",
      data: colors.map(color => color.value),
      backgroundColor: bgColors.map(color => color + "aa"),
      borderColor: bgColors.map((color) => color === "#ffffff" ? "#bbbbbb" : color),
      borderWidth: 1
    }];
    return { labels, datasets };
  };

  const getPercentOfTotalFabrics = (value) => {
    const totalNumber = analyticsData.fabrics.reduce((previous, current) => previous + current.count, 0);
    return Math.round(value / totalNumber * 1000) / 10;
  };
  const getFraction = (array, value) => {
    const totalNumber = array.reduce((previous, current) => previous + current.value, 0);
    return Math.round(value / totalNumber * 1000) / 10;
  };

  const filterChanged = (value, fieldName) => {
    setFiltersForm(prevState => {
      return { ...prevState, [fieldName]: value };
    });
  };

  const productDetailsHaveValues = () => {
    return [
      analyticsData.chainColors,
      analyticsData.controlPositions,
      analyticsData.dimensionsArray,
      analyticsData.rolls
    ].some(el => !!el.length);
  };

  return (
    <div>
      <div className="p-12 bg-header">
        <h1 className="text-3xl mb-6">Analytics Dashboard</h1>
        <div className="flex flex-wrap justify-end items-end">
          <div className="w-full text-lg">Filter results:</div>
          <div className="w-1/6 pr-4">
            <FormSelect
              name="selectGroup"
              id="storeGroupUID"
              label="Select Group:"
              value={filtersForm.storeGroupUID ? {
                label: groups.find(group => group.uid === filtersForm.storeGroupUID).name,
                value: filtersForm.storeGroupUID
              } : undefined}
              options={groups.map(group => {
                return { value: group.uid, label: group.name };
              })}
              onChange={(selectedValue) => filterChanged(selectedValue.value, "storeGroupUID")}
            />
          </div>
          <div className="w-1/6 pr-4">
            <FormSelect
              name="selectSubcategory"
              label="Product Category:"
              id="productSubcategory"
              options={[
                ...sortedSubcategories.map(productSubcategory => productSubcategory),
                { value: undefined, label: "Not Selected" }
              ]}
              value={filtersForm.productSubcategory ?
                sortedSubcategories.find(productSubcategory => productSubcategory.value === filtersForm.productSubcategory)
                : undefined}
              onChange={(selectedValue) => filterChanged(selectedValue.value, "productSubcategory")}
            />
          </div>
          <div className="w-1/6 pr-4">
            <label htmlFor="startDate" className="block mb-2 text-sm font-bold">
              Start Date:
            </label>
            <DatePicker
              id="startDate"
              placeholderText="Select start date"
              aria-labelledby="startDate"
              dateFormat="dd/MM/yyyy"
              className="block w-full rounded-md p-3 border outline-none focus:border-primary max-h-15"
              selected={filtersForm.startDate}
              maxDate={Date.now()}
              onChange={(date) => filterChanged(date, "startDate")}
            />
          </div>
          <div className="w-1/6 pr-4">
            <label htmlFor="endDate" className="block mb-2 text-sm font-bold">
              End Date:
            </label>
            <DatePicker
              id="endDate"
              placeholderText="Select end date"
              aria-labelledby="endDate"
              dateFormat="dd/MM/yyyy"
              className="block w-full rounded-md p-3 border outline-none focus:border-primary max-h-15"
              selected={filtersForm.endDate}
              maxDate={new Date(Date.now())}
              minDate={filtersForm.startDate}
              onChange={(date) => filterChanged(date, "endDate")}
            />
          </div>
          <div className="w-2/6 flex">
            <ActionButton
              label="Apply Filters"
              background="action"
              onClick={() => updateAnalytics()}
              className="px-6 h-12 mr-4"
              disabled={!Object.keys(filtersForm).length}
            />
            <ActionButton
              label="Clear Filters"
              background="outline"
              onClick={() => clearFilters()}
              className="px-6 h-12 "
              disabled={!Object.keys(filtersForm).length}
            />
          </div>
        </div>
      </div>
      {analyticsData?.fabrics && !isLoading ? (<div className="min-h-screen">
        <div className="bg-white text-action p-6 pb-9 m-8 text-xl flex flex-wrap">
          <div className="w-full pb-6 text-4xl font-light">
            Orders:
          </div>
          {generalOrderFields.map(el => (
            <div key={el.label} className="w-1/2">
              <span className="font-bold">{el.label}</span>
              {el.value}
              <hr className="my-4"/>
            </div>
          ))}
        </div>
        <div className="bg-white p-6 pb-10 m-8 text-xl text-action flex flex-wrap">
          <div className="w-full pb-6 text-3xl font-light">
            Product Details:
          </div>
          {productDetailsHaveValues() ? productDetailsFields.map(el => !!el.value?.length && (
            <div key={el.label} className="w-1/2 ">
                <div className="text-2xl font-bold pb-3">
                  {el.label}
                </div>
                <div className="flex flex-wrap">
                  {el.value.map(innerValue => (
                    <div key={innerValue.key} className="w-1/2">
                      <div className="text-xl font-bold mb-1">
                        {innerValue.key}:
                        <span className="font-normal ml-1">{innerValue.value}</span>
                      </div>
                      <div className="text-base">- Fraction: {getFraction(el.value, innerValue.value)}%</div>
                    </div>
                  ))}
                  <div className="w-full h-12 mt-4 pr-8">
                    <Bar type="bar" options={detailChartOptions} data={getDetailChartData(el)}/>
                </div>
              </div>
              <hr className="my-8"/>
            </div>)) : (
            <div>No orders match your filter criteria</div>
          )}
        </div>
        <div className="bg-white text-action p-6 m-8 text-xl">
          <div className="w-full pb-6 text-3xl font-light">
            Fabrics:
          </div>
          {!!analyticsData.fabrics.length ? (
            <div className="flex flex-wrap">
              <div className="w-full">
                <Bar options={fabricChartOptions} data={getFabricsChartData(analyticsData.fabrics)} type="bar"/>
              </div>
              <div className="w-full flex flex-wrap mt-5">
                {analyticsData.fabrics.map((fabric, index) => {
                  return index !== currentFabricIndex ? (
                    <div onClick={() => setCurrentFabricIndex(index)}
                         onKeyDown={() => setCurrentFabricIndex(index)}
                         className="w-1/4 px-2 py-3 hover:bg-body cursor-pointer" key={fabric.key}>
                      <div><span className="font-bold">{fabric.key}: </span>{fabric.count}</div>
                      <div className="text-base">
                        <span className="font-bold">Fraction: </span>
                        {getPercentOfTotalFabrics(fabric.count)} %
                      </div>
                    </div>
                  ) : (
                    <div className="w-full px-0" key={fabric.key}>
                      <hr className="my-4"/>
                      <div className="py-2 flex justify-between flex-wrap">
                        <div className="w-1/2">
                          <div
                            onClick={() => setCurrentFabricIndex(-1)}
                            onKeyDown={() => setCurrentFabricIndex(-1)}
                            className="p-5 hover:bg-body cursor-pointer">
                            <div>- <span className="font-bold">{fabric.key}: </span>{fabric.count}</div>
                            <div className="text-base">
                              <span className="font-bold">Fraction: </span>
                              {getPercentOfTotalFabrics(fabric.count)} %
                            </div>
                          </div>
                          <div className="flex overflow-y-auto flex-wrap">
                            {fabric.value.map(color => (
                              <div className="text-lg pl-4 pt-2 font-normal w-1/2 min-w-max"
                                   key={`${fabric.key}+${color.key}`}>
                                <span>∙ {color.key}: </span>
                                <span className="pl-1">{color.value}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        {fabric.value.length > 1 && (
                          <div className="w-1/2 mt-20">
                            <Bar data={getColorChartData(fabric.value)} options={colorChartOptions} type="bar"/>
                          </div>)
                        }
                      </div>
                      <hr className="my-4"/>
                    </div>);
                })}
              </div>
            </div>
          ) : (<div>No orders match your filter criteria</div>)}
        </div>
      </div>) : (
        <div className="flex p-40 flex-grow justify-center items-center">
          <Spinner/>
        </div>
      )}
    </div>
  );
};

export default AnalyticsPage;
