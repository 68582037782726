import React, { useCallback, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { LOGIN_ROUTE } from "../../constants/routes";
import FormInput from "../Form/FormInput";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState(null);

  const handleForgotPassword = useCallback(
    async (event) => {
      event.preventDefault();

      if (!emailAddress) {
        setError("Please provide a valid email address.");
        return false;
      }

      try {
        await auth.sendPasswordResetEmail(emailAddress.toString());
        setEmailAddress(emailAddress);
        setSuccess(true);
      } catch (err) {
        if (err.code === "auth/user-not-found") {
          setEmailAddress(emailAddress);
          setSuccess(true);
        } else {
          setError(err.message);
        }
      }

      return true;
    },
    [emailAddress]
  );

  const resendForgotPassword = useCallback(
    async (event) => {
      event.preventDefault();

      try {
        await auth.sendPasswordResetEmail(emailAddress.toString());
        setSuccess(true);
      } catch (err) {
        setError(err.message);
      }

      return true;
    },
    [emailAddress]
  );

  return !success ? (
    <>
      <form id="login-form" onSubmit={handleForgotPassword}>
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <p className="mb-6 text-black font-light">
          Enter the email address associated with your account and we will send
          you a link to reset your password.
        </p>
        <div id="username-control" className="mb-6">
          <FormInput
            name="email"
            type="email"
            placeholder="Email address"
            id="email"
            label="Email Address:"
            onChange={(event) => setEmailAddress(event.target.value)}
            value={emailAddress}
          />
        </div>

        <button
          type="submit"
          className="block w-full bg-action text-white p-3 rounded-md mt-6"
        >
          Continue
        </button>
      </form>

      <Link
        to={LOGIN_ROUTE}
        className="underline text-link mt-6 flex justify-center"
      >
        I’ve remembered my password
      </Link>
    </>
  ) : (
    <>
      <p className="font-normal mb-4">
        Thanks, if the email address you entered is associated with an account,
        you will receive an email with instructions to reset your password.
      </p>
      <p className="font-light mb-6">
        Didn’t get the email? Check your spam folder or{" "}
        <button
          className="font-bold underline"
          type="button"
          onClick={resendForgotPassword}
        >
          resend
        </button>
      </p>
      <Link
        to={LOGIN_ROUTE}
        className="block w-full bg-action text-white p-3 rounded-md mt-6 text-center"
      >
        Return to login
      </Link>
    </>
  );
};

export default withRouter(ForgotPassword);
