import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useDropzone } from "react-dropzone";
import Icon from "components/Icon/Icon";
import { formatBytes } from "support/helpers";
import Spinner from "components/Spinner/Spinner";

const propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.instanceOf(Object),
  closeButtonProps: PropTypes.instanceOf(Object),
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
};

const UploadDialog = ({
  title,
  onSubmit,
  onClose,
  submitButtonProps,
  closeButtonProps,
  acceptedFileTypes,
  loading,
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles) {
      setError(null);
      setFile(acceptedFiles[0]);
    }
  }, []);

  const onDropRejected = () => setError("This file format is not accepted");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    accept: acceptedFileTypes.join(" ,"),
  });

  const switchDropContent = () => {
    if (loading) {
      return (
        <div className="flex flex-row items-center">
          <p className="mr-4">Processing file...</p>
          <Spinner size={24} />
        </div>
      );
    }
    if (file) {
      return (
        <div className="flex flex-row w-full justify-center">
          <Icon type="CSV" className="w-1/12 mr-4" />
          <div>
            <div className="font-bold">{file.name}</div>
            <div>{formatBytes(file.size)}</div>
          </div>
        </div>
      );
    }
    return (
      <>
        <Icon type="Upload" className="w-1/12 my-4" />
        {isDragActive ? "Drop to upload" : "Drop files here or click to upload"}
      </>
    );
  };

  return (
    <div className="bg-white rounded-md w-3/12 shadow">
      <div className="flex flex-grow flex-col p-4">
        <div className="text-xl font-bold border-b pb-2">{title}</div>
        <div
          className="mt-6 p-4 border border-dashed rounded-md border-gray-300 h-36 flex flex-col justify-center items-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {switchDropContent()}
        </div>
        <div className="self-center mt-2 text-red-600">{error}</div>
      </div>
      <div className="flex p-2">
        <ActionButton
          className="m-2 w-full p-3"
          onClick={onClose}
          {...closeButtonProps}
          disabled={loading}
        />
        <ActionButton
          className="m-2 w-full p-3"
          onClick={() => onSubmit(file)}
          disabled={!file || loading}
          {...submitButtonProps}
        />
      </div>
    </div>
  );
};

UploadDialog.defaultProps = {
  submitButtonProps: {
    label: "Submit",
    type: "button",
  },
  closeButtonProps: {
    label: "Cancel",
    type: "button",
    background: "outline",
  },
  loading: false,
};

UploadDialog.propTypes = propTypes;

export default UploadDialog;
