import React from 'react';
import PropTypes from "prop-types";
import search from 'images/icons/search.svg';

const Search = ({ placeholder, onChange, className, value }) => {
  const defaultClasses = ['block', 'w-full', 'rounded-md', 'py-3', 'pr-3', 'pl-10', 'border'];
  const additionalClasses = className.split(' ');

  const classes = [...defaultClasses, ...additionalClasses];

  return (
    <div className="relative w-full">
      <img src={search} alt="search" className="absolute top-4 left-3 w-4 h-4" />
      <input
        id="search"
        name="search"
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={classes.join(' ')}
      />
    </div>
  )
};

Search.defaultProps = {
  className: "",
  value: "",
};

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Search;