import React, { useContext } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import DetailsSection from "components/ViewOrder/DetailsSection";
import { createAddressString, millisecondsToDays } from "support/helpers";
import { useHistory } from "react-router";
import { ORDER_DETAILS_ROUTE } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "providers/DialogProvider";
import { updateNotes } from "slices/createOrderSlice";
import { UserContext } from "providers/UserProvider";
import { selectStoreGroupById } from "slices/storeGroupSlice";
import { DESTINATION_OPTION } from "constants/detail-form-fileds";
import { getMinInstallationDate } from "support/orderItemHelpers";
import { selectSlaDurationConfig } from "slices/productPricingConfigSlice";
import { SAVED } from "constants/status";

const propTypes = {
  store: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  showEditButtons: PropTypes.bool,
};

const defaultProps = {
  showEditButtons: true,
};

const SummaryDetails = ({ showEditButtons, store, order }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDialog, closeDialog] = useDialog();
  const { isDunelmUser } = useContext(UserContext);
  const group = useSelector((state) => selectStoreGroupById(state, store.storeGroupUID));
  const slaDurations = useSelector(selectSlaDurationConfig);
  const isSavedQuote = order.status === SAVED;

  const deliveryDestination = () => {
    switch (order.deliveryDestination) {
      case DESTINATION_OPTION.STORE:
        return "Store";
      case DESTINATION_OPTION.CUSTOMER:
        return "Customer";
      default:
        return "Unknown";
    }
  };

  const getPoNumberLabel = () => {
    const showCrimson = group?.name === "Dunelm" || isDunelmUser();
    return showCrimson ? "Crimson Reference Number" : "PO Number";
  };

  const isInstallationDateInvalid = () => {
    const minInstallationDate = new Date(getMinInstallationDate(order.productCategory, slaDurations));
    const installationDate = new Date(order.installationDate);
    return !(minInstallationDate <= installationDate) && showEditButtons;
  };

  const editDetailsButton = () =>
    showEditButtons ? (
      <ActionButton
        label="Edit"
        background="none"
        onClick={() => history.push(ORDER_DETAILS_ROUTE, { editing: true })}
        type="button"
        icon="Pencil"
      />
    ) : null;

  const orderDetails = [
    {
      key: "store",
      title: "Store Details",
      entries: [
        { label: "Name", value: store.name },
        { label: "Store Number", value: store.number },
        { label: "Email Address", value: store.email },
        { label: "Telephone", value: store.telephone },
        { label: "Consultant Name", value: order.consultantName },
        {
          label: getPoNumberLabel(),
          value: order.poNumber ? order.poNumber : "Not supplied",
        },
      ],
      button: editDetailsButton(),
    },
    {
      key: "deliveryDetails",
      title: "Delivery Details",
      entries: [
        {
          label: "Delivery Destination",
          value: deliveryDestination(),
          error: isSavedQuote && !order.deliveryDestination,
          errorMessage: 'A delivery destination must be selected. Please update it before submitting this order'
        },
        {
          label: "Address",
          value: createAddressString(
            order.deliveryDestination === DESTINATION_OPTION.STORE
              ? [
                order.storeAddress.storeName,
                order.storeAddress.addressLine1,
                order.storeAddress.addressLine2,
                order.storeAddress.city,
                order.storeAddress.postcode,
              ]
              : [
                order.customer.addressLine1,
                order.customer.addressLine2,
                order.customer.city,
                order.customer.postcode,
              ]
          ),
        },
        {
          label: "Installation Date",
          value: dayjs(order.installationDate).format("DD/MM/YYYY"),
          hidden: !order.installationDate,
          error: isInstallationDateInvalid(),
          errorMessage: `The installation date has to be set to at least ${millisecondsToDays(
        slaDurations[order.productCategory]
      )} days from now. Please update it and try to submit the order again.`
        },
      ],
      button: editDetailsButton(),
    },
    {
      key: "customer",
      title: "Customer Details",
      entries: [
        {
          label: "Name",
          value: `${order.customer.firstName} ${order.customer.lastName}`,
        },
        { label: "Email Address", value: order.customer.email },
        { label: "Telephone", value: order.customer.telephone },
      ],
      button: editDetailsButton(),
    },
  ];

  const submitNotes = (notes) => {
    dispatch(updateNotes(notes));
    closeDialog();
  };

  return (
    <div className="bg-white h-full overflow-y-auto">
      {orderDetails.map((section) => (
        <div key={section.key} className="p-10 border-b">
          <DetailsSection section={section}/>
        </div>
      ))}
      <div>
        <div className="p-10">
          <div className="flex justify-between items-center mb-4">
            <div className="font-bold text-lg">Notes</div>
            {showEditButtons && (
              <ActionButton
                label="Edit"
                background="none"
                onClick={() =>
                  openDialog({
                    type: "input",
                    title: "Notes",
                    onSubmit: (notes) => submitNotes(notes),
                    onClose: closeDialog,
                    submitButtonProps: {
                      label: "Save",
                    },
                    value: order.notes,
                  })
                }
                type="button"
                icon="Pencil"
              />
            )}
          </div>
          <div className={order.notes ? "" : "text-gray-300"}>{order.notes || "No notes entered"}</div>
        </div>
      </div>
    </div>
  );
};

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

export default SummaryDetails;
