import ActionButton from "components/Buttons/ActionButton/ActionButton";
import OrderCreationHeader from "components/OrderCreationHeader/OrderCreationHeader";
import ItemsList from "components/ViewOrder/ItemsList";
import SummaryDetails from "components/ViewOrder/SummaryDetails";
import { HOME_ROUTE } from "constants/routes";
import { CONFIRMED, SAVED } from "constants/status";
import { useDialog } from "providers/DialogProvider";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import API from "services/API";
import {
  markOrderAsComplete,
  resetCreateOrderState,
  selectOrder,
  updateTotalPrice,
} from "slices/createOrderSlice";
import {resetOrderState} from "slices/orderSlice";
import { selectStoreFromUID } from "slices/storeSlice";
import {
  calculateTotalPriceFromItemsInOrder,
  convertNumberToGBP,
} from "support/helpers";
import { showErrorToast } from "services/Toasts/toastService";
import { subcategoriesWithInstallationHeight } from "components/ViewOrder/Item";
import { minInstallationHeight } from "constants/product-configuration-form-fields";
import { selectSlaDurationConfig } from "slices/productPricingConfigSlice";
import { getMinInstallationDate } from "support/orderItemHelpers";
import { UserContext } from "providers/UserProvider";

const OrderSummaryPage = () => {
  const order = useSelector(selectOrder);
  const store = useSelector((state) =>
    selectStoreFromUID(state, order.storeUID)
  );
  const slaDurations = useSelector(selectSlaDurationConfig);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, closeDialog, setDialogLoading] = useDialog();
  const isSavedQuote = order.status === SAVED;
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    dispatch(markOrderAsComplete());
    const calculatedTotal = calculateTotalPriceFromItemsInOrder(order.items);
    dispatch(updateTotalPrice(calculatedTotal));
  }, [order.items, dispatch]);

  const deleteOrder = async () => {
    try {
      openDialog({
        type: "loading",
      });
      await API.orders.deleteOrder(order);
      goToHome();
    } catch (error) {
      closeDialog();
      showErrorToast(
        "Error.",
        "An error occured when trying to discard this quote. Please try again.",
        10000
      );
    }
  };

  const goToHome = () => {
    const userData = currentUser.data;
    dispatch(resetOrderState());
    API.orders.getOrdersFromAStoreByStatus(
      dispatch,
      CONFIRMED,
      userData.storeGroupUID,
      userData.storeUID,
      true
    );
    API.orders.getOrdersFromAStoreByStatus(
      dispatch,
      SAVED,
      userData.storeGroupUID,
      userData.storeUID,
      true
    );
    closeDialog();
    history.push(HOME_ROUTE);
    dispatch(resetCreateOrderState());
  };

  const openPdf = (pdfBase64) => {
    const bytes = Buffer.from(pdfBase64, "base64");
    const file = new Blob([bytes], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const getQuotePdf = async (orderUID) => {
    try {
      setDialogLoading(true);
      const functionResponse = await API.orders.fetchQuotePdf(orderUID);
      const responseData = JSON.parse(functionResponse.data);
      openPdf(responseData.pdfBase64);
      setDialogLoading(false);
    } catch (error) {
      showErrorToast(
        "Error.",
        "Unable to generate Quote PDF. Please try again.",
        10000
      );
      setDialogLoading(false);
    }
  };

  const submitOrder = async () => {
    try {
      openDialog({
        type: "loading",
      });
      if (isSavedQuote) {
        await API.orders.updateSavedQuote(order);
      } else {
        await API.orders.saveOrder(order);
      }
      const functionResponse = await API.orders.completeOrder(order.uid);
      const responseData = JSON.parse(functionResponse.data);
      openDialog({
        title: "Order Complete",
        description: `Order #${responseData.orderNumber} has been successfully submitted. Would you like to print this order?`,
        onSubmit: goToHome,
        submitButtonProps: {
          background: "action",
          label: "Done",
        },
        onClose: () => openPdf(responseData.pdfBase64),
        closeButtonProps: {
          background: "outline",
          label: "Print a copy",
        },
      });
    } catch (error) {
      openDialog({
        title: "Unable to submit order",
        description:
          "An error occurred whilst trying to submit this order, please try again. If the problem persists please contact the made to measure team.",
        onSubmit: submitOrder,
        onClose: closeDialog,
        submitButtonProps: {
          background: "action",
          label: "Retry",
        },
      });
    }
  };

  const saveAsQuote = async () => {
    try {
      openDialog({
        type: "loading",
      });
      if (isSavedQuote) {
        await API.orders.updateSavedQuote(order);
      } else {
        await API.orders.saveOrder(order);
      }
      openDialog({
        title: "Quote Saved",
        description:
          "This order has been saved as a quote. To continue with this order, visit the Saved Quotes page accessible via the home screen and search for the customer name or consultant name. Would you like to print this quote?",
        onSubmit: goToHome,
        submitButtonProps: {
          background: "action",
          label: "Done",
        },
        onClose: () => getQuotePdf(order.uid),
        closeButtonProps: {
          background: "outline",
          label: "Print quote",
        },
      });
    } catch (error) {
      openDialog({
        title: "Unable to save order",
        description:
          "An error occurred whilst trying to save this order, please try again. If the problem persists please contact the made to measure team.",
        onSubmit: saveAsQuote,
        onClose: closeDialog,
        submitButtonProps: {
          background: "action",
          label: "Retry",
        },
      });
    }
  };

  const hasRequiredAndValidInstallationHeight = (item) => {
    return subcategoriesWithInstallationHeight.includes(item.productSubcategory)
      ? parseFloat(item.installationHeight) >= parseFloat(minInstallationHeight)
      : true;
  };

  const isInstallationDateValid = () => {
    const minInstallationDate = new Date(getMinInstallationDate(order.productCategory, slaDurations));
    const installationDate = new Date(order.installationDate);
    return !order.installationRequired || minInstallationDate <= installationDate;
  };
  const isEveryInstallationHeightValid = () => {
    return !(order.installationRequired && !order.items.every(item => hasRequiredAndValidInstallationHeight(item)));
  };

  const isFormInvalid = () => {
    return !(isInstallationDateValid() && isEveryInstallationHeightValid()) || !order.deliveryDestination;
  };

  return (
    <div className="flex flex-col h-full">
      <OrderCreationHeader showCancelButton>
        {isSavedQuote && (
          <ActionButton
            label="Discard Saved Quote"
            background="outlineRed"
            onClick={() =>
              openDialog({
                title: "Discard Quote",
                description:
                  "Discarding the this saved quote will delete all order information from the system, this cannot be undone, are you sure you want to continue?",
                onSubmit: deleteOrder,
                onClose: closeDialog,
                submitButtonProps: {
                  background: "delete",
                  label: "Discard Quote",
                  icon: "Delete",
                },
              })
            }
            className="px-14 h-full absolute left-0"
          />
        )}
        <div className="w-full text-center text-lg font-bold">
          Order Summary
        </div>
      </OrderCreationHeader>
      <div className="grid grid-cols-2 flex-grow border-t overflow-hidden">
        <ItemsList
          items={order.items}
          productCategory={order.productCategory}
        />
        <SummaryDetails store={store} order={order}/>
      </div>
      <div className="bg-light-grey w-full py-7 px-9 flex justify-between items-center border-t">
        <div className="flex flex-col">
          <div>Total Price</div>
          <div className="text-3xl font-bold">
            {convertNumberToGBP(order.price)}
          </div>
        </div>
        <div className="flex">
          <ActionButton
            label={isSavedQuote ? "Save Changes" : "Save as quote"}
            background="outline"
            onClick={saveAsQuote}
            className="px-12 h-16 w-52 mr-6"
            disabled={!order.deliveryDestination}
          />
          <ActionButton
            label="Submit"
            background="action"
            onClick={submitOrder}
            className="px-12 h-16 w-52"
            disabled={isFormInvalid()}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryPage;
