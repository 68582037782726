import ActionButton from "components/Buttons/ActionButton/ActionButton";
import Search from "components/Search/Search";
import Spinner from "components/Spinner/Spinner";
import Table from "components/Table/Table";
import { ADMIN, STORE, STORE_GROUP } from "constants/access-level";
import {
  ORDER_SUMMARY_ROUTE,
  SAVED_QUOTES_ROUTE,
  VIEW_ORDER_ROUTE,
} from "constants/routes";
import { CONFIRMED, IDLE, SAVED } from "constants/status";
import {
  ORDERS_HEADINGS,
  SAVED_QUOTE_HEADINGS,
} from "constants/table-headings";
import dayjs from "dayjs";
import { useDialog } from "providers/DialogProvider";
import { UserContext } from "providers/UserProvider";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import API from "services/API";
import { showErrorToast, showSuccessToast } from "services/Toasts/toastService";
import { saveOrderDetails } from "slices/createOrderSlice";
import {
  selectAllOrders,
  selectLastVisibleConfirmedOrder,
  selectLastVisibleSavedOrder,
} from "slices/orderSlice";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import { selectAllStores } from "slices/storeSlice";
import {
  checkSearchQuery,
  convertNumberToGBP,
  getStoreGroupNameFromUID,
  getStoreNameFromUID,
  toTitleCase,
} from "support/helpers";
import ActionHeader from "components/ActionHeader/ActionHeader";

const HomePage = () => {
  const orders = useSelector(selectAllOrders);
  const groups = useSelector(selectAllStoreGroups);
  const stores = useSelector(selectAllStores);
  const ordersSubscriptionStatus = useSelector((state) => state.orders.status);
  const lastVisibleConfirmedOrder = useSelector(
    selectLastVisibleConfirmedOrder
  );
  const lastVisibleSavedOrder = useSelector(selectLastVisibleSavedOrder);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const history = useHistory();
  const { getUserAccessLevel, currentUser } = useContext(UserContext);
  const userAccessLevel = getUserAccessLevel();
  const location = useLocation();
  const isSavedQuotesPage = location.pathname === SAVED_QUOTES_ROUTE;
  const [openDialog, closeDialog, setDialogLoading] = useDialog();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);

  const searchableFields = [
    {
      key: "number",
      roles: [ADMIN, STORE_GROUP, STORE],
    },
    {
      key: "store",
      roles: [ADMIN, STORE_GROUP],
    },
    {
      key: "group",
      roles: [ADMIN],
    },
    {
      key: "customer",
      roles: [ADMIN, STORE_GROUP, STORE],
    },
    {
      key: "consultant",
      roles: [STORE_GROUP, STORE],
    },
    {
      key: "poNumber",
      roles: [ADMIN],
    },
  ];

  const prepareOrder = (order) => {
    const orderFields = [
      {
        key: "number",
        value: order.orderNumber,
        roles: [ADMIN, STORE_GROUP, STORE],
        excludedRoutes: [SAVED_QUOTES_ROUTE],
      },
      {
        key: "poNumber",
        value: order.poNumber ? order.poNumber : "Not Supplied",
        roles: [ADMIN],
        excludedRoutes: [SAVED_QUOTES_ROUTE],
      },
      {
        key: "type",
        value: toTitleCase(order.productCategory),
        roles: [ADMIN, STORE_GROUP, STORE],
        searchable: false,
      },
      {
        key: "group",
        value: getStoreGroupNameFromUID(order.storeGroupUID, groups),
        roles: [ADMIN],
      },
      {
        key: "store",
        value: getStoreNameFromUID(order.storeUID, stores),
        roles: [ADMIN, STORE_GROUP],
      },
      {
        key: "customer",
        value: `${order.customer.firstName.slice(0, 1)}. ${
          order.customer.lastName
        }`,
        roles: [ADMIN, STORE_GROUP, STORE],
      },
      {
        key: "consultant",
        value: order.consultantName,
        roles: [STORE_GROUP, STORE],
      },
      {
        key: "price",
        value: convertNumberToGBP(order.price),
        roles: [ADMIN, STORE_GROUP, STORE],
      },
      {
        key: "created",
        value: order.createdAt,
        roles: [ADMIN, STORE_GROUP, STORE],
      },
      {
        key: "action",
        value: (
          <ActionButton
            label={
              !isSavedQuotesPage || userAccessLevel === ADMIN ? "View" : "Edit"
            }
            className="font-bold"
            background="none"
            onClick={() =>
              !isSavedQuotesPage || userAccessLevel === ADMIN
                ? viewOrder(order)
                : editOrder(order)
            }
            type="button"
            icon="ForwardArrow"
          />
        ),
        roles: [ADMIN, STORE_GROUP, STORE],
      },
    ];

    const orderValues = {};

    orderFields
      .filter(
        (field) =>
          field.roles.includes(userAccessLevel) &&
          !field.excludedRoutes?.includes(location.pathname)
      )
      .forEach((field) => {
        orderValues[field.key] = field.value;
      });

    return {
      key: order.uid,
      values: orderValues,
      createdAt: order.createdAt,
    };
  };

  const viewOrder = (order) => {
    history.push(VIEW_ORDER_ROUTE, { orderUID: order.uid });
  };

  const editOrder = async (order) => {
    try {
      openDialog({
        type: "loading",
      });
      const updatedOrder = { ...order, items: [] };
      const querySnapshot = await API.orders.getOrderItems(order);
      querySnapshot.forEach((doc) => updatedOrder.items.push(doc.data()));
      dispatch(saveOrderDetails(updatedOrder));
      closeDialog();
      history.push(ORDER_SUMMARY_ROUTE);
    } catch (error) {
      closeDialog();
      showErrorToast(
        "Error.",
        "An error occured when trying to retrieve this saved quote. Please try again.",
        10000
      );
    }
  };

  useEffect(() => {
    const preparedOrders = orders
      .filter((order) =>
        isSavedQuotesPage ? order.status === SAVED : order.status === CONFIRMED
      )
      .map((order) => prepareOrder(order))
      .filter((order) => {
        const fieldsToSearch = searchableFields
          .filter((field) => field.roles.includes(userAccessLevel))
          .map((field) => order.values[field.key]);
        return checkSearchQuery(searchQuery, fieldsToSearch);
      });
    setFilteredOrders(preparedOrders);
  }, [groups, stores, orders, searchQuery]);

  const loadMoreOrders = async (limitResults) => {
    setLoadingMore(true);
    const { storeUID, storeGroupUID } = currentUser.data;
    const statusToGet = isSavedQuotesPage ? SAVED : CONFIRMED;
    const lastOrderDoc = isSavedQuotesPage
      ? lastVisibleSavedOrder
      : lastVisibleConfirmedOrder;

    if (userAccessLevel === ADMIN) {
      await API.orders.getOrdersForAllStoresByStatus(
        dispatch,
        statusToGet,
        limitResults,
        lastOrderDoc
      );
    }

    if (userAccessLevel === STORE) {
      await API.orders.getOrdersFromAStoreByStatus(
        dispatch,
        statusToGet,
        storeGroupUID,
        storeUID,
        limitResults,
        lastOrderDoc
      );
    }

    if (userAccessLevel === STORE_GROUP) {
      await API.orders.getOrdersFromAGroupByStatus(
        dispatch,
        statusToGet,
        storeGroupUID,
        limitResults,
        lastOrderDoc
      );
    }
    setLoadingMore(false);
  };

  const shouldLoadMoreButtonsBeShown = () =>
    isSavedQuotesPage
      ? lastVisibleSavedOrder !== null
      : lastVisibleConfirmedOrder !== null;

  const searchPlaceholder = () => {
    switch (userAccessLevel) {
      case STORE:
        return "Search using Order Number, Customer or Consultant";
      case STORE_GROUP:
        return "Search using Order Number, Store, Customer or Consultant";
      default:
        return "Search using Order Number, PO Number, Store/Group, Customer";
    }
  };

  const exportOrdersToCSV = async (data) => {
    try {
      setDialogLoading(true);
      const startDate = dayjs(data.startDate).format("YYYY-MM-DD");
      const endDate = dayjs(data.endDate).format("YYYY-MM-DD");
      const functionResponse = await API.orders.exportOrders({
        status: isSavedQuotesPage ? SAVED : CONFIRMED,
        startDate,
        endDate,
        reportType: data.reportType
      });
      const responseData = JSON.parse(functionResponse.data);
      const blob = new Blob(["\ufeff", responseData.csv]);
      const downloadLink = document.createElement("a");

      // Build file name
      let fileName = "finesse_";
      fileName += isSavedQuotesPage ? "saved_quotes_" : "orders_";
      fileName += data.reportType === "orderSummary" ? "summary_" : "details_";
      fileName += `${startDate}_to_${endDate}.csv`;

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      showSuccessToast(
        "Success.",
        `${
          isSavedQuotesPage ? "Saved quotes" : "Orders"
        } successfully exported`,
        5000
      );
      closeDialog();
    } catch (error) {
      setDialogLoading(false);
      showErrorToast(
        "Error.",
        "An error occurred when trying to export the orders. Please try again.",
        10000
      );
    }
  };

  return (
    <div id="home-page" className="h-full flex flex-col">
      {ordersSubscriptionStatus === IDLE ? (
        <div className="flex flex-grow justify-center items-center">
          <Spinner/>
        </div>
      ) : (
        <>
          <ActionHeader>
            {userAccessLevel === ADMIN && (
              <ActionButton
                className="mr-auto"
                label={isSavedQuotesPage ? "Export Quotes" : "Export Orders"}
                onClick={() =>
                  openDialog({
                    type: "date",
                    title: isSavedQuotesPage
                      ? "Export Quotes"
                      : "Export Orders",
                    onSubmit: (data) => exportOrdersToCSV(data),
                    onClose: closeDialog,
                    submitButtonProps: {
                      label: "Export",
                    },
                  })
                }
                type="button"
                background="action"
                icon="WhiteCSV"
              />
            )}
            <div className="w-5/12 ml-auto">
              <Search
                placeholder={searchPlaceholder()}
                onChange={(event) => setSearchQuery(event.target.value)}
                value={searchQuery}
              />
            </div>
          </ActionHeader>
          <Table
            headings={
              isSavedQuotesPage
                ? SAVED_QUOTE_HEADINGS[userAccessLevel]
                : ORDERS_HEADINGS[userAccessLevel]
            }
            items={filteredOrders}
            loadMore={(limitResults) => loadMoreOrders(limitResults)}
            showLoadButtons={shouldLoadMoreButtonsBeShown()}
            loadingMore={loadingMore}
          />
        </>
      )}
    </div>
  );
};

export default HomePage;
