import FinesseLogo from "components/Logo/FinesseLogo";
import React from "react";

const PrivacyPolicyPage = () => (
  <div id="privacy-page" className="h-full flex overflow-y-auto p-20">
    <div className="container m-auto flex justify-center">
      <div className="w-full md:w-8/12">
        <div className="flex justify-center mb-28">
          <FinesseLogo />
        </div>
        <div>
          <strong>1. Introduction</strong>
          <br />
          <p>
            We are committed to ensuring your privacy is protected. Should we
            ask you to provide certain information by which you can be
            identified when using this website, then you can be assured that it
            will only be used in accordance with this privacy statement.
          </p>
          <br />
          <p>
            This notice sets out how we use and protect personal information for
            marketing purposes, including data which we gather from the use of
            our website.
          </p>
          <br />
          <p>
            We are the Data Controller for personal information, relating to
            people who have:
          </p>
          <br />
          <ul>
            <li>Accessed our website</li>
            <li>Agreed to receive marketing information from us</li>
          </ul>
          <br />
          <p>
            We do not share personal information. We will only disclose personal
            information for marketing purposes if:
          </p>
          <br />
          <ul>
            <li>Required or authorised by law</li>
          </ul>
          <br />
          <p>
            We do not intend to transfer any personal information used or stored
            for marketing purposes outside of the European Union.
          </p>
          <br />
          <p>
            We will retain your personal information for the period necessary to
            fulfill the requirements to which you have agreed.
          </p>
          <br />
          <p>
            <strong>
              2. Personal Information We Process Website Visitors:
            </strong>
          </p>
          <br />
          <p>
            Our website places cookies (text files) on your computer. The
            cookies contain a reference which is used to uniquely identify your
            browser and track each site you visit that has Google Analytics
            enabled. We use the information provided by cookies to determine the
            number of people visiting our website. It helps us to understand how
            people use our website. This information can then be used to help us
            improve our website and service to our browsers.
          </p>
          <br />
          <p>Links to other websites:</p>
          <br />
          <p>
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information, which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </p>
          <br />
          <p>Email Subscribers:</p>
          <br />
          <p>When you subscribe to emails from us, we will need your:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
          </ul>
          <br />
          <p>
            Our use and storage of your personal information for email marketing
            is based on your consent. This means that you will only receive
            emails and information that you have asked to receive. You can
            withdraw your consent at any time, by clicking the ‘unsubscribe’
            links shown in the footer of the marketing emails we send, or you
            can email finesse@filigreeholdings.co.uk or call 01773 811619
          </p>
          <br />
          <p>
            If you unsubscribe from emails or withdraw your consent for us to
            contact you, we will remove you from our marketing list.
          </p>
          <br />
          <p>
            <strong>
              3. Cookies (via Google Analytics) store the following data:
            </strong>
          </p>
          <br />
          <ul>
            <li>Time of visit</li>
            <li>Pages visited</li>
            <li>Time spent on the website</li>
            <li>
              Referring domains (Which domain a user may have come from to enter
              our website)
            </li>
            <li>Web browser type</li>
            <li>Operating system type</li>
            <li>Device type</li>
            <li>Geographic location of IP address</li>
            <li>Any links followed</li>
          </ul>
          <br />
          <p>
            If you already have Google Analytics cookies, they will be updated
            with the latest information about your visit to our website. We
            cannot access these cookies and are not the data controller for
            information held by Google Analytics.
          </p>
          <br />
          <p>
            Your consent is generally required for cookies to be placed on your
            computer and for the use and storage of personal information,
            relating to you provided by the cookies. You may withdraw your
            consent at any time by updating your cookie settings.
          </p>
          <br />
          <p>
            The use and storage of any personal information provided by the
            cookies will be based on legitimate interests. The legitimate
            interests are relating to the operation of our website and general
            promotion of our business and services.
          </p>
          <br />
          <p>
            For further information, we have provided a link to the Information
            Commissioner’s Office - Please
            <a href="https://ico.org.uk/for-organisations/guide-to-pecr/cookies-and-similar-technologies/">
              click here
            </a>
          </p>
          <br />
          <p>
            <strong>4. Your Rights</strong>
          </p>
          <br />
          <p>
            You may request a copy of the personal data we hold about you and
            you may object to our processing of it (including by way of direct
            marketing) or ask us to rectify it, restrict the way in which we
            process it or delete it from our records.
          </p>
          <br />
          <p>
            Where we use or store personal information relating to you, you have
            the following rights:
          </p>
          <br />
          <ul>
            <li>The right to be informed</li>
            <li>The right of access</li>
            <li>The right to rectification</li>
            <li>The right to erasure</li>
            <li>The right to restrict processing</li>
            <li>The right to data portability</li>
            <li>The right to object</li>
            <li>
              Rights in relation to automated decision making and profiling.
            </li>
          </ul>
          <br />
          <p>
            For further information, we have provided a link to the Information
            Commissioner’s Office’s for additional guidance - Please
            <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
              click here
            </a>
          </p>
          <br />
          <p>
            <strong>5. Complaints</strong>
          </p>
          <br />
          <p>
            If you have any cause for concern about our handling of personal
            information, please contact us using the details below:
          </p>
          <br />
          <p>
            Luke Mulligan - Director,
            <br /> Filigree Holdings Limited
            <br /> High Holborn Road
            <br />
            Ripley
            <br /> DE5 3NW
          </p>
          <br />
          <p>Or email finesse@filigreeholdings.co.uk </p>
          <br />
          <p>
            If we are not able to resolve your concerns about our handling of
            personal information, you have the right to complain to the
            Information Commissioner’s Office.
          </p>
          <br />
          <p>
            <a href="https://ico.org.uk/concerns/">Click here</a> to be directed
            to more information.
          </p>
          <br />
          <p>
            This version of the privacy notice was last updated on 04 October
            2021.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicyPage;
