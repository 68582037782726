import ActionButton from "components/Buttons/ActionButton/ActionButton";
import FormInput from "components/Form/FormInput";
import FormSelect from "components/Form/FormSelect";
import OrderCreationHeader from "components/OrderCreationHeader/OrderCreationHeader";
import { BLIND, SHEER } from "constants/product-categories";
import { PRODUCT_CONFIGURATION_FORM_FIELDS } from "constants/product-configuration-form-fields";
import {
  CHAIN_COLOR,
  COLOR,
  DESIGN,
  EDGE,
  HEADING,
  LINING,
  METRES,
  PRICE,
  QUANTITY,
  SIZE,
  WIDTH,
} from "constants/product-form-fields";
import {
  ORDER_DETAILS_ROUTE,
  ORDER_SUMMARY_ROUTE,
  SELECT_PRODUCT_ROUTE,
} from "constants/routes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  addOrderItem,
  selectOrderCompletionState,
  selectOrderProductCategory,
  updateOrderItem,
} from "slices/createOrderSlice";
import {
  selectPricingConfig,
  selectProductConfig,
} from "slices/productPricingConfigSlice";
import {
  areDecimalsValid,
  convertNumberToGBP,
  isFormInvalid,
  toTitleCase,
} from "support/helpers";
import {
  getQuantityFieldLabel,
  getSubcategoryName,
} from "support/orderItemHelpers";
import { generateProductPrice } from "services/PricingGeneration/priceGenerator";
import {
  CUT_LENGTH,
  ROMAN_BLIND_KIT,
  VALANCE,
} from "constants/product-subcategory";
import { v4 as uuidv4 } from "uuid";
import OrderBottomBar from "components/OrderBottomBar/OrderBottomBar";
import { useDialog } from "providers/DialogProvider";

const ProductConfigurationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { productSubcategory, orderItem, editing } = location.state ?? {};
  const dispatch = useDispatch();
  const orderProductCategory = useSelector(selectOrderProductCategory);
  const orderComplete = useSelector(selectOrderCompletionState);
  const categoryProductConfig = useSelector((state) =>
    selectProductConfig(state, orderProductCategory)
  );
  const subcategoryPricingConfig = useSelector((state) =>
    selectPricingConfig(state, orderProductCategory, productSubcategory)
  );
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [itemPrice, setItemPrice] = useState(0);
  const [configurationError, setConfigurationError] = useState(null);
  const [openDialog, closeDialog] = useDialog();

  useEffect(() => {
    const filteredFormFields = PRODUCT_CONFIGURATION_FORM_FIELDS.filter(
      (field) => field.visibleFor.includes(productSubcategory)
    );
    setFieldsToShow(filteredFormFields);
  }, [productSubcategory]);

  useEffect(() => {
    // Function used in the event of an error bellow, this will reset the price and set the error as provided
    const handleConfigurationError = (errorObject) => {
      setItemPrice(0);
      setConfigurationError(errorObject);
    };

    // UseEffect triggered by value change so set the error to null
    setConfigurationError(null);

    // Check if the form is actually valid, if not set price to 0
    if (
      fieldsToShow.length === 0 ||
      isFormInvalid(fieldsToShow.map((field) => formValues[field.id]))
    ) {
      setItemPrice(0);
      return;
    }

    // Find the related design object from the config as we'll need some details from it
    const designObject = categoryProductConfig.designs.find(
      (design) => design.name === formValues[DESIGN]
    );

    // Roman blind kit does not have a standard configuration because it does not ask the user for a design choice
    // so we need to have this special case
    let designPricingDetails;

    if (productSubcategory === ROMAN_BLIND_KIT) {
      designPricingDetails = subcategoryPricingConfig.prices;
    } else {
      // Sheer products have a different pricing key
      const pricingIdentifier =
        orderProductCategory === SHEER
          ? designObject.priceIdentifier
          : designObject.priceBand;
      designPricingDetails = subcategoryPricingConfig[pricingIdentifier];
    }

    // Its possible that no pricing details are found, if so, show the user an error and exit the function
    if (!designPricingDetails) {
      handleConfigurationError(
        new Error(
          "The configuration you've entered is not available, please try again. If the problem persists, please contact the made to measure team."
        )
      );
      return;
    }

    // Blind products have a maximum fabric width, we need to check this to ensure that the entered width does not
    // exceed the maximum width for the fabric
    if (
      orderProductCategory === BLIND &&
      designObject.maxWidth < formValues[WIDTH]
    ) {
      handleConfigurationError(
        new Error("The chosen fabric is not available in this width")
      );
      return;
    }

    // All validation has passed, now we can generate the price of this product
    try {
      const price = generateProductPrice(
        orderProductCategory,
        productSubcategory,
        formValues,
        designPricingDetails
      );
      setItemPrice(price);
    } catch (error) {
      handleConfigurationError(error);
    }
  }, [
    formValues,
    fieldsToShow,
    orderProductCategory,
    productSubcategory,
    subcategoryPricingConfig,
    categoryProductConfig,
  ]);

  useEffect(() => {
    if (orderItem) {
      let values = { ...orderItem.details, quantity: orderItem.quantity };

      if (productSubcategory !== ROMAN_BLIND_KIT) {
        values = {
          ...values,
          design: orderItem.fabric.design,
          color: orderItem.fabric.color,
        };
      }

      setFormValues(values);
    }
  }, [orderItem, productSubcategory]);

  const inputChange = (field, value) => {
    if (value === formValues[field.id]) {
      return;
    }

    if (field.type === "number") {
      if (value < 0) {
        return;
      }

      if (!areDecimalsValid(field.maxDecimalCount, value)) {
        return;
      }
    }

    setFormValues((prevState) => {
      let newState;
      if (field.id === DESIGN && formValues[DESIGN] !== value) {
        newState = { ...prevState, [DESIGN]: value, [COLOR]: null };
      } else {
        newState = { ...prevState, [field.id]: value };
      }
      return newState;
    });
  };

  const mapToSelectableArray = (array) =>
    array.map((value) => ({
      label: value,
      value,
    }));

  const getSelectOptions = (fieldId, options) => {
    const subcategoryOptions =
      categoryProductConfig.options[productSubcategory];

    let unMappedArray;
    switch (fieldId) {
      case DESIGN:
        return categoryProductConfig.designs
          .filter((design) =>
            design.availableForSubcategories.includes(productSubcategory)
          )
          .map((design) => ({
            ...design,
            label: design.name,
            value: design.name,
          }));
      case COLOR: {
        const selectedDesign = formValues[DESIGN];
        unMappedArray = selectedDesign
          ? categoryProductConfig.designs.find(
            (design) => design.name === selectedDesign
          ).colors
          : [];
        break;
      }
      case HEADING:
        unMappedArray = subcategoryOptions.headings;
        break;
      case LINING:
        unMappedArray = subcategoryOptions.linings;
        break;
      case CHAIN_COLOR:
        unMappedArray = subcategoryOptions.chainColors;
        break;
      case EDGE:
        unMappedArray = subcategoryOptions.edges;
        break;
      case SIZE:
        unMappedArray = subcategoryOptions.sizes;
        break;
      default:
        unMappedArray = options;
        break;
    }
    return mapToSelectableArray(unMappedArray);
  };

  const checkIfDisabled = (fieldId) => fieldId === COLOR && !formValues[DESIGN];

  const getItemQuantity = () => {
    const quantityKey = productSubcategory === CUT_LENGTH ? METRES : QUANTITY;
    const quantity = formValues[quantityKey] ? formValues[quantityKey] : 0;
    return quantity;
  };

  const getTotalPrice = () => {
    const quantity = getItemQuantity();
    return itemPrice * quantity;
  };

  const handleDialogClick = (route) => {
    history.push(route);
    closeDialog();
  };

  const addProductToOrder = () => {
    const nonDetailsFields = [PRICE, DESIGN, COLOR, QUANTITY];
    const itemDetails = { ...formValues };
    nonDetailsFields.forEach((field) => delete itemDetails[field]);
    const itemObject = {
      productSubcategory,
      uid: editing ? orderItem.uid : uuidv4(),
      details: itemDetails,
      price: getTotalPrice(),
      fabric:
        productSubcategory !== ROMAN_BLIND_KIT
          ? { design: formValues.design, color: formValues.color }
          : null,
      quantity:
        productSubcategory === CUT_LENGTH || productSubcategory === VALANCE
          ? 1
          : formValues[QUANTITY],
      ...(orderItem?.installationHeight ? { installationHeight: orderItem.installationHeight } : {})
    };
    if (editing) {
      dispatch(updateOrderItem(itemObject));
      history.push(ORDER_SUMMARY_ROUTE);
    } else {
      dispatch(addOrderItem(itemObject));
      openDialog({
        title: "Product Added",
        description:
          "The product has been successfully added to the order, would you like to add another product or checkout now?",
        onSubmit: () => handleDialogClick(orderComplete ? ORDER_SUMMARY_ROUTE : ORDER_DETAILS_ROUTE),
        onClose: () => handleDialogClick(SELECT_PRODUCT_ROUTE),
        submitButtonProps: {
          background: "action",
          label: "Checkout now",
        },
        closeButtonProps: {
          background: "outline",
          label: "Add another product",
        },
      });
    }
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <OrderCreationHeader showCancelButton>
        {!editing && (
          <ActionButton
            icon="BackArrow"
            iconSide="left"
            label="Back to Product Selection"
            background="outline"
            onClick={() => history.push(SELECT_PRODUCT_ROUTE)}
            className="px-14 h-full absolute"
          />
        )}
        <div className="w-full text-center text-lg font-bold">
          {`${toTitleCase(orderProductCategory)} ${getSubcategoryName(
            productSubcategory
          )}`}
        </div>
      </OrderCreationHeader>
      <div className="grid grid-cols-3 gap-6 p-9">
        <div className="col-span-3">
          Please complete all of the required fields to calculate the price
        </div>
        {fieldsToShow.map((field) => (
          <div key={field.id} className="mb-6">
            {field.type === "select" ? (
              <FormSelect
                name={field.name}
                label={field.label}
                options={getSelectOptions(field.id, field.options)}
                value={
                  formValues[field.id]
                    ? {
                      value: formValues[field.id],
                      label: formValues[field.id],
                    }
                    : undefined
                }
                onChange={(selectedValue) =>
                  inputChange(field, selectedValue.value)
                }
                disabled={checkIfDisabled(field.id)}
              />
            ) : (
              <FormInput
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                id={field.id}
                label={
                  field.id === QUANTITY
                    ? getQuantityFieldLabel(productSubcategory)
                    : field.label
                }
                onChange={(event) => inputChange(field, event.target.value)}
                value={formValues[field.id]}
              />
            )}
            {field.instruction && (
              <div className="mt-2 text-sm text-gray-400">
                {field.instruction[productSubcategory]}
              </div>
            )}
          </div>
        ))}
      </div>
      <OrderBottomBar
        lineOneText={`Price per ${
          productSubcategory === CUT_LENGTH ? "metre" : "item"
        }: ${convertNumberToGBP(itemPrice)}`}
        lineTwoText={`Total product price: ${convertNumberToGBP(
          getTotalPrice()
        )}`}
        button={
          <ActionButton
            label={editing ? "Save Changes" : "Add to order"}
            background="action"
            icon="Cart"
            iconSide="left"
            onClick={() => addProductToOrder()}
            className="px-12 h-16"
            disabled={itemPrice === 0 || getTotalPrice() === 0}
          />
        }
        error={configurationError}
      />
    </div>
  );
};

export default ProductConfigurationPage;
