import React from "react";
import PropTypes from "prop-types";

const TableHeader = ({ headings }) => (
  <thead className="font-bold tracking-wide text-left">
    <tr>
      {headings.map((heading) => (
        <th
          key={heading.split(" ").join("-").toLowerCase()}
          className="bg-header py-5 px-8 sticky top-0"
        >
          {heading}
        </th>
      ))}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
};

export default TableHeader;
