import Analytics from "images/icons/analytics.svg"
import BackArrow from "images/icons/back-arrow.svg";
import Bag from "images/icons/bag.svg";
import Delete from "images/icons/delete.svg";
import DeleteRed from "images/icons/deleteRed.svg";
import DropdownArrow from "images/icons/dropdown-arrow.svg";
import ForwardArrow from "images/icons/forward-arrow.svg";
import Group from "images/icons/group.svg";
import Logout from "images/icons/logout.svg";
import Pencil from "images/icons/pencil.svg";
import PencilRed from 'images/icons/pencil-red.svg'
import PencilWhite from "images/icons/pencil-white.svg";
import Pricing from "images/icons/pricing.svg";
import Products from "images/icons/products.svg";
import Search from "images/icons/search.svg";
import Store from "images/icons/store.svg";
import User from "images/icons/user.svg";
import Upload from "images/icons/upload.svg";
import UploadRed from "images/icons/upload-red.svg";
import Add from "images/icons/add.svg";
import CSV from "images/icons/csv.svg";
import WhiteCSV from "images/icons/white-csv.svg";
import Document from "images/icons/document.svg";
import Cart from "images/icons/cart.svg";
import Close from "images/icons/close.svg";

const icons = {
  Analytics,
  BackArrow,
  Bag,
  Delete,
  DeleteRed,
  DropdownArrow,
  ForwardArrow,
  Group,
  Logout,
  Pencil,
  PencilRed,
  PencilWhite,
  Pricing,
  Products,
  Search,
  Store,
  User,
  Upload,
  UploadRed,
  Add,
  CSV,
  WhiteCSV,
  Document,
  Cart,
  Close,
};

export function getIcon(name) {
  return icons[name];
}

export default {
  getIcon,
};
