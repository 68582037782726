import FirestoreWrapper from "components/FirestoreWrapper/FirestoreWrapper";
import React from "react";
import "./app.scss";
import RouteBuilder from "components/Routing/RouteBuilder/RouteBuilder";
import { ToastContainer } from "react-toastify";
import { DialogProvider } from "providers/DialogProvider";

const contextClass = {
  success: "bg-green-500",
  error: "bg-red-500",
};

function App() {
  return (
    <div className="app h-screen">
      <FirestoreWrapper>
        <ToastContainer
          toastClassName={({ type }) =>
            `${
              contextClass[type || "default"]
            } relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer`
          }
          position="bottom-right"
        />
        <DialogProvider>
          <RouteBuilder />
        </DialogProvider>
      </FirestoreWrapper>
    </div>
  );
}

export default App;
