import {
  CENTER_SPLIT,
  CHAIN_COLOR,
  COLOR,
  CONTROL_POSITION,
  DESIGN,
  DIMENSIONS,
  DROP,
  EDGE,
  EXACT_DROP,
  EXACT_WIDTH,
  FILL,
  FIX_TYPE,
  HEADING,
  LINING,
  METRES,
  QUANTITY,
  ROLL,
  SIZE,
  WIDTH,
} from "./product-form-fields";

import {
  CURTAIN,
  CUSHION,
  CUT_LENGTH,
  JARDINIERE,
  ROLLER,
  ROMAN_BLIND,
  ROMAN_BLIND_KIT,
  TIE_BACK,
  VALANCE,
  VERTICAL,
} from "./product-subcategory";
import { INPUT_TYPES } from "./form-input-types";

export const minInstallationHeight = "180";
export const PRODUCT_CONFIGURATION_FORM_FIELDS = [
  {
    type: INPUT_TYPES.SELECT,
    name: DESIGN,
    label: "Fabric Design",
    id: DESIGN,
    visibleFor: [
      CURTAIN,
      JARDINIERE,
      ROMAN_BLIND,
      CUT_LENGTH,
      ROLLER,
      VERTICAL,
      CUSHION,
      TIE_BACK,
      VALANCE,
    ],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: COLOR,
    label: "Fabric Colour",
    id: COLOR,
    visibleFor: [
      CURTAIN,
      JARDINIERE,
      ROMAN_BLIND,
      CUT_LENGTH,
      ROLLER,
      VERTICAL,
      CUSHION,
      TIE_BACK,
      VALANCE,
    ],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: HEADING,
    label: "Heading",
    id: HEADING,
    visibleFor: [CURTAIN, VALANCE],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: LINING,
    label: "Lining",
    id: LINING,
    visibleFor: [CURTAIN, ROMAN_BLIND, VALANCE],
  },
  {
    type: INPUT_TYPES.NUMBER,
    name: DROP,
    label: "Drop (cm)",
    id: DROP,
    visibleFor: [CURTAIN, JARDINIERE, ROMAN_BLIND, ROLLER, VERTICAL],
    maxDecimalCount: 1,
  },
  {
    type: INPUT_TYPES.NUMBER,
    name: WIDTH,
    label: "Width (cm)",
    id: WIDTH,
    visibleFor: [
      CURTAIN,
      JARDINIERE,
      ROMAN_BLIND,
      ROMAN_BLIND_KIT,
      ROLLER,
      VERTICAL,
      VALANCE,
    ],
    instruction: {
      [CURTAIN]:
        "Please enter the width of the curtain to include desired fullness. For Eyelets & Pinch Pleats please enter with width of the track or the pole",
    },
    maxDecimalCount: 1,
  },
  {
    type: INPUT_TYPES.NUMBER,
    name: EXACT_DROP,
    label: "Exact Recess Drop (cm)",
    id: EXACT_DROP,
    visibleFor: [CURTAIN],
    instruction: {
      [CURTAIN]:
        "The exact recess dimensions are helpful in the making of the curtain",
    },
    maxDecimalCount: 1,
  },
  {
    type: INPUT_TYPES.NUMBER,
    name: EXACT_WIDTH,
    label: "Exact Recess Width (cm)",
    id: EXACT_WIDTH,
    visibleFor: [CURTAIN],
    instruction: {
      [CURTAIN]:
        "The exact recess dimensions are helpful in the making of the curtain",
    },
    maxDecimalCount: 1,
  },
  {
    type: INPUT_TYPES.NUMBER,
    name: METRES,
    label: "Metres (m)",
    id: METRES,
    visibleFor: [CUT_LENGTH],
    maxDecimalCount: 2,
  },
  {
    type: INPUT_TYPES.SELECT,
    name: DIMENSIONS,
    label: "Dimensions",
    id: DIMENSIONS,
    visibleFor: [ROMAN_BLIND, ROMAN_BLIND_KIT, ROLLER, VERTICAL],
    options: ["Exact", "Recess"],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: CHAIN_COLOR,
    label: "Chain Colour",
    id: CHAIN_COLOR,
    visibleFor: [ROMAN_BLIND, ROMAN_BLIND_KIT, ROLLER, VERTICAL],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: CONTROL_POSITION,
    label: "Control Position",
    id: CONTROL_POSITION,
    visibleFor: [ROMAN_BLIND, ROMAN_BLIND_KIT, ROLLER, VERTICAL],
    options: ["Left", "Right"],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: ROLL,
    label: "Roll",
    id: ROLL,
    visibleFor: [ROLLER],
    options: ["Over", "Under"],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: CENTER_SPLIT,
    label: "Center Split",
    id: CENTER_SPLIT,
    visibleFor: [VERTICAL],
    options: ["Left", "Right"],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: FIX_TYPE,
    label: "Fix Type",
    id: FIX_TYPE,
    visibleFor: [VERTICAL],
    options: ["Left", "Right"],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: EDGE,
    label: "Edge",
    id: EDGE,
    visibleFor: [CUSHION, TIE_BACK],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: SIZE,
    label: "Size",
    id: SIZE,
    visibleFor: [CUSHION, TIE_BACK],
  },
  {
    type: INPUT_TYPES.SELECT,
    name: FILL,
    label: "Fill",
    id: FILL,
    visibleFor: [CUSHION],
    options: ["Yes", "No"],
  },
  {
    type: INPUT_TYPES.NUMBER,
    name: QUANTITY,
    label: "Quantity",
    id: QUANTITY,
    visibleFor: [
      CURTAIN,
      JARDINIERE,
      ROMAN_BLIND,
      ROMAN_BLIND_KIT,
      ROLLER,
      VERTICAL,
      CUSHION,
      TIE_BACK,
    ],
    maxDecimalCount: 0,
  },
];

export default PRODUCT_CONFIGURATION_FORM_FIELDS;
