import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { UserContext } from "../../../providers/UserProvider";
import { HOME_ROUTE } from "../../../constants/routes";

const propTypes = {
  component: PropTypes.func.isRequired,
};

const PublicRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(UserContext);

  if (currentUser && currentUser.data) {
    return <Redirect to={HOME_ROUTE} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Layout showSidebar={false}>
          <RouteComponent {...routeProps} />
        </Layout>
      )}
    />
  );
};

PublicRoute.propTypes = propTypes;

export default PublicRoute;
