import { functions } from "../../firebase";
import { setAnalytics } from "slices/analyticsSlice";

const fetchFullAnalytics = async (dispatch) => {
  const analyticsResponse = await functions.httpsCallable("getAnalyticsData")({});
  return dispatch(setAnalytics(analyticsResponse.data));
};

const updateAnalytics = async (options) => {
  const analyticsResponse = await functions.httpsCallable("getAnalyticsData")(options);
  return analyticsResponse.data;
};

export default {
  updateAnalytics,
  fetchFullAnalytics,
};
