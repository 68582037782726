import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../../../providers/UserProvider";
import Layout from "../../Layout/Layout";
import { LOGIN_ROUTE } from "../../../constants/routes";

const propTypes = {
  component: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  showSidebar: PropTypes.bool,
};

const defaultProps = {
  showSidebar: true,
};

const PrivateRoute = ({
  component: RouteComponent,
  roles,
  showSidebar,
  ...rest
}) => {
  const { currentUser } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!currentUser || !roles.includes(currentUser.data?.accessLevel)) {
          return <Redirect to={LOGIN_ROUTE} />;
        }

        return (
          <Layout showSidebar={showSidebar}>
            <RouteComponent {...routeProps} />
          </Layout>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = propTypes;

PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
