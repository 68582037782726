import { BLIND, FABRIC, SHEER, SWATCH } from "./product-categories";
import {
  ACCESSORIES,
  CURTAIN,
  CUSHION,
  CUT_LENGTH,
  JARDINIERE,
  ROLLER,
  ROMAN_BLIND,
  ROMAN_BLIND_KIT,
  TIE_BACK,
  VALANCE,
  VERTICAL,
} from "./product-subcategory";

export const ORDER_CATEGORIES = [
  {
    key: SHEER,
    label: "Sheers",
  },
  {
    key: BLIND,
    label: "Blinds",
  },
  {
    key: FABRIC,
    label: "Fabrics",
    hideForDunelm: true,
  },
  {
    key: SWATCH,
    label: "Swatches",
    hideForDunelm: true,
  },
];

export const ORDER_ACCESSORIES = [
  {
    key: CUSHION,
    label: "Cushion",
  },
  {
    key: TIE_BACK,
    label: "Tie Back",
  },
  {
    key: VALANCE,
    label: "Valance",
  },
];

export const ORDER_SUBCATEGORIES = {
  [SHEER]: [
    {
      key: CURTAIN,
      label: "Curtain",
    },
    {
      key: JARDINIERE,
      label: "Jardiniere",
    },
    {
      key: ROMAN_BLIND,
      label: "Roman Blind",
    },
    {
      key: CUT_LENGTH,
      label: "Cut Length",
    },
  ],
  [BLIND]: [
    {
      key: VERTICAL,
      label: "Vertical",
      hideForDunelm: true,
    },
    {
      key: ROLLER,
      label: "Roller",
    },
  ],
  [FABRIC]: [
    {
      key: CURTAIN,
      label: "Curtain",
    },
    {
      key: ROMAN_BLIND,
      label: "Roman Blind",
    },
    {
      key: ROMAN_BLIND_KIT,
      label: "Roman Blind Kit",
    },
    {
      key: CUT_LENGTH,
      label: "Cut Length",
    },
    {
      key: ACCESSORIES,
      label: "Accessories",
      options: ORDER_ACCESSORIES,
    },
  ],
  [SWATCH]: [
    {
      key: SHEER,
      label: "Sheers",
    },
    {
      key: BLIND,
      label: "Blinds",
    },
    {
      key: FABRIC,
      label: "Fabrics",
      hideForDunelm: true,
    },
  ],
};
