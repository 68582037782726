import FormInput from "components/Form/FormInput";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { LOGIN_ROUTE } from "constants/routes";
import { CODE_EXPIRED, CODE_INVALID } from "constants/firebase-error-codes";
import { showErrorToast, showSuccessToast } from "services/Toasts/toastService";
import API from "services/API";
import FormButton from "components/Form/FormButton";
import FinesseLogo from "../../components/Logo/FinesseLogo";
import { auth } from "../../firebase";

const CreatePasswordPage = () => {
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const urlQueries = queryString.parse(location.search);
  const code = urlQueries.oobCode;
  const userUID = urlQueries.uid;
  const [codeExpired, setCodeExpired] = useState(false);

  useEffect(() => {
    const isCodeValid = async () => {
      try {
        await auth.checkActionCode(code);
      } catch (err) {
        if (err.code === CODE_EXPIRED) {
          setCodeExpired(true);
        }

        if (err.code === CODE_INVALID) {
          showErrorToast(
            "An error occurred",
            "The create password link was invalid, please try again. If the problem continues contact your administrator"
          );
          history.push(LOGIN_ROUTE);
        }
      }
    };

    if (!code) {
      history.push(LOGIN_ROUTE);
    }

    isCodeValid();
  }, [code, history]);

  const setFormError = (errorMessage) => {
    setError(errorMessage);
    setLoading(false);
  };

  const handleCreatePassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { password, confirmPassword } = formValues;

    if (password !== confirmPassword) {
      setFormError("Passwords do not match");
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{8,}$/g;
    if (!passwordRegex.test(password)) {
      setFormError(
        "Passwords must contain a minimum of 8 characters, at least one number and one symbol (like !@#£%&*)"
      );
      return;
    }

    try {
      if (!code) {
        throw new Error("No oobCode provided");
      }
      await auth.confirmPasswordReset(code, password);
      history.push(LOGIN_ROUTE);
      showSuccessToast(
        "Success",
        "Your registration is complete, you can now login with your new password.",
        5000
      );
    } catch (err) {
      setFormError("Password reset failed, please try again");
    }
  };

  const inputChange = (key, value) => {
    setError(null);
    setFormValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const checkIfFormInvalid = () => {
    const { password, confirmPassword } = formValues;
    if (!password || !confirmPassword) {
      return true;
    }

    return false;
  };

  const resendInvite = async () => {
    try {
      setLoading(true);
      await API.users.resendOnboardingEmail(userUID);
      history.push(LOGIN_ROUTE);
      showSuccessToast(
        "Success",
        "A new invitation email has been sent to the email registered with your account.",
        5000
      );
    } catch (err) {
      setLoading(false);
      showErrorToast(
        "Error",
        "An error occurred when we attempted to resent the invite, please try again.",
        5000
      );
    }
  };

  return (
    <div id="forgot-password-page" className="h-full flex">
      <div className="container m-auto flex justify-center">
        <div className="w-4/12">
          <div className="flex justify-center mb-10">
            <FinesseLogo />
          </div>
          {codeExpired ? (
            <div>
              <p className="mb-6 text-black font-light">
                This invitation link has expired, use the button below to
                request a new link. This will be sent to the email address
                registered to your account.
              </p>

              <FormButton
                label={loading ? "Loading..." : "Resend invitation link"}
                onClick={resendInvite}
                disabled={loading}
              />
            </div>
          ) : (
            <form id="create-password-form" onSubmit={handleCreatePassword}>
              <div id="password-control" className="mb-6">
                <FormInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  id="password"
                  label="Password:"
                  onChange={(event) =>
                    inputChange("password", event.target.value)
                  }
                  value={formValues.password}
                />
                <div className="mt-2 text-sm text-gray-400">
                  Passwords must contain a minimum of 8 characters, at least one
                  number and one symbol (like !@#£%&*)
                </div>
              </div>

              <div id="confirm-password-control" className="mb-6">
                <FormInput
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  id="confirmPassword"
                  label="Confirm Password:"
                  onChange={(event) =>
                    inputChange("confirmPassword", event.target.value)
                  }
                  value={formValues.confirmPassword}
                />
              </div>
              {error !== null && (
                <div className="p-4 bg-red-600 w-full text-white text-center rounded-md mb-3">
                  {error}
                </div>
              )}
              <FormButton
                label={loading ? "Loading..." : "Save"}
                type="submit"
                onClick={() => {}}
                disabled={checkIfFormInvalid() || loading}
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePasswordPage;
