import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { HOME_ROUTE } from "constants/routes";
import { auth } from "../../firebase";
import FormInput from "../Form/FormInput";
import FormButton from "../Form/FormButton";

const propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

const Login = ({ history }) => {
  const [error, setError] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();

      if (!emailAddress) {
        setError("Please provide a valid email address.");
        return false;
      }

      if (!userPassword) {
        setError("You must enter your password.");
        return false;
      }

      try {
        await auth.signInWithEmailAndPassword(emailAddress, userPassword);
        history.push(HOME_ROUTE);
      } catch (err) {
        setError(err.message);
      }

      return true;
    },
    [history, emailAddress, userPassword]
  );

  return (
    <form id="login-form" onSubmit={handleLogin}>
      {error !== null && (
        <div className="py-4 bg-scarlet-red rounded-md w-full text-white text-center mb-3">
          {error}
        </div>
      )}

      <div id="username-control" className="mb-6">
        <FormInput
          name="email"
          type="email"
          placeholder="Email address"
          id="email"
          label="Email Address:"
          onChange={(event) => setEmailAddress(event.target.value)}
          value={emailAddress}
        />
      </div>

      <div id="username-control" className="mb-6">
        <FormInput
          name="password"
          type="password"
          placeholder="Password"
          id="password"
          label="Password:"
          onChange={(event) => setUserPassword(event.target.value)}
          value={userPassword}
        />
      </div>

      <FormButton
        className="mt-6"
        type="submit"
        onClick={() => {}}
        label="Log in"
      />
    </form>
  );
};

Login.propTypes = propTypes;

export default withRouter(Login);
