import HomePage from "pages/HomePage/HomePage";
import LoginPage from "pages/LoginPage/LoginPage";
import UserManagementPage from "pages/UserManagementPage/UserManagementPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage/ForgotPasswordPage";
import GroupManagementPage from "pages/GroupManagementPage/GroupManagementPage";
import StoreManagementPage from "pages/StoreManagementPage/StoreManagementPage";
import AddEditUserPage from "pages/AddEditUserPage/AddEditUserPage";
import ViewOrderPage from "pages/ViewOrderPage/ViewOrderPage";
import AddEditGroupPage from "pages/AddEditGroupPage/AddEditGroupPage";
import AddEditStorePage from "pages/AddEditStorePage/AddEditStorePage";
import AddConfigPage from "pages/AddConfigPage/AddConfigPage";
import EditConfigPage from "pages/EditConfigPage/EditConfigPage";
import ConfigurationPage from "pages/ConfigurationPage/ConfigurationPage";
import CategorySelectionPage from "pages/CategorySelectionPage/CategorySelectionPage";
import ProductSelectionPage from "pages/ProductSelectionPage/ProductSelectionPage";
import ProductConfigurationPage from "pages/ProductConfigurationPage/ProductConfigurationPage";
import OrderDetailsPage from "pages/OrderDetailsPage/OrderDetailsPage";
import OrderSummaryPage from "pages/OrderSummaryPage/OrderSummaryPage";
import {
  ANALYTICS_ROUTE,
  ADD_GROUP_ROUTE,
  ADD_STORE_ROUTE,
  ADD_USER_ROUTE,
  EDIT_GROUP_ROUTE,
  EDIT_STORE_ROUTE,
  EDIT_USER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  GROUP_MANAGEMENT_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  CONFIGURATIONS_ROUTE,
  STORE_MANAGEMENT_ROUTE,
  USER_MANAGEMENT_ROUTE,
  VIEW_ORDER_ROUTE,
  SAVED_QUOTES_ROUTE,
  ADD_CONFIGURATION_ROUTE,
  EDIT_CONFIGURATION_ROUTE,
  CREATE_ORDER_ROUTE,
  SELECT_PRODUCT_ROUTE,
  PRODUCT_CONFIGURATION_ROUTE,
  ORDER_DETAILS_ROUTE,
  ORDER_SUMMARY_ROUTE,
  SWATCH_CONFIGURATION_ROUTE,
  CREATE_PASSWORD_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from "constants/routes";
import { ADMIN, STORE, STORE_GROUP } from "constants/access-level";
import SwatchConfigurationPage from "pages/SwatchConfigurationPage/SwatchConfigurationPage";
import CreatePasswordPage from "pages/CreatePasswordPage/CreatePasswordPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage/PrivacyPolicyPage";
import AnalyticsPage from 'pages/AnalyticsPage/AnalyticsPage'

export const routes = {
  public: [
    {
      path: LOGIN_ROUTE,
      name: "login",
      isProtected: false,
      component: LoginPage,
    },
    {
      path: FORGOT_PASSWORD_ROUTE,
      name: "forgotPassword",
      isProtected: false,
      component: ForgotPasswordPage,
    },
    {
      path: CREATE_PASSWORD_ROUTE,
      name: "createPassword",
      isProtected: false,
      component: CreatePasswordPage,
    },
    {
      path: PRIVACY_POLICY_ROUTE,
      name: "privacyPolicy",
      isProtected: false,
      component: PrivacyPolicyPage,
    },
  ],
  protected: [
    {
      path: HOME_ROUTE,
      name: "home",
      isProtected: true,
      component: HomePage,
      roles: [ADMIN, STORE_GROUP, STORE],
    },
    {
      path: VIEW_ORDER_ROUTE,
      name: "viewOrder",
      isProtected: true,
      component: ViewOrderPage,
      roles: [ADMIN, STORE_GROUP, STORE],
    },
    {
      path: USER_MANAGEMENT_ROUTE,
      name: "userManagement",
      isProtected: true,
      component: UserManagementPage,
      roles: [ADMIN],
    },
    {
      path: ADD_USER_ROUTE,
      name: "addUser",
      isProtected: true,
      component: AddEditUserPage,
      roles: [ADMIN],
    },
    {
      path: EDIT_USER_ROUTE,
      name: "editUser",
      isProtected: true,
      component: AddEditUserPage,
      roles: [ADMIN],
    },
    {
      path: GROUP_MANAGEMENT_ROUTE,
      name: "groupManagement",
      isProtected: true,
      component: GroupManagementPage,
      roles: [ADMIN],
    },
    {
      path: ADD_GROUP_ROUTE,
      name: "addGroup",
      isProtected: true,
      component: AddEditGroupPage,
      roles: [ADMIN],
    },
    {
      path: EDIT_GROUP_ROUTE,
      name: "editGroup",
      isProtected: true,
      component: AddEditGroupPage,
      roles: [ADMIN],
    },
    {
      path: STORE_MANAGEMENT_ROUTE,
      name: "storeManagement",
      isProtected: true,
      component: StoreManagementPage,
      roles: [ADMIN],
    },
    {
      path: ADD_STORE_ROUTE,
      name: "addStore",
      isProtected: true,
      component: AddEditStorePage,
      roles: [ADMIN],
    },
    {
      path: EDIT_STORE_ROUTE,
      name: "editStore",
      isProtected: true,
      component: AddEditStorePage,
      roles: [ADMIN],
    },
    {
      path: CONFIGURATIONS_ROUTE,
      name: "configurations",
      isProtected: true,
      component: ConfigurationPage,
      roles: [ADMIN],
    },
    {
      path: ADD_CONFIGURATION_ROUTE,
      name: "addConfiguration",
      isProtected: true,
      component: AddConfigPage,
      roles: [ADMIN],
    },
    {
      path: EDIT_CONFIGURATION_ROUTE,
      name: "editConfiguration",
      isProtected: true,
      component: EditConfigPage,
      roles: [ADMIN],
    },
    {
      path: SAVED_QUOTES_ROUTE,
      name: "savedQuotes",
      isProtected: true,
      component: HomePage,
      roles: [ADMIN, STORE_GROUP, STORE],
    },
    {
      path: CREATE_ORDER_ROUTE,
      name: "createOrder",
      isProtected: true,
      component: CategorySelectionPage,
      roles: [STORE],
      showSidebar: false,
    },
    {
      path: SELECT_PRODUCT_ROUTE,
      name: "selectProduct",
      isProtected: true,
      component: ProductSelectionPage,
      roles: [STORE],
      showSidebar: false,
    },
    {
      path: PRODUCT_CONFIGURATION_ROUTE,
      name: "productConfiguration",
      isProtected: true,
      component: ProductConfigurationPage,
      roles: [STORE],
      showSidebar: false,
    },
    {
      path: SWATCH_CONFIGURATION_ROUTE,
      name: "swatchConfiguration",
      isProtected: true,
      component: SwatchConfigurationPage,
      roles: [STORE],
      showSidebar: false,
    },
    {
      path: ORDER_DETAILS_ROUTE,
      name: "orderDetails",
      isProtected: true,
      component: OrderDetailsPage,
      roles: [STORE],
      showSidebar: false,
    },
    {
      path: ORDER_SUMMARY_ROUTE,
      name: "orderSummary",
      isProtected: true,
      component: OrderSummaryPage,
      roles: [STORE],
      showSidebar: false,
    },
    {
      path: ANALYTICS_ROUTE,
      name: "analytics",
      isProtected: true,
      component: AnalyticsPage,
      roles: [ADMIN],
      showSidebar: true,
    },
  ],
};

export default routes;
