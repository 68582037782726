import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE, IDLE } from "constants/status";

const initialState = {
  storeGroups: [],
  status: IDLE,
};

export const storeGroupSlice = createSlice({
  name: "storeGroups",
  initialState,
  reducers: {
    addStoreGroup(state, action) {
      state.status = ACTIVE;
      state.storeGroups.push(action.payload);
    },
    removeStoreGroup(state, action) {
      state.storeGroups = state.storeGroups.filter(
        (storeGroup) => storeGroup.uid !== action.payload.uid
      );
    },
    updateStoreGroup(state, action) {
      state.storeGroups = state.storeGroups.map((storeGroup) =>
        storeGroup.uid === action.payload.uid ? action.payload : storeGroup
      );
    },
    updateStoreGroupStatus(state, action) {
      state.status = action.payload;
    },
    resetStoreGroupState: () => initialState,
  },
});

export const {
  addStoreGroup,
  removeStoreGroup,
  updateStoreGroup,
  updateStoreGroupStatus,
  resetStoreGroupState,
} = storeGroupSlice.actions;

export const selectAllStoreGroups = (state) => state.storeGroups.storeGroups;

export const selectStoreGroupById = (state, groupId) =>
  state.storeGroups.storeGroups.find((group) => group.uid === groupId);

export default storeGroupSlice.reducer;
