export const PRODUCT_OPTIONS = {
  sheer: {
    curtain: {
      linings: ["Lined", "Unlined"],
      headings: [
        "Slot",
        "2 Inch Net Pleat Tape",
        "3 Inch 3 Pocket Tape",
        "3 Inch Velcro Tape",
        "Pinch Pleat Single",
        "Pinch Pleat Pair",
        "Eyelet Single",
        "Eyelet Pair",
        "Ripple",
      ],
      eyeletColors: ["Silver", "Brass", "Gun Metal", "Antique Gold"],
    },
    romanBlind: {
      linings: ["Lined", "Unlined"],
      chainColors: ["White", "Chrome"],
    },
  },
  blind: {
    vertical: {
      chainColors: ["White", "Chrome"],
    },
    roller: {
      chainColors: ["White", "Chrome"],
    },
  },
  fabric: {
    cushion: {
      edges: ["Piped", "Plain"],
      sizes: ["Rectangle", "Square"],
    },
    tieBack: {
      edges: ["Piped", "Plain"],
      sizes: ["66cm", "80cm", "90cm"],
    },
    valance: {
      headings: ["Pencil Pleat", "Pinch Pleat"],
      linings: ["Sateen Twill", "Thermal Supersoft", "Blackout"],
    },
    curtain: {
      headings: ["3 Inch Tape", "Eyelet", "Pinch Pleat"],
      linings: ["Sateen Twill", "Thermal Supersoft", "Blackout"],
      eyeletColors: ["Silver", "Brass", "Gun Metal", "Antique Gold"],
    },
    romanBlind: {
      linings: ["Sateen Twill", "Thermal Supersoft", "Blackout"],
      chainColors: ["White", "Chrome"],
    },
    romanBlindKit: {
      chainColors: ["White", "Chrome"],
    },
  },
};

export default PRODUCT_OPTIONS;
