import { configureStore } from "@reduxjs/toolkit";
import storeGroupReducer from "./slices/storeGroupSlice";
import userReducer from "./slices/userSlice";
import storeReducer from "./slices/storeSlice";
import orderReducer from "./slices/orderSlice";
import configReducer from "./slices/configSlice";
import createOrderReducer from "./slices/createOrderSlice";
import productPricingConfigReducer from "./slices/productPricingConfigSlice";
import analyticsReducer from './slices/analyticsSlice'

export default configureStore({
  reducer: {
    storeGroups: storeGroupReducer,
    users: userReducer,
    stores: storeReducer,
    orders: orderReducer,
    configs: configReducer,
    createOrder: createOrderReducer,
    productPricingConfig: productPricingConfigReducer,
    analytics: analyticsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
