import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";

const ActionButton = ({
  label,
  type,
  onClick,
  className,
  icon,
  iconSide,
  background,
  rounded,
  disabled,
}) => {
  const defaultClasses = [
    "flex",
    "justify-center",
    "items-center",
    "px-4",
    "cursor-pointer",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed",
  ];
  const additionalClasses = className.split(" ");

  const backgroundClass = {
    action: ["bg-action", "text-white"],
    delete: ["bg-red-500", "text-white"],
    outline: ["border", "border-primary", "text-primary"],
    outlineRed: ["border", "border-red-500", "text-red-500"],
    white: ["bg-white", "text-primary"],
    dashed: ["border", "border-primary", "text-primary", "border-dashed"],
    none: "",
  };

  const classes = [...defaultClasses, ...additionalClasses];

  if (rounded) {
    classes.push("rounded-md");
  }

  if (background && background !== "none") {
    classes.push(...backgroundClass[background]);
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classes.join(" ")}
    >
      {icon && iconSide === "left" && <Icon type={icon} className="pr-2" />}
      {label}
      {icon && iconSide === "right" && <Icon type={icon} className="pl-2" />}
    </button>
  );
};

ActionButton.defaultProps = {
  type: "button",
  className: "",
  icon: null,
  iconSide: "right",
  background: "action",
  rounded: true,
  disabled: false,
};

ActionButton.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(["submit", "button", "reset"]),
  ]),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  iconSide: PropTypes.string,
  background: PropTypes.string,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ActionButton;
