import React, { useEffect, useState } from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useHistory, useLocation } from "react-router";
import FormGrid from "components/Form/FormGrid";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "services/Toasts/toastService";
import API from "services/API";
import { selectStoreFromUID } from "slices/storeSlice";
import { useDialog } from "providers/DialogProvider";

const AddEditStorePage = () => {
  const location = useLocation();
  const history = useHistory();
  const storeUID = location.state?.storeUID;

  // Local states
  const [editing] = useState(storeUID);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState(null);
  const [group, setGroup] = useState({
    label: "Please select",
    value: "",
  });
  const [storeName, setStoreName] = useState("");
  const [storeNumber, setStoreNumber] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [address, setAddress] = useState({});

  // Global states
  const groups = useSelector(selectAllStoreGroups);
  const store = useSelector((state) => selectStoreFromUID(state, storeUID));
  const [openDialog, closeDialog] = useDialog();

  useEffect(() => {
    if (editing && store) {
      const storesGroup = groups.find(
        (storeGroup) => storeGroup.uid === store.storeGroupUID
      );
      setGroup({
        value: storesGroup.uid,
        label: storesGroup.name,
      });
      setStoreName(store.name);
      setStoreNumber(store.number);
      setEmail(store.email);
      setTelephone(store.telephone);
      setAddress(store.address ? store.address : {});
    }
  }, [editing, store, groups]);

  const handleFormSubmission = async () => {
    setError(null);
    setPageLoading(true);
    try {
      const storeData = {
        storeGroupUID: group.value,
        email,
        number: storeNumber,
        telephone,
        name: storeName,
        address,
      };
      if (editing) {
        storeData.uid = storeUID;
        if (storeData.email !== store.email) {
          const authUpdates = {
            uid: storeUID,
            updates: {
              email: storeData.email,
            },
          };
          await API.users.updateAuthAccount(authUpdates);
        }
        await API.stores.updateStoreDocument(storeData);
      } else {
        await API.stores.createStore(storeData);
      }
      history.goBack();
      const message = editing
        ? "The store was successfully updated."
        : "The store was successfully created. An email containing a link to set their password has been sent to the email address provided.";
      showSuccessToast("Success.", message, 5000);
    } catch (err) {
      setError(err.message);
      setPageLoading(false);
    }
  };

  const removeStore = async () => {
    try {
      await API.stores.deleteStore(store);
      closeDialog();
      history.goBack();
      showSuccessToast("Success.", "The store was successfully removed.", 5000);
    } catch (err) {
      showErrorToast(
        "Error.",
        "An error occured whilst attempting to remove this store, please try again",
        5000
      );
    }
  };

  let fields = [
    {
      type: "select",
      startPosition: 1,
      span: 1,
      label: "Group:",
      value: group,
      onChange: setGroup,
      name: "group",
      id: "group",
      hidden: editing,

      options: groups.map((storeGroup) => ({
        value: storeGroup.uid,
        label: storeGroup.name,
      })),
    },
    {
      type: "text",
      startPosition: 1,
      span: 1,
      label: "Store Name:",
      value: storeName,
      onChange: setStoreName,
      name: "storeName",
      id: "storeName",
      placeholder: "Store Name",
    },
    {
      type: "text",
      startPosition: 2,
      span: 1,
      label: "Store Number:",
      value: storeNumber,
      onChange: setStoreNumber,
      name: "storeNumber",
      id: "storeNumber",
      placeholder: "Store Number",
    },
    {
      type: "email",
      startPosition: 1,
      span: 2,
      label: "Store Email Address:",
      value: email,
      onChange: setEmail,
      name: "email",
      id: "email",
      placeholder: "Store Email Address",
    },
    {
      type: "text",
      startPosition: 1,
      span: 1,

      label: "Telephone Number:",
      value: telephone,
      onChange: setTelephone,
      name: "telephone",
      id: "telephone",
      placeholder: "Telephone Number",
    },
    {
      type: "text",
      startPosition: 1,
      span: 2,
      label: "Address Line 1:",
      value: address.addressLine1,
      onChange: (value) => addressChange("addressLine1", value),
      name: "addressLine1",
      id: "addressLine1",
      placeholder: "Address Line 1",
    },
    {
      type: "text",
      startPosition: 1,
      span: 2,
      label: "Address Line 2:",
      value: address.addressLine2,
      onChange: (value) => addressChange("addressLine2", value),
      name: "addressLine2",
      id: "addressLine2",
      placeholder: "Address Line 2 (Optional)",
      optional: true,
    },
    {
      type: "text",
      startPosition: 1,
      span: 1,
      label: "City:",
      value: address.city,
      onChange: (value) => addressChange("city", value),
      name: "city",
      id: "city",
      placeholder: "City",
    },
    {
      type: "text",
      startPosition: 1,
      span: 1,
      label: "Postcode:",
      value: address.postcode,
      onChange: (value) => addressChange("postcode", value),
      name: "postcode",
      id: "postcode",
      placeholder: "Postcode",
    },
  ];

  const addressChange = (key, value) => {
    setAddress((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <div className="h-screen overflow-y-scroll" id="add-edit-store-page">
      <ActionHeader>
        <div className="flex justify-between w-full">
          <ActionButton
            label={editing ? "Edit Store" : "Add Store"}
            onClick={() => history.goBack()}
            type="button"
            icon="BackArrow"
            iconSide="left"
            background="none"
            className="font-bold text-xl"
          />
          {editing && (
            <ActionButton
              label="Remove Store"
              onClick={() =>
                openDialog({
                  title: "Remove Store",
                  description: "Are you sure you want to remove this store?",
                  onSubmit: removeStore,
                  onClose: closeDialog,
                  submitButtonProps: {
                    background: "delete",
                    label: "Remove Store",
                    icon: "Delete",
                  },
                })
              }
              type="button"
              icon="Delete"
              background="delete"
            />
          )}
        </div>
      </ActionHeader>

      <FormGrid
        fields={fields}
        onSubmit={handleFormSubmission}
        loading={pageLoading}
        error={error}
        setError={setError}
        buttonLabel={editing ? "Save Changes" : "Add Store"}
      />
    </div>
  );
};

export default AddEditStorePage;
