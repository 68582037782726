import sheer from "images/categories/sheer.jpg";
import blind from "images/categories/blind.jpg";
import fabric from "images/categories/fabric.jpg";
import swatch from "images/categories/swatch.jpg";
import accessories from "images/subcategories/accessories.jpg";
import curtain from "images/subcategories/curtain.jpg";
import cutLength from "images/subcategories/cutLength.jpg";
import jardiniere from "images/subcategories/jardiniere.jpg";
import roller from "images/subcategories/roller.jpg";
import romanBlind from "images/subcategories/romanBlind.jpg";
import romanBlindKit from "images/subcategories/romanBlindKit.jpg";
import vertical from "images/subcategories/vertical.jpg";
import cushion from "images/subcategories/cushion.jpg";
import tieBack from "images/subcategories/tieBack.jpg";
import valance from "images/subcategories/valance.jpg";

const images = {
  sheer,
  blind,
  fabric,
  swatch,
  accessories,
  curtain,
  cutLength,
  jardiniere,
  roller,
  romanBlind,
  romanBlindKit,
  vertical,
  cushion,
  tieBack,
  valance,
};

export function getImage(name) {
  return images[name];
}

export default {
  getImage,
};
