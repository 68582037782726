import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  entry: PropTypes.instanceOf(Object).isRequired,
};

const FieldEntry = ({ entry }) => (
  <div className="break-words">
    <div className="font-bold">{entry.label}</div>
    <div className="whitespace-pre-wrap">{entry.value}</div>
    {entry.error && (
      <div className="text-xs mt-2 text-red-600">{entry.errorMessage || "Invalid value"}</div>
    )}
  </div>
);

FieldEntry.propTypes = propTypes;

export default FieldEntry;
