import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const ToastContent = ({ title, content }) => (
  <div className="p-2">
    <div className="font-bold">{title}</div>
    <div className="text-sm">{content}</div>
  </div>
);

ToastContent.propTypes = propTypes;

export default ToastContent;
