import React from "react";
import PropTypes from "prop-types";
import ActionHeader from "components/ActionHeader/ActionHeader";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { resetCreateOrderState, selectOrder } from "slices/createOrderSlice";
import { useDialog } from "providers/DialogProvider";
import { useHistory } from "react-router";
import { HOME_ROUTE, ORDER_SUMMARY_ROUTE } from "constants/routes";
import { SAVED } from "constants/status";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  showCancelButton: PropTypes.bool,
};

const defaultProps = {
  showCancelButton: false,
};

const OrderCreationHeader = ({ children, showCancelButton }) => {
  const order = useSelector(selectOrder);
  const [openDialog, closeDialog] = useDialog();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToCheckout = () => {
    history.push(ORDER_SUMMARY_ROUTE);
    closeDialog();
  };

  const cancelOrder = () => {
    dispatch(resetCreateOrderState());
    history.push(HOME_ROUTE);
    closeDialog();
  };

  const getDescriptionMessage = () => {
    if (order.complete && order.status === SAVED) {
      return "Cancelling this order will discard any changes you have made to this saved quote, alternatively you can check out with the products currently added to your order";
    }
    if (order.complete) {
      return "Cancelling your order will remove all products and information, alternatively you can check out with the products currently added to your order";
    }
    return "Cancelling your order will remove all products and information, are you sure you want to cancel?";
  };

  const defaultDialogProps = {
    title: "Cancel Order",
    onSubmit: cancelOrder,
    submitButtonProps: {
      background: "delete",
      label: "Cancel order",
    },
    description: getDescriptionMessage(),
    showDismissDialogButton: true,
  };

  return (
    <ActionHeader>
      <div className="w-full relative flex items-center">
        {children}
        {showCancelButton && (
          <ActionButton
            label="Cancel Order"
            background="outlineRed"
            onClick={() =>
              order.complete
                ? openDialog({
                    ...defaultDialogProps,
                    onClose: goToCheckout,
                    closeButtonProps: {
                      label: "Checkout Now",
                    },
                  })
                : openDialog({
                    ...defaultDialogProps,
                    closeButtonProps: {
                      hide: true,
                    },
                  })
            }
            className="px-14 h-full absolute right-0"
          />
        )}
      </div>
    </ActionHeader>
  );
};

OrderCreationHeader.propTypes = propTypes;

OrderCreationHeader.defaultProps = defaultProps;

export default OrderCreationHeader;
