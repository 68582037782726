import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Search from "components/Search/Search";
import ActionHeader from "components/ActionHeader/ActionHeader";
import { selectAllStores } from "slices/storeSlice";
import { STORES_HEADINGS } from "constants/table-headings";
import { IDLE } from "constants/status";
import Spinner from "components/Spinner/Spinner";
import Table from "components/Table/Table";
import { checkSearchQuery, getStoreGroupNameFromUID } from "support/helpers";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useHistory } from "react-router";
import { ADD_STORE_ROUTE, EDIT_STORE_ROUTE } from "constants/routes";

const StoreManagementPage = () => {
  const stores = useSelector(selectAllStores);
  const groups = useSelector(selectAllStoreGroups);
  const storesSubscriptionStatus = useSelector((state) => state.stores.status);
  const [filteredStores, setFilteredStores] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();

  useEffect(() => {
    const preparedStores = stores
      .map((store) => ({
        key: store.uid,
        values: {
          name: store.name,
          group: getStoreGroupNameFromUID(store.storeGroupUID, groups),
          number: store.number,
          email: store.email,
          phone: store.telephone,
          action: (
            <ActionButton
              label="Edit"
              background="none"
              onClick={() =>
                history.push(EDIT_STORE_ROUTE, { storeUID: store.uid })
              }
              type="button"
              icon="Pencil"
            />
          ),
        },
      }))
      .filter((store) => {
        const searchFields = [store.values.name, store.values.group];
        return checkSearchQuery(searchQuery, searchFields);
      });
    setFilteredStores(preparedStores);
  }, [groups, stores, searchQuery, history]);
  return (
    <div id="group-management-page" className="h-full flex flex-col">
      {storesSubscriptionStatus === IDLE ? (
        <div className="flex flex-grow justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <ActionHeader>
            <div className="flex justify-between w-full">
              <ActionButton
                label="Add store"
                onClick={() => history.push(ADD_STORE_ROUTE)}
                type="button"
                icon="Add"
              />
              <div className="w-3/12 content-end flex-none">
                <Search
                  placeholder="Search for group or store name"
                  onChange={(event) => setSearchQuery(event.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>
          </ActionHeader>
          <Table headings={STORES_HEADINGS} items={filteredStores} />
        </>
      )}
    </div>
  );
};

export default StoreManagementPage;
