import React from "react";
import PropTypes from "prop-types";

const FormButton = ({ label, type, onClick, className, disabled }) => {
  const defaultClasses = [
    "block",
    "w-full",
    "bg-action",
    "text-white",
    "p-3",
    "rounded-md",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed",
  ];
  const additionalClasses = className.split(" ");

  const classes = [...defaultClasses, ...additionalClasses];

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classes.join(" ")}
    >
      {label}
    </button>
  );
};

FormButton.defaultProps = {
  type: "button",
  className: "",
  disabled: false,
};

FormButton.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(["submit", "button", "reset"]),
  ]),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FormButton;
