import { ADMIN, STORE, STORE_GROUP } from "./access-level";

export const ORDERS_HEADINGS = {
  [ADMIN]: [
    "Order No.",
    "PO No.",
    "Type",
    "Group",
    "Store",
    "Customer",
    "Price",
    "Created",
    "",
  ],
  [STORE_GROUP]: [
    "Order No.",
    "Type",
    "Store",
    "Customer",
    "Consultant",
    "Price",
    "Created",
    "",
  ],
  [STORE]: [
    "Order No.",
    "Type",
    "Customer",
    "Consultant",
    "Price",
    "Created",
    "",
  ],
};

export const SAVED_QUOTE_HEADINGS = {
  [ADMIN]: [
    "Type",
    "Group",
    "Store",
    "Customer",
    "Price",
    "Created",
    "",
  ],
  [STORE_GROUP]: [
    "Type",
    "Customer",
    "Consultant",
    "Price",
    "Created",
    "",
  ],
  [STORE]: [
    "Type",
    "Customer",
    "Consultant",
    "Price",
    "Created",
    "",
  ],
};

export const USERS_HEADINGS = [
  "Name",
  "Email Address",
  "Type",
  "Group",
  "Created",
  "",
];
export const GROUPS_HEADINGS = [
  "Group Name",
  "Contact Name",
  "Email Address",
  "Total Stores",
  "Status",
  "",
];
export const STORES_HEADINGS = [
  "Name",
  "Group",
  "Store No.",
  "Email Address",
  "Telephone No.",
  "",
];

export const CONFIGS_HEADINGS = ["Name", "Last Updated", "Status", ""];
