import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE, IDLE } from "constants/status";

const initialState = {
  orders: [],
  status: IDLE,
  lastVisibleConfirmedOrder: null,
  lastVisibleSavedOrder: null,
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrders(state, action) {
      state.status = ACTIVE;
      state.orders.push(...action.payload);
    },
    removeOrder(state, action) {
      state.orders = state.orders.filter(
        (order) => order.uid !== action.payload.uid
      );
    },
    updateOrder(state, action) {
      state.orders = state.orders.map((order) =>
        order.uid === action.payload.uid ? action.payload : order
      );
    },
    updateOrderStatus(state, action) {
      state.status = action.payload;
    },
    setLastVisibleConfirmedOrder(state, action) {
      state.lastVisibleConfirmedOrder = action.payload;
    },
    setLastVisibleSavedOrder(state, action) {
      state.lastVisibleSavedOrder = action.payload;
    },
    resetOrderState: () => initialState,
  },
});

export const {
  addOrders,
  removeOrder,
  updateOrder,
  updateOrderStatus,
  resetOrderState,
  setLastVisibleConfirmedOrder,
  setLastVisibleSavedOrder,
} = orderSlice.actions;

export const selectAllOrders = (state) => state.orders.orders;

export const selectOrderFromUID = (state, orderUID) =>
  state.orders.orders.find((order) => order.uid === orderUID);

export const selectLastVisibleConfirmedOrder = (state) =>
  state.orders.lastVisibleConfirmedOrder;

export const selectLastVisibleSavedOrder = (state) =>
  state.orders.lastVisibleSavedOrder;

export default orderSlice.reducer;
