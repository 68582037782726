import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectOrder } from "slices/createOrderSlice";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import OrderItemSelector from "components/OrderItemSelector/OrderItemSelector";
import {
  ORDER_ACCESSORIES,
  ORDER_SUBCATEGORIES,
} from "constants/order-selection-items";
import {
  CREATE_ORDER_ROUTE,
  ORDER_DETAILS_ROUTE,
  ORDER_SUMMARY_ROUTE,
  PRODUCT_CONFIGURATION_ROUTE,
  SWATCH_CONFIGURATION_ROUTE,
} from "constants/routes";
import { ACCESSORIES } from "constants/product-subcategory";
import OrderCreationHeader from "components/OrderCreationHeader/OrderCreationHeader";
import OrderBottomBar from "components/OrderBottomBar/OrderBottomBar";
import {
  calculateTotalPriceFromItemsInOrder,
  convertNumberToGBP,
} from "support/helpers";
import { UserContext } from "providers/UserProvider";
import { SWATCH } from "constants/product-categories";

const ProductSelectionPage = () => {
  const [isAccessorySelection, setIsAccessorySelection] = useState(false);
  const history = useHistory();
  const order = useSelector(selectOrder);
  const orderItemExists = order.items.length > 0;
  const { isDunelmUser } = useContext(UserContext);

  const selectProduct = (product) => {
    if (order.productCategory === SWATCH) {
      history.push(SWATCH_CONFIGURATION_ROUTE, {
        productSubcategory: product,
      });
    } else if (product === ACCESSORIES) {
      setIsAccessorySelection(true);
    } else {
      history.push(PRODUCT_CONFIGURATION_ROUTE, {
        productSubcategory: product,
      });
    }
  };

  const getSubcategoriesForSelection = () =>
    isDunelmUser()
      ? ORDER_SUBCATEGORIES[order.productCategory].filter((subcategory) => subcategory.hideForDunelm !== true)
      : ORDER_SUBCATEGORIES[order.productCategory];

  const getSelectionConfiguration = () => {
    if (isAccessorySelection) {
      return {
        headerButtonLabel: "Back to Product Selection",
        headerButtonOnClick: () => setIsAccessorySelection(false),
        title: "Fabric Accessory Selection",
        selectorHeader: "Select your fabric accessory to continue the order",
        items: ORDER_ACCESSORIES,
      };
    }

    return {
      headerButtonLabel: "Back to Category Selection",
      headerButtonOnClick: () => history.push(CREATE_ORDER_ROUTE),
      title: "Product Selection",
      selectorHeader: `Select your ${order.productCategory} product to begin the order`,
      selectorInstruction:
        "There will be option to enter any accompanying notes and purchase order numbers before submitting the order",
      items: getSubcategoriesForSelection(),
    };
  };

  const getTotalPrice = () => {
    const calculatedTotal = calculateTotalPriceFromItemsInOrder(order.items);
    return convertNumberToGBP(calculatedTotal);
  };

  return (
    <div className="flex flex-col h-full">
      <OrderCreationHeader showCancelButton>
        {!orderItemExists && (
          <ActionButton
            icon="BackArrow"
            iconSide="left"
            label={getSelectionConfiguration().headerButtonLabel}
            background="outline"
            onClick={getSelectionConfiguration().headerButtonOnClick}
            className="px-14 h-full absolute"
          />
        )}
        <div className="w-full text-center text-lg font-bold">
          {getSelectionConfiguration().title}
        </div>
      </OrderCreationHeader>
      <OrderItemSelector
        header={getSelectionConfiguration().selectorHeader}
        instruction={getSelectionConfiguration().selectorInstruction}
        items={getSelectionConfiguration().items}
        onClick={(product) => selectProduct(product)}
      />
      {orderItemExists && (
        <OrderBottomBar
          lineOneText={`Total products: ${order.items.length}`}
          lineTwoText={`Total Price: ${getTotalPrice()}`}
          button={
            <ActionButton
              label="Checkout Now"
              background="action"
              icon="Cart"
              onClick={() =>
                history.push(
                  order.complete ? ORDER_SUMMARY_ROUTE : ORDER_DETAILS_ROUTE
                )
              }
              className="px-12 h-16"
            />
          }
        />
      )}
    </div>
  );
};

export default ProductSelectionPage;
