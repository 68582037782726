import StoreGroups from "./storeGroups";
import Configs from "./configs";
import Users from "./users";
import Stores from "./stores";
import Orders from "./orders";
import Analytics from "./analytics";

export default {
  storeGroups: StoreGroups,
  configs: Configs,
  users: Users,
  stores: Stores,
  orders: Orders,
  analytics: Analytics
};
