import React, { useContext, useEffect, useState } from "react";
import OrderCreationHeader from "components/OrderCreationHeader/OrderCreationHeader";
import FormInput from "components/Form/FormInput";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { isFormInvalid, millisecondsToDays } from "support/helpers";
import { useDispatch, useSelector } from "react-redux";
import { removeInstallationHeights, saveOrderDetails, selectOrder } from "slices/createOrderSlice";
import { useHistory, useLocation } from "react-router";
import { ORDER_SUMMARY_ROUTE } from "constants/routes";
import { UserContext } from "providers/UserProvider";
import { v4 as uuidv4 } from "uuid";
import { selectSlaDurationConfig } from "slices/productPricingConfigSlice";
import { getMinInstallationDate } from "support/orderItemHelpers";
import { INPUT_TYPES } from "constants/form-input-types";
import { DESTINATION_OPTION, DETAIL_FORM_FIELD_IDS } from "constants/detail-form-fileds";
import * as _ from "lodash";
import FormSelect from "components/Form/FormSelect";
import { selectAllStores } from "slices/storeSlice";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { SWATCH } from "constants/product-categories";

dayjs.extend(duration);

const OrderDetailsPage = () => {
  const [formValues, setFormValues] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { editing } = location.state ?? {};
  const { currentUser, isDunelmUser } = useContext(UserContext);
  const order = useSelector(selectOrder);
  const stores = useSelector(selectAllStores);
  const slaDurations = useSelector(selectSlaDurationConfig);
  const sortedStores = _.sortBy(stores, ["name"]);
  const isDeliveryDestinationStore = () =>
    formValues[DETAIL_FORM_FIELD_IDS.DELIVERY_DESTINATION] === DESTINATION_OPTION.STORE;

  const storeValidAddress = (store) => {
    return store.address?.addressLine1 && store.address.city && store.address.postcode;
  };

  const formSections = [
    {
      key: "contact",
      title: "Customer Contact Details",
      fields: [
        {
          label: "First Name",
          id: DETAIL_FORM_FIELD_IDS.FIRST_NAME,
          type: INPUT_TYPES.TEXT,
          required: true,
        },
        {
          label: "Last Name",
          id: DETAIL_FORM_FIELD_IDS.LAST_NAME,
          type: INPUT_TYPES.TEXT,
          required: true,
        },
        {
          label: "Email Address",
          id: DETAIL_FORM_FIELD_IDS.EMAIL,
          type: INPUT_TYPES.EMAIL,
          required: true,
        },
        {
          label: "Telephone",
          id: DETAIL_FORM_FIELD_IDS.TELEPHONE,
          type: INPUT_TYPES.NUMBER,
          required: true,
        },
      ],
    },
    {
      key: "deliveryType",
      title: "Delivery Details",
      subtitle: `Please note that this order has a minimum fulfillment time of ${dayjs
        .duration(slaDurations[order.productCategory])
        .asDays()} days`,
      fields: [
        {
          label: "Delivery Destination",
          id: DETAIL_FORM_FIELD_IDS.DELIVERY_DESTINATION,
          type: INPUT_TYPES.SELECT,
          required: true,
          options: [
            { value: DESTINATION_OPTION.STORE, label: "Store" },
            { value: DESTINATION_OPTION.CUSTOMER, label: "Customer" },
          ],
        },
        {
          label: "Select Store",
          id: DETAIL_FORM_FIELD_IDS.SELECTED_STORE,
          type: INPUT_TYPES.SELECT,
          hidden: !isDeliveryDestinationStore(),
          required: isDeliveryDestinationStore(),
          options: sortedStores
            .filter((store) => storeValidAddress(store))
            .map((store) => ({
              label: `${store.name} - ${store.address.postcode}`,
              value: store.uid,
            })),
        },
        {
          label: "Is Fitting Required?",
          id: DETAIL_FORM_FIELD_IDS.INSTALLATION_REQUIRED,
          type: INPUT_TYPES.SELECT,
          hidden: order.productCategory === SWATCH,
          required: order.productCategory !== SWATCH,
          options: [
            { label: "Yes", value: true, },
            { label: "No", value: false }
          ]
        },
        {
          label: "Installation Date",
          id: DETAIL_FORM_FIELD_IDS.INSTALLATION_DATE,
          type: INPUT_TYPES.DATE,
          hidden: !formValues[DETAIL_FORM_FIELD_IDS.INSTALLATION_REQUIRED],
          min: getMinInstallationDate(order.productCategory, slaDurations),
          required: formValues[DETAIL_FORM_FIELD_IDS.INSTALLATION_REQUIRED]
        },
      ],
    },
    {
      key: "address",
      title: "Address Details",
      hidden: isDeliveryDestinationStore() || !formValues["deliveryDestination"],
      fields: [
        {
          label: "Address Line 1",
          id: DETAIL_FORM_FIELD_IDS.ADDRESS_LINE_1,
          type: INPUT_TYPES.TEXT,
          required: !isDeliveryDestinationStore(),
        },
        {
          label: "Address Line 2",
          id: DETAIL_FORM_FIELD_IDS.ADDRESS_LINE_2,
          type: INPUT_TYPES.TEXT,
        },
        {
          label: "City",
          id: DETAIL_FORM_FIELD_IDS.CITY,
          type: INPUT_TYPES.TEXT,
          required: !isDeliveryDestinationStore(),
        },
        {
          label: "Postcode",
          id: DETAIL_FORM_FIELD_IDS.POSTCODE,
          type: INPUT_TYPES.TEXT,
          required: !isDeliveryDestinationStore(),
        },
      ],
    },
    {
      key: "store",
      title: "Store Details",
      fields: [
        {
          label: "Consultant Name",
          id: DETAIL_FORM_FIELD_IDS.CONSULTANT_NAME,
          type: INPUT_TYPES.TEXT,
          required: true,
        },
        {
          label: isDunelmUser() ? "Crimson Reference Number" : "PO Number",
          id: DETAIL_FORM_FIELD_IDS.PO_NUMBER,
          type: INPUT_TYPES.TEXT,
          required: isDunelmUser(),
        },
      ],
    },
  ];

  const getStoreAddress = () => {
    const selectedStore = stores.find((store) => store.uid === formValues[DETAIL_FORM_FIELD_IDS.SELECTED_STORE]);
    const storeUID = selectedStore.uid;
    const storeName = selectedStore.name;
    const { addressLine1, addressLine2 = "", city, postcode } = selectedStore.address;
    return {
      storeName,
      addressLine1,
      addressLine2,
      city,
      postcode,
      storeUID,
    };
  };

  useEffect(() => {
    const currentStore = stores.find((store) => store.uid === currentUser.uid);
    let selectedStore;
    if (order.deliveryDestination === DESTINATION_OPTION.STORE) {
      selectedStore = order.storeAddress.storeUID;
    } else if (storeValidAddress(currentStore)) {
      selectedStore = currentUser.uid;
    }
    const values = {
      ...order,
      ...order.customer,
      installationDate: order.installationDate ? dayjs(order.installationDate).format("YYYY-MM-DD") : "",
      selectedStore,
    };
    setFormValues(values);
  }, [editing, order]);

  const isCrimsonNumberInvalid = () => isDunelmUser() && formValues.poNumber?.length !== 8;

  const checkFieldForError = (field) => {
    if (field.type === INPUT_TYPES.EMAIL && formValues.email && !/\S+@\S+\.\S+/.test(formValues.email)) {
      return "Please enter a valid email address";
    }
    if (field.id === DETAIL_FORM_FIELD_IDS.TELEPHONE && formValues.telephone && formValues.telephone.length < 10) {
      return "Please enter a valid telephone number";
    }
    if (field.id === DETAIL_FORM_FIELD_IDS.PO_NUMBER && formValues.poNumber && isCrimsonNumberInvalid()) {
      return "Please enter a valid Crimson Reference Number";
    }
    if (
      field.id === DETAIL_FORM_FIELD_IDS.INSTALLATION_DATE &&
      formValues.installationDate &&
      !isInstallationDateValid(formValues.installationDate)
    ) {
      return `The installation date has to be set to at least ${millisecondsToDays(
        slaDurations[order.productCategory]
      )} days from now. Please update it and try to submit the order again.`;
    }

    return null;
  };

  const isDetailsFormInvalid = () => {
    const allFields = formSections.map((section) => section.fields).flat();

    if (allFields.some((field) => checkFieldForError(field))) {
      return true;
    }
    const requiredFields = allFields.filter((field) => field.required).map((field) => formValues[field.id]);
    return isFormInvalid(requiredFields);
  };

  const isInstallationDateValid = (date) => {
    const minInstallationDate = new Date(getMinInstallationDate(order.productCategory, slaDurations));
    const installationDate = new Date(date);
    return installationDate >= minInstallationDate;
  };

  const saveDetails = () => {
    const orderDetails = {
      createdAt: new Date(),
      storeGroupUID: currentUser.data.storeGroupUID,
      storeUID: currentUser.data.storeUID,
      consultantName: formValues.consultantName,
      installationRequired: formValues.installationRequired || false,
      installationDate: formValues.installationRequired ? new Date(formValues.installationDate) : "",
      poNumber: formValues.poNumber ? formValues.poNumber : "",
      deliveryDestination: formValues.deliveryDestination,
      customer: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        telephone: formValues.telephone,
        addressLine1: !isDeliveryDestinationStore() ? formValues.addressLine1 : "",
        addressLine2: !isDeliveryDestinationStore() && formValues.addressLine2 ? formValues.addressLine2 : "",
        city: !isDeliveryDestinationStore() ? formValues.city : "",
        postcode: !isDeliveryDestinationStore() ? formValues.postcode : "",
      },
      ...(isDeliveryDestinationStore() && { storeAddress: getStoreAddress() }),
      uid: formValues.uid ? formValues.uid : uuidv4(),
    };
    if (!orderDetails.installationRequired) {
      dispatch(removeInstallationHeights());
    }
    dispatch(saveOrderDetails(orderDetails));
    history.push(ORDER_SUMMARY_ROUTE);
  };

  const getSelectValue = (field) => {
    return (typeof formValues[field.id] !== undefined)
      ? {
        value: formValues[field.id],
        label: field.options.find((option) => {
          return option.value === formValues[field.id];
        })?.label,
      }
      : undefined;
  };

  const inputChange = (fieldId, value) => {
    setFormValues((prevState) => {
      return { ...prevState, [fieldId]: value };
    });
  };
  return (
    <div className="flex flex-col h-full justify-between">
      <OrderCreationHeader showCancelButton>
        <div className="w-full text-center text-lg font-bold">Order Details</div>
      </OrderCreationHeader>
      <div className="p-9">
        {formSections
          .filter((section) => section.hidden !== true)
          .map((section) => (
            <div className="grid grid-cols-4 gap-6 mb-10" key={section.key}>
              <div className="col-span-4 pb-3 border-b">
                <div className="font-bold text-lg">{section.title}</div>
                {section.subtitle && <p className="text-sm text-gray-600">{section.subtitle}</p>}
              </div>
              {section.fields
                .filter((field) => field.hidden !== true)
                .map((field) => (
                  <div key={field.id}>
                    {field.type === INPUT_TYPES.SELECT ? (
                      <FormSelect
                        name={field.label}
                        type={field.type}
                        id={field.id}
                        label={field.label}
                        options={field.options}
                        disabled={field.disabled}
                        value={getSelectValue(field)}
                        onChange={(selectedValue) => inputChange(field.id, selectedValue.value)}
                      />
                    ) : (
                      <FormInput
                        name={field.id}
                        type={field.type}
                        id={field.id}
                        label={field.label}
                        onChange={(event) => inputChange(field.id, event.target.value)}
                        value={formValues[field.id] ? formValues[field.id] : field.value}
                        error={checkFieldForError(field)}
                        min={field.min}
                      />
                    )}
                  </div>
                ))}
            </div>
          ))}
      </div>
      <div className="bg-white w-full py-7 px-9 flex justify-end items-center">
        <ActionButton
          label={editing ? "Save" : "Continue"}
          background="action"
          onClick={() => saveDetails()}
          className="px-12 h-16"
          disabled={isDetailsFormInvalid()}
        />
      </div>
    </div>
  );
};

export default OrderDetailsPage;
