import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE, IDLE } from "constants/status";

const initialState = {
  analytics: {},
  status: IDLE,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalytics(state, action) {
      state.status = ACTIVE;
      state.analytics = action.payload;
    },
  },
});

export const { setAnalytics } = analyticsSlice.actions;

export const selectAnalytics = (state) => state.analytics.analytics;

export default analyticsSlice.reducer;
