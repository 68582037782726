import React, { useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "providers/UserProvider";
import Spinner from "components/Spinner/Spinner";
import Sidebar from "components/Sidebar/Sidebar";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

const Layout = ({ showSidebar, children }) => {
  const { loading } = useContext(UserContext);

  return (
    <div className="flex h-screen">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner size={40} />
        </div>
      ) : (
        <>
          {showSidebar && <Sidebar />}
          <div className={showSidebar ? "w-10/12 bg-body overflow-y-scroll" : "w-full bg-body overflow-y-scroll"}>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

Layout.propTypes = propTypes;

export default Layout;
