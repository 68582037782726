import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const Spinner = ({ size }) => (
  <svg
    className="animate-spin"
    style={{ height: `${size}px`, width: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 88 88"
    fill="none"
  >
    <circle
      cx="44"
      cy="44"
      r="40"
      stroke="#062C49"
      strokeOpacity="0.1"
      strokeWidth="8"
    />
    <path
      d="M44 4C21.9086 4 4 21.9086 4 44C4 53.9788 7.654 63.1041 13.697 70.1109"
      stroke="#062C49"
      strokeWidth="8"
    />
  </svg>
);

Spinner.defaultProps = defaultProps;

Spinner.propTypes = propTypes;

export default Spinner;
