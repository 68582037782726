import React from "react";
import PropTypes from "prop-types";
import FormSelect from "components/Form/FormSelect";
import FormInput from "components/Form/FormInput";
import FormButton from "components/Form/FormButton";
import { isFormInvalid } from "support/helpers";

const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

function inputChange(selectedOption, onChangeFunction, setError) {
  setError(null);
  onChangeFunction(selectedOption);
}

const FormGrid = ({
  fields,
  onSubmit,
  loading,
  error,
  setError,
  buttonLabel,
}) => (
  <div className="grid grid-cols-4 gap-6 p-9">
    {fields
      .filter((field) => !field.hidden)
      .map((field) => (
        <div
          key={field.id}
          className={`col-start-${field.startPosition} col-span-${field.span}`}
        >
          {field.type === "select" ? (
            <FormSelect
              name={field.name}
              label={field.label}
              options={field.options}
              value={field.value}
              onChange={(selectedOption) =>
                inputChange(selectedOption, field.onChange, setError)
              }
            />
          ) : (
            <FormInput
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              id={field.id}
              label={field.label}
              onChange={(event) =>
                inputChange(event.target.value, field.onChange, setError)
              }
              value={field.value}
            />
          )}
        </div>
      ))}
    {error !== null && (
      <div className="col-start-1 col-span-2 p-3 bg-scarlet-red rounded-md text-white text-center">
        Error: {error || "An unknown error occured"}
      </div>
    )}
    <div className="col-start-1 col-span-1">
      <FormButton
        className="mt-6"
        type="submit"
        onClick={() => onSubmit()}
        label={loading ? "Loading..." : buttonLabel}
        disabled={
          loading ||
          isFormInvalid(
            fields
              .filter((field) => !field.hidden && !field.optional)
              .map((field) =>
                field.type === "select" ? field.value.value : field.value
              )
          )
        }
      />
    </div>
  </div>
);

FormGrid.defaultProps = {
  error: null,
};

FormGrid.propTypes = propTypes;

export default FormGrid;
