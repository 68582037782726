import React, { useState } from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import { useHistory } from "react-router-dom";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import FormGrid from "components/Form/FormGrid";
import { showSuccessToast } from "services/Toasts/toastService";
import API from "services/API";
import { EDIT_CONFIGURATION_ROUTE } from "constants/routes";
import { BLIND, FABRIC, SHEER } from "constants/product-categories";
import {
  CURTAIN,
  CUSHION,
  CUT_LENGTH,
  JARDINIERE,
  ROLLER,
  ROMAN_BLIND,
  ROMAN_BLIND_KIT,
  TIE_BACK,
  VALANCE,
  VERTICAL,
} from "constants/product-subcategory";

const AddConfigPage = () => {
  const history = useHistory();

  // Local states
  const [configName, setConfigName] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialConfigState = {
    pricing: {
      [FABRIC]: {
        [CURTAIN]: null,
        [ROMAN_BLIND]: null,
        [ROMAN_BLIND_KIT]: null,
        [CUT_LENGTH]: null,
        [CUSHION]: null,
        [VALANCE]: null,
        [TIE_BACK]: null,
      },
      [BLIND]: {
        [VERTICAL]: null,
        [ROLLER]: null,
      },
      [SHEER]: {
        [CURTAIN]: null,
        [ROMAN_BLIND]: null,
        [JARDINIERE]: null,
        [CUT_LENGTH]: null,
      },
    },
    slaDurations: {
      [FABRIC]: 1209600000,
      [BLIND]: 1209600000,
      [SHEER]: 1209600000,
    },
    product: {
      [FABRIC]: null,
      [BLIND]: null,
      [SHEER]: null,
    },
    complete: false,
  };

  const handleFormSubmission = async () => {
    setError(null);
    setLoading(true);
    try {
      const configData = {
        ...initialConfigState,
        name: configName,
        lastUpdated: new Date(),
      };
      const configUID = await API.configs.createConfig(configData);
      history.push(EDIT_CONFIGURATION_ROUTE, {
        configUID,
      });
      const message =
        "The configuration has been created, all files must be uploaded before the config can be assigned to a store group";
      showSuccessToast("Success.", message, 5000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fields = [
    {
      type: "text",
      startPosition: 1,
      span: 2,
      name: "configName",
      label: "Configuration Name:",
      value: configName,
      onChange: setConfigName,
      id: "configName",
      placeholder: "Configuration Name",
    },
  ];

  return (
    <div id="add-edit-user-page">
      <ActionHeader>
        <div className="flex justify-start">
          <ActionButton
            label="Add New Configuration"
            onClick={() => history.goBack()}
            type="button"
            icon="BackArrow"
            iconSide="left"
            background="none"
            className="font-bold text-xl"
          />
        </div>
      </ActionHeader>

      <FormGrid
        fields={fields}
        onSubmit={handleFormSubmission}
        loading={loading}
        error={error}
        setError={setError}
        buttonLabel="Add Configuration"
      />
    </div>
  );
};

export default AddConfigPage;
