import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE, IDLE } from "constants/status";

const initialState = {
  stores: [],
  status: IDLE,
};

export const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    addStore(state, action) {
      state.status = ACTIVE;
      state.stores.push(action.payload);
    },
    removeStore(state, action) {
      state.stores = state.stores.filter(
        (store) => store.uid !== action.payload.uid
      );
    },
    updateStore(state, action) {
      state.stores = state.stores.map((store) =>
        store.uid === action.payload.uid ? action.payload : store
      );
    },
    updateStoreStatus(state, action) {
      state.status = action.payload;
    },
    resetStoreState: () => initialState,
  },
});

export const {
  addStore,
  removeStore,
  updateStore,
  updateStoreStatus,
  resetStoreState,
} = storeSlice.actions;

export const selectAllStores = (state) => state.stores.stores;

export const selectStoreFromUID = (state, storeUID) =>
  state.stores.stores.find((store) => store.uid === storeUID);

export default storeSlice.reducer;
