import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import ConfirmActionDialog from "components/Dialogs/ConfirmActionDialog";
import UploadDialog from "components/Dialogs/UploadDialog";
import Spinner from "components/Spinner/Spinner";
import ErrorDialog from "components/Dialogs/ErrorDialog";
import InputDialog from "components/Dialogs/InputDialog";
import DateDialog from "components/Dialogs/DateDialog";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export const DialogContext = createContext([]);

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogOptions, setDialogOptions] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);

  const openDialog = (options) => {
    setShowDialog(true);
    setDialogOptions(options);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setDialogOptions(null);
    setDialogLoading(false);
  };

  const switchDialog = (options) => {
    switch (options.type) {
      case "upload":
        return <UploadDialog {...options} loading={dialogLoading} />;
      case "loading":
        return <Spinner size={60} />;
      case "error":
        return <ErrorDialog {...options} />;
      case "input":
        return <InputDialog {...options} />;
      case "date":
        return <DateDialog {...options} loading={dialogLoading} />;
      default:
        return (
          <ConfirmActionDialog
            {...options}
            loading={dialogLoading}
            dismissDialog={closeDialog}
          />
        );
    }
  };

  return (
    <DialogContext.Provider value={[openDialog, closeDialog, setDialogLoading]}>
      {children}
      {showDialog && (
        <div className="w-full h-full fixed top-0 left-0 bg-black bg-opacity-25 z-50 flex justify-center items-center">
          {switchDialog(dialogOptions)}
        </div>
      )}
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = propTypes;

export default DialogProvider;
