import React, { useEffect, useState } from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import FormGrid from "components/Form/FormGrid";
import { useHistory, useLocation } from "react-router";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import API from "services/API";
import { showSuccessToast } from "services/Toasts/toastService";
import { useSelector } from "react-redux";
import { selectStoreGroupById } from "slices/storeGroupSlice";
import { selectAllConfigs } from "slices/configSlice";

const AddEditGroupPage = () => {
  const history = useHistory();
  const location = useLocation();
  const groupUID = location.state?.groupUID;

  // Local states
  const [groupName, setGroupName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmailAddress, setContactEmailAddress] = useState("");
  const [configuration, setConfiguration] = useState({
    label: "Please select",
    value: "",
  });
  const [disabled, setDisabled] = useState({ value: false });
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editing] = useState(groupUID);

  // Global states
  const group = useSelector((state) => selectStoreGroupById(state, groupUID));
  const configs = useSelector(selectAllConfigs);

  useEffect(() => {
    if (editing && group) {
      setGroupName(group.name);
      setContactName(group.contact.name);
      setContactEmailAddress(group.contact.email);
      const groupsConfig = configs.find(
        (config) => config.uid === group.configVersionUID
      );
      setConfiguration({
        value: groupsConfig.uid,
        label: groupsConfig.name,
      });
      setDisabled(
        group.isDisabled
          ? { value: true, label: "Suspended" }
          : { value: false, label: "Active" }
      );
    }
  }, [editing, group, configs]);

  const handleFormSubmission = async () => {
    setError(null);
    setPageLoading(true);
    try {
      const groupData = {
        configVersionUID: configuration.value,
        contact: {
          email: contactEmailAddress,
          name: contactName,
        },
        name: groupName,
        isDisabled: disabled.value,
      };
      if (editing) {
        groupData.uid = groupUID;
        await API.storeGroups.updateGroup(groupData);
      } else {
        await API.storeGroups.createGroup(groupData);
      }
      history.goBack();
      showSuccessToast(
        "Success!",
        `The group was successfully ${editing ? "updated" : "created"}.`,
        5000
      );
    } catch (err) {
      setError(err.message);
      setPageLoading(false);
    }
  };

  const fields = [
    {
      type: "text",
      startPosition: 1,
      span: 1,
      name: "groupName",
      label: "Group Name:",
      value: groupName,
      onChange: setGroupName,
      id: "groupName",

      placeholder: "Group Name",
    },
    {
      type: "text",
      startPosition: 1,
      span: 2,
      name: "contactName",
      label: "Contact Name:",
      value: contactName,
      onChange: setContactName,
      id: "contactName",
      placeholder: "Name",
    },
    {
      type: "email",
      startPosition: 1,
      span: 2,
      name: "contactEmail",
      label: "Contact Email Address:",
      value: contactEmailAddress,
      onChange: setContactEmailAddress,
      id: "contactEmail",
      placeholder: "Email Address",
    },
    {
      type: "select",
      startPosition: 1,
      span: 1,
      name: "config",
      label: "Pricing/Product Configuration:",
      value: configuration,
      onChange: setConfiguration,
      id: "config",
      options: configs
        .filter((config) => config.complete)
        .map((config) => ({
          value: config.uid,
          label: config.name,
        })),
    },
    {
      type: "select",
      startPosition: 1,
      span: 1,
      name: "disabled",
      label: "Status:",
      value: disabled,
      onChange: setDisabled,
      id: "disabled",
      hidden: !editing,
      options: [
        {
          value: true,
          label: "Suspended",
        },
        {
          value: false,
          label: "Actve",
        },
      ],
    },
  ];

  return (
    <div id="add-edit-group-page">
      <ActionHeader>
        <div className="flex justify-start">
          <ActionButton
            label={editing ? "Edit Group" : "Add Group"}
            onClick={() => history.goBack()}
            type="button"
            icon="BackArrow"
            iconSide="left"
            background="none"
            className="font-bold text-xl"
          />
        </div>
      </ActionHeader>

      <FormGrid
        fields={fields}
        onSubmit={handleFormSubmission}
        loading={pageLoading}
        error={error}
        setError={setError}
        buttonLabel={editing ? "Save Changes" : "Add Group"}
      />
    </div>
  );
};

export default AddEditGroupPage;
