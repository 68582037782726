import React, { useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormSelect from "components/Form/FormSelect";

const propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.instanceOf(Object),
  closeButtonProps: PropTypes.instanceOf(Object),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  loading: PropTypes.bool,
};

const reportTypes = [
  {value: "orderSummary", label: "Summary"},
  {value: "orderDetails", label: "Details"}
]

const defaultProps = {
  value: "",
  submitButtonProps: {
    label: "Submit",
    type: "button",
  },
  closeButtonProps: {
    label: "Cancel",
    type: "button",
    background: "outline",
  },
  loading: false
};

const DateDialog = ({
  title,
  onSubmit,
  onClose,
  submitButtonProps,
  closeButtonProps,
  startDate,
  endDate,
  loading
}) => {
  const [startDateValue, setStartDateValue] = useState(startDate);
  const [endDateValue, setEndDateValue] = useState(endDate);
  const [reportType, setReportType] = useState(reportTypes[0]);

  const isSubmitDisabled = () => !startDateValue || !endDateValue || loading;

  return (
    <div className="bg-white rounded-md w-3/12 shadow">
      <div className="flex flex-grow flex-col p-4">
        <div className="text-xl font-bold border-b pb-2">{title}</div>
      </div>
      <div className="w-full px-4 py-2">
        <div className="relative mb-4">
          <label
            htmlFor="startDate"
            className="block mb-1 text-sm font-semibold"
          >
            Report Type
          </label>
          <FormSelect
            name="reportType"
            options={reportTypes}
            value={reportTypes.find(type => type.value == reportType.value)}
            onChange={selectedValue => setReportType(selectedValue)}
          />
        </div>        
        <div className="relative mb-4">
          <label
            htmlFor="startDate"
            className="block mb-1 text-sm font-semibold"
          >
            Start Date
          </label>
          <DatePicker
            aria-labelledby="startDate"
            dateFormat="dd/MM/yyyy"
            className="block w-full rounded-md p-3 border outline-none focus:border-primary max-h-10"
            selected={startDateValue}
            maxDate={endDateValue}
            onChange={(date) => setStartDateValue(date)}
          />
        </div>
        <div className="relative">
          <label htmlFor="endDate" className="block mb-1 text-sm font-semibold">
            End Date
          </label>
          <DatePicker
            aria-labelledby="startDate"
            dateFormat="dd/MM/yyyy"
            className="block w-full rounded-md p-3 border outline-none focus:border-primary max-h-10"
            selected={endDateValue}
            minDate={startDateValue}
            onChange={(date) => {
              setEndDateValue(date);
            }}
          />
        </div>
      </div>

      <div className="flex p-2">
        <ActionButton
          className="m-2 w-full p-3"
          onClick={onClose}
          {...closeButtonProps}
          disabled={loading}
        />
        <ActionButton
          className="m-2 w-full p-3"
          onClick={() =>
            onSubmit({ startDate: startDateValue, endDate: endDateValue, reportType: reportType.value })
          }
          {...submitButtonProps}
          disabled={isSubmitDisabled()}
        />
      </div>
    </div>
  );
};

DateDialog.propTypes = propTypes;

DateDialog.defaultProps = defaultProps;

export default DateDialog;
