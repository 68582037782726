import { createSlice } from "@reduxjs/toolkit";
import { UNSAVED } from "constants/status";

const initialState = {
  status: UNSAVED,
  items: [],
  price: 0,
  complete: false,
  installationRequired: undefined,
};

export const createOrder = createSlice({
  name: "createOrder",
  initialState,
  reducers: {
    updateOrderProductCategory(state, action) {
      state.productCategory = action.payload.productCategory;
    },
    addOrderItem(state, action) {
      state.items.push(action.payload);
    },
    removeOrderItem(state, action) {
      state.items = state.items.filter(
        (item) => item.uid !== action.payload.uid
      );
    },
    updateOrderItem(state, action) {
      state.items = state.items.map((item) =>
        item.uid === action.payload.uid ? action.payload : item
      );
    },
    removeInstallationHeights(state) {
      state.items = state.items.map((item) => {
        return { ...item, installationHeight: null }
      });
    },
    saveOrderDetails(state, action) {
      state = Object.assign(state, action.payload);
    },
    updateTotalPrice(state, action) {
      state.price = action.payload;
    },
    updateNotes(state, action) {
      state.notes = action.payload;
    },
    markOrderAsComplete(state) {
      state.complete = true;
    },
    replaceOrderItems(state, action) {
      state.items = action.payload;
    },
    resetCreateOrderState: () => initialState,
  },
});

export const {
  updateOrderProductCategory,
  addOrderItem,
  removeOrderItem,
  updateOrderItem,
  saveOrderDetails,
  updateTotalPrice,
  updateNotes,
  markOrderAsComplete,
  replaceOrderItems,
  resetCreateOrderState,
  removeInstallationHeights,
} = createOrder.actions;

export const selectOrder = (state) => state.createOrder;

export const selectOrderProductCategory = (state) =>
  state.createOrder.productCategory;

export const selectOrderCompletionState = (state) => state.createOrder.complete;

export default createOrder.reducer;
