import React from 'react';
import Login from "components/Login/Login";
import FinesseLogo from "components/Logo/FinesseLogo";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD_ROUTE } from "constants/routes";

const LoginPage = () => (
  <div id="login-page" className="h-full flex">
    <div className="container m-auto flex justify-center">
      <div className="w-4/12">
        <div className="flex justify-center mb-10">
          <FinesseLogo />
        </div>
        <Login />
        <Link to={FORGOT_PASSWORD_ROUTE} className="underline text-link mt-6 flex justify-center">Forgot password?</Link>
      </div>
    </div>
  </div>
);

export default LoginPage;