export const ALL_VARIABLES = [
  {
    name: "minDrop",
    type: "Int",
  },
  {
    name: "maxDrop",
    type: "Int",
  },
  {
    name: "minWidth",
    type: "Int",
  },
  {
    name: "maxWidth",
    type: "Int",
  },
  {
    name: "lining",
    type: "String",
  },
  {
    name: "heading",
    type: "String",
  },
  {
    name: "size",
    type: "String",
  },
  {
    name: "edge",
    type: "String",
  },
];

export const SUBCATEGORY_REQUIRED_VARIABLES = {
  sheer: {
    curtain: ["minDrop", "maxDrop", "heading", "lining"],
    jardiniere: ["minDrop", "maxDrop", "minWidth", "maxWidth"],
    romanBlind: ["minDrop", "maxDrop", "minWidth", "maxWidth", "lining"],
    cutLength: [],
  },
  fabric: {
    curtain: [
      "minDrop",
      "maxDrop",
      "minWidth",
      "maxWidth",
      "heading",
      "lining",
    ],
    romanBlind: ["minDrop", "maxDrop", "minWidth", "maxWidth", "lining"],
    romanBlindKit: ["minWidth", "maxWidth"],
    cutLength: [],
    valance: ["minWidth", "maxWidth", "heading"],
    tieBack: ["size", "edge"],
    cushion: ["size", "edge"],
  },
  blind: {
    roller: ["minDrop", "maxDrop", "minWidth", "maxWidth"],
    vertical: ["minDrop", "maxDrop", "minWidth", "maxWidth"],
  },
};
