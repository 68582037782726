export const PINCH_PLEAT_WIDTH_BANDS = [
  {
    min: 1,
    max: 100.9,
    value: 260,
  },
  {
    min: 101,
    max: 160.9,
    value: 400,
  },
  {
    min: 161,
    max: 220.9,
    value: 520,
  },
  {
    min: 221,
    max: 280.9,
    value: 660,
  },
  {
    min: 281,
    max: 340.9,
    value: 800,
  },
  {
    min: 341,
    max: 400.9,
    value: 900,
  },
  {
    min: 401,
    max: 460.9,
    value: 1040,
  },
  {
    min: 461,
    max: 520.9,
    value: 1160,
  },
  {
    min: 521,
    max: 580.9,
    value: 1300,
  },
  {
    min: 581,
    max: 640.9,
    value: 1400,
  },
  {
    min: 641,
    max: 700.9,
    value: 1600,
  },
  {
    min: 701,
    max: 760.9,
    value: 1700,
  },
  {
    min: 761,
    max: 820.9,
    value: 1800,
  },
  {
    min: 821,
    max: 880.9,
    value: 2000,
  },
  {
    min: 881,
    max: 940.9,
    value: 2100,
  },
  {
    min: 941,
    max: 1000.9,
    value: 2100,
  },
];

export default PINCH_PLEAT_WIDTH_BANDS;
