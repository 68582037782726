export const DESIGN = "design";
export const COLOR = "color";
export const HEADING = "heading";
export const LINING = "lining";
export const DROP = "drop";
export const WIDTH = "width";
export const EXACT_DROP = "exactDrop";
export const EXACT_WIDTH = "exactWidth";
export const METRES = "metres";
export const DIMENSIONS = "dimensions";
export const CHAIN_COLOR = "chainColor";
export const CONTROL_POSITION = "controlPosition";
export const INSTALLATION_HEIGHT = "installationHeight";
export const CENTER_SPLIT = "centerSplit";
export const FIX_TYPE = "fixType";
export const ROLL = "roll";
export const QUANTITY = "quantity";
export const PAIR_SINGLE = "pairSingle";
export const EDGE = "edge";
export const SIZE = "size";
export const FILL = "fill";
export const PRICE = "price";
export const PRODUCT_FORM_FIELD_PRIORITY = [
  "design",
  "color",
  "lining",
  "heading",
  "drop",
  "width",
  "exactDrop",
  "exactWidth",
  "metres",
  "dimensions",
  "chainColor",
  "controlPosition",
  "installationHeight",
  "roll",
  "fixType",
  "centerSplit",
  "edge",
  "size",
  "fill",
  "quantity",
];
