import React from 'react';
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

const TableBody = ({ children }) => (
  <tbody className="bg-white">
    {children}
  </tbody>
);

TableBody.propTypes = propTypes;

export default TableBody;