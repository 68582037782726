import { createSlice } from "@reduxjs/toolkit";
import { BLIND, FABRIC, SHEER } from "constants/product-categories";
import { LOADING } from "constants/status";

const initialState = {
  pricing: {
    [FABRIC]: {},
    [SHEER]: {},
    [BLIND]: {},
  },
  product: {},
  slaDurations: {},
  status: LOADING,
};

export const productPricingConfig = createSlice({
  name: "productPricingConfig",
  initialState,
  reducers: {
    addPricingConfigJson(state, action) {
      const { payload } = action;
      state.pricing[payload.category][payload.subcategory] = payload.configJson;
    },
    addProductConfigJson(state, action) {
      const { payload } = action;
      state.product[payload.category] = payload.configJson;
    },
    addSlaDurationsConfig(state, action) {
      state.slaDurations = action.payload;
    },
    updateProductPricingConfigSliceStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const {
  addPricingConfigJson,
  addProductConfigJson,
  addSlaDurationsConfig,
  updateProductPricingConfigSliceStatus,
} = productPricingConfig.actions;

export const selectAllConfigData = (state) => state.productPricingConfig;

export const selectPricingConfig = (state, category, subcategory) =>
  state.productPricingConfig.pricing[category][subcategory];

export const selectProductConfig = (state, category) =>
  state.productPricingConfig.product[category];

export const selectSlaDurationConfig = (state) =>
  state.productPricingConfig.slaDurations;

export default productPricingConfig.reducer;
