import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { parseJSON, format } from "date-fns";

const propTypes = {
  label: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string,
  onHandlePrimaryAction: PropTypes.func.isRequired,
  onHandleSecondaryAction: PropTypes.func,
};

const defaultProps = {
  lastUpdated: null,
  onHandleSecondaryAction: null
};

const ConfigRow = ({ label, lastUpdated, onHandlePrimaryAction, onHandleSecondaryAction }) => (
  <div className="bg-white border-b p-8 flex">
    <div className="w-5/12 capitalize">{label}</div>
    <div className="w-4/12">
      <b>Last Updated: </b>
      {lastUpdated ? format(parseJSON(lastUpdated), "dd/MM/yyyy") : "Never"}
    </div>
    <div className="flex flex-grow justify-end">
      <ActionButton
        label="Upload File"
        background="none"
        onClick={onHandlePrimaryAction}
        type="button"
        icon={lastUpdated ? "Upload" : "UploadRed"}
        className={lastUpdated ? "" : "text-scarlet-red"}
      />
      {(onHandleSecondaryAction) && <ActionButton
        label="SLA"
        background="none"
        onClick={onHandleSecondaryAction}
        type="button"
        icon={lastUpdated ? "Pencil" : "PencilRed"}
        className={lastUpdated ? "" : "text-scarlet-red"}
      />}
    </div>
  </div>
);

ConfigRow.propTypes = propTypes;

ConfigRow.defaultProps = defaultProps;

export default ConfigRow;
