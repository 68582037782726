import React from "react";
import PropTypes from "prop-types";
import { getIcon } from "support/Icon";

const propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  rounded: PropTypes.bool
};

const Icon = ({ type, size, rounded, ...props }) => (
  <img src={getIcon(type)} alt={type} {...props} />
);

Icon.propTypes = propTypes;

Icon.defaultProps = { size: 25, rounded: false };

export default Icon;
