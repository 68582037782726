import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import {
  SELECT_PRODUCT_ROUTE,
  SWATCH_CONFIGURATION_ROUTE,
} from "constants/routes";
import { SWATCH } from "constants/product-categories";
import Item from "./Item";

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  showAddButton: PropTypes.bool,
  productCategory: PropTypes.string.isRequired,
};

const defaultProps = {
  showAddButton: true,
};

const ItemsList = ({ items, showAddButton, productCategory }) => {
  const history = useHistory();

  const defaultButtonProps = {
    background: "action",
    type: "button",
    className: "h-12",
  };

  return (
    <div className="p-10 overflow-y-auto">
      <div className="flex justify-between items-center mb-6">
        <div className="text-xl font-bold">Total Products: {items.length}</div>
        {showAddButton && productCategory === SWATCH && (
          <ActionButton
            {...defaultButtonProps}
            label="Edit"
            icon="PencilWhite"
            onClick={() =>
              history.push(SWATCH_CONFIGURATION_ROUTE, {
                orderItems: items,
                editing: true,
                productSubcategory: items[0].productSubcategory,
              })
            }
          />
        )}
        {showAddButton && productCategory !== SWATCH && (
          <ActionButton
            {...defaultButtonProps}
            label="Add product +"
            onClick={() => history.push(SELECT_PRODUCT_ROUTE)}
          />
        )}
      </div>
      {items.map((item) => (
        <Item
          key={item.uid}
          item={item}
          productCategory={productCategory}
          isEditable={showAddButton && productCategory !== SWATCH}
          isRemovable={showAddButton && items.length > 1}
        />
      ))}
    </div>
  );
};

ItemsList.propTypes = propTypes;

ItemsList.defaultProps = defaultProps;

export default ItemsList;
