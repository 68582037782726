import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
);

export const fabricChartOptions = {
  maintainAspectRatio: true,
  responsive: true,
};

export const colorChartOptions = {
  maintainAspectRatio: false,
  indexAxis: "y",
  responsive: true,
  scales: {
    x: {
      stacked: false,
      display: true
    },
    y: {
      stacked: false,
      display: true
    },
  },
};

export const detailChartOptions = {
  indexAxis: "y",
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      stacked: true,
      display: false
    },
    y: {
      stacked: true,
      display: false
    },
  },
};

export const backgroundColors = ["#536372", "#b0ccd3", "#E0D2C5"];

export const fabricColors = {
  navy: "#001d3d",
  dove: "#afb8bd",
  cream: "#FFFDD0",
  ivory: "#FFFFF0",
  brown: "#964B00",
  burnt: "#CC5500",
  mauve: "#e0b0ff",
  ochre: "#CC7722",
  green: "#249225",
  charcoal: "#36454F",
  white: "#ffffff",
  grey: "#999999",
  blue: "#0077b6",
  teal: "#008080",
  red: "#ff0000",
  blush: "#de5d83",
  seafoam: "#93E9BE",
  mink: "#d2b7a6",
  silver: "#C0C0C0",
  beige: "#E0D2C5",
  latte: "#bdab98",
  mid: "#8b8680",
  linen: "#E9DCC9",
  antique: "#986335",
  azure: "#0080FF",
  fog: "#abaeb0",
  taupe: "#b38b6d",
  spring: "#A3C566",
  autumn: "#DE8C3C",
  jade: "#74BB83",
  dark: "#545454",
  sand: "#EECFAD",
  neutral: "#B0C0C8",
  light: "#cccccc",
  beach: "#FBB995",
  summer: "#F5DF8E",
  sage: "#91946E",
  lavender: "#9A9BC1",
  denim: "#2F6479",
  natural: "#FBEDE5",
  aqua: "#94F1FD",
  snow: "#FFFDF8",
  smoke: "#75766C",
  spa: "#D3DEDF",
  fawn: "#E5AA70",
  ash: "#8F8F8E",
  pure: "#F0EDE1",
  champagne: "#F9F0DF",
  pastel: "#EBCAD5",
  gold: "#EEB52D",
  dusk: "#ECC1B2",
  fossil: "#bbb3ae",
  ebony: "#5C5D54",
  mist: "#99AEAE",
  monochrome: "#5C5D54",
  stone: "#B1A69B",
  black: "#262626",
};

