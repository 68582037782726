import React from "react";
import PropTypes from "prop-types";
import TableHeader from "./TableHeader/TableHeader";
import TableBody from "./TableBody/TableBody";
import ActionButton from "components/Buttons/ActionButton/ActionButton";

const propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  showLoadButtons: PropTypes.bool,
};

const defaultProps = {
  loadMore: null,
  loadingMore: false,
  showLoadButtons: false,
};

const Table = ({ headings, items, loadMore, loadingMore, showLoadButtons }) => (
  <div className="flex flex-col flex-grow overflow-y-auto min-h-0">
    <table className="w-full">
      <TableHeader headings={headings} />
      <TableBody>
        {items.map((item) => (
          <tr key={item.key} className="border-b">
            {Object.values(item.values).map((value) => (
              <td key={value} className="p-8 last:float-right">
                {value}
              </td>
            ))}
          </tr>
        ))}
      </TableBody>
    </table>
    {items.length === 0 && (
      <div className="self-center text-gray-300 my-10 text-lg">
        {showLoadButtons
          ? "No results found, try loading more orders"
          : "No results found"}
      </div>
    )}
    {showLoadButtons && (
      <div className="self-center py-6 flex">
        <ActionButton
          label={loadingMore ? "Loading..." : "Load more"}
          className="py-4"
          onClick={() => loadMore(true)}
          disabled={loadingMore}
        />
        <ActionButton
          label={loadingMore ? "Loading..." : "Load all"}
          className="py-4 ml-4"
          onClick={() => loadMore(false)}
          background="outline"
          disabled={loadingMore}
        />
      </div>
    )}
  </div>
);

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;

export default Table;
