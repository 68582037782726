import React from "react";
import PropTypes from "prop-types";
import FieldEntry from "./FieldEntry";

const propTypes = {
  section: PropTypes.instanceOf(Object).isRequired,
};

const DetailsSection = ({ section }) => (
  <div>
    <div className="flex justify-between items-center mb-4">
      <div className="font-bold text-lg">{section.title}</div>
      {section.button}
    </div>
    <div className="grid grid-cols-2 gap-6">
      {section.entries
        .filter(entry => !entry.hidden)
        .map((entry) => (
          <FieldEntry key={`${entry.label}${entry.values}`} entry={entry}/>)
        )}
    </div>
  </div>
);

DetailsSection.propTypes = propTypes;


export default DetailsSection;
