import React, { useEffect, useState } from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import { useSelector } from "react-redux";
import { selectAllConfigs } from "slices/configSlice";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { IDLE } from "constants/status";
import Spinner from "components/Spinner/Spinner";
import Table from "components/Table/Table";
import { CONFIGS_HEADINGS } from "constants/table-headings";
import { useHistory } from "react-router";
import {
  ADD_CONFIGURATION_ROUTE,
  EDIT_CONFIGURATION_ROUTE,
} from "constants/routes";

const ConfigurationPage = () => {
  // Global state
  const configs = useSelector(selectAllConfigs);
  const configSubscriptionStatus = useSelector((state) => state.configs.status);
  const history = useHistory();

  // Local state
  const [filteredConfigs, setFilteredConfigs] = useState([]);

  useEffect(() => {
    if (configSubscriptionStatus !== IDLE) {
      const preparedConfigs = configs.map((config) => ({
        key: config.uid,
        values: {
          name: config.name,
          updated: config.lastUpdated,
          status: config.complete ? "Complete" : "Incomplete",
          action: (
            <ActionButton
              label="Edit"
              background="none"
              onClick={() =>
                history.push(EDIT_CONFIGURATION_ROUTE, {
                  configUID: config.uid,
                })
              }
              type="button"
              icon="Pencil"
            />
          ),
        },
      }));
      setFilteredConfigs(preparedConfigs);
    }
  }, [configs, configSubscriptionStatus, history]);

  return (
    <div
      id="product-pricing-configuration-page"
      className="h-full flex flex-col"
    >
      {configSubscriptionStatus === IDLE ? (
        <div className="flex flex-grow justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <ActionHeader>
            <div className="flex justify-between w-full">
              <ActionButton
                label="Add new configuration"
                onClick={() => history.push(ADD_CONFIGURATION_ROUTE)}
                type="button"
                icon="Add"
              />
            </div>
          </ActionHeader>
          <Table headings={CONFIGS_HEADINGS} items={filteredConfigs} />
        </>
      )}
    </div>
  );
};

export default ConfigurationPage;
