import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  disabled: PropTypes.bool,
};

const defaultProps = {
  label: null,
  name: null,
  value: "",
  disabled: false,
};

const FormSelect = ({ name, label, options, onChange, value, disabled }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 50,
      borderColor: "#e5e7eb",
      boxShadow: "none",
      cursor: "pointer",
      ":hover": {
        borderColor: "#e5e7eb",
      },
    }),
    indicatorSeparator: () => ({}),
    option: (provided, { isSelected }) => ({
      ...provided,
      color: "#333F48",
      backgroundColor: isSelected ? "rgba(20, 25, 28, 0.2)" : null,
      cursor: "pointer",
      ":active": {
        backgroundColor: isSelected ? "rgba(20, 25, 28, 0.2)" : null,
      },
    }),
  };

  return (
    <div>
      {label && (
        <label htmlFor={name} className="block mb-2 text-sm font-bold">
          {label}
        </label>
      )}
      <Select
        options={options}
        placeholder="Please select"
        styles={customStyles}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
      />
    </div>
  );
};
FormSelect.defaultProps = defaultProps;

FormSelect.propTypes = propTypes;

export default FormSelect;
