import { ACTIVE, CONFIRMED, SAVED } from "constants/status";
import {
  addOrders,
  setLastVisibleConfirmedOrder,
  setLastVisibleSavedOrder,
  updateOrderStatus,
} from "slices/orderSlice";
import { firestore, functions } from "../../firebase";
import dayjs from "dayjs";

const queryLimit = 250;

const prepareOrder = (doc) => {
  const order = doc.data();
  order.createdAt = order.createdAt.toDate().toLocaleDateString();
  order.installationDate = order.installationDate?.seconds ? dayjs(order.installationDate.seconds * 1000).format("YYYY-MM-DD") : "";
  order.storeUID = doc.ref.parent.parent.id;
  order.storeGroupUID = doc.ref.parent.parent.parent.parent.id;
  return order;
};

const handleOrderQuery = async (
  dispatch,
  status,
  limitResults,
  lastVisibleDoc,
  query
) => {
  if (limitResults) {
    query = query.limit(queryLimit);
  }

  const querySnapshot = lastVisibleDoc
    ? await query.startAfter(lastVisibleDoc).get()
    : await query.get();

  if (querySnapshot.empty) {
    dispatch(updateOrderStatus(ACTIVE));
  }
  const preparedOrders = [];
  querySnapshot.forEach((doc) => {
    preparedOrders.push(prepareOrder(doc));
  });
  dispatch(addOrders(preparedOrders));

  let lastVisibleDocToSave;

  if (limitResults) {
    lastVisibleDocToSave =
      querySnapshot.size === queryLimit
        ? querySnapshot.docs[querySnapshot.docs.length - 1]
        : null;
  } else {
    lastVisibleDocToSave = null;
  }

  if (status === CONFIRMED) {
    dispatch(setLastVisibleConfirmedOrder(lastVisibleDocToSave));
  } else {
    dispatch(setLastVisibleSavedOrder(lastVisibleDocToSave));
  }

  return querySnapshot;
};

const getOrdersForAllStoresByStatus = async (
  dispatch,
  status,
  limitResults,
  lastVisibleDoc
) => {
  let query = firestore
    .collectionGroup("orders")
    .where("status", "==", status)
    .orderBy("createdAt", "desc");
  return handleOrderQuery(
    dispatch,
    status,
    limitResults,
    lastVisibleDoc,
    query
  );
};

const getOrdersFromAStoreByStatus = async (
  dispatch,
  status,
  storeGroupUID,
  storeUID,
  limitResults,
  lastVisibleDoc
) => {
  let query = firestore
    .collection("storeGroups")
    .doc(storeGroupUID)
    .collection("stores")
    .doc(storeUID)
    .collection("orders")
    .where("status", "==", status)
    .orderBy("createdAt", "desc");
  return handleOrderQuery(
    dispatch,
    status,
    limitResults,
    lastVisibleDoc,
    query
  );
};

const getOrdersFromAGroupByStatus = async (
  dispatch,
  status,
  storeGroupUID,
  limitResults,
  lastVisibleDoc
) => {
  let query = firestore
    .collectionGroup("orders")
    .where("storeGroupUID", "==", storeGroupUID)
    .where("status", "==", status)
    .orderBy("createdAt", "desc");
  return handleOrderQuery(
    dispatch,
    status,
    limitResults,
    lastVisibleDoc,
    query
  );
};

const getOrderItems = (order) => {
  const collectionRef = firestore
    .collection("storeGroups")
    .doc(order.storeGroupUID)
    .collection("stores")
    .doc(order.storeUID)
    .collection("orders")
    .doc(order.uid)
    .collection("items");
  return collectionRef.get();
};

const saveOrder = (order) => {
  const { items, complete, ...rest } = order;
  rest.status = SAVED;
  const batch = firestore.batch();
  const orderRef = firestore
    .collection("storeGroups")
    .doc(order.storeGroupUID)
    .collection("stores")
    .doc(order.storeUID)
    .collection("orders")
    .doc(rest.uid);
  batch.set(orderRef, rest);
  const itemsCollectionRef = orderRef.collection("items");
  order.items.forEach((item) => {
    const itemRef = itemsCollectionRef.doc(item.uid);
    batch.set(itemRef, item);
  });
  return batch.commit();
};

const updateSavedQuote = async (order) => {
  const { items, complete, createdAt, ...rest } = order;
  let { installationDate } = rest;
  if (installationDate && installationDate !== "") {
    rest.installationDate = new Date(installationDate);
  };
  const batch = firestore.batch();
  const orderRef = firestore
    .collection("storeGroups")
    .doc(order.storeGroupUID)
    .collection("stores")
    .doc(order.storeUID)
    .collection("orders")
    .doc(rest.uid);
  batch.update(orderRef, rest);
  const itemsCollectionRef = orderRef.collection("items");
  const itemsSnapshot = await itemsCollectionRef.get();
  const currentItems = [];
  itemsSnapshot.forEach((item) => {
    currentItems.push(item.id);
    const itemExists = order.items.some((newItem) => newItem.uid === item.id);
    if (!itemExists) {
      const itemRef = itemsCollectionRef.doc(item.id);
      batch.delete(itemRef);
    }
  });
  order.items.forEach((item) => {
    const itemExists = currentItems.some((oldItem) => oldItem === item.uid);
    const itemRef = itemsCollectionRef.doc(item.uid);
    if (itemExists) {
      batch.update(itemRef, item);
    } else {
      batch.set(itemRef, item);
    }
  });
  return batch.commit();
};

const fetchQuotePdf = async (orderUID) => {
  const printQuoteFunction = functions.httpsCallable("printQuote");
  return printQuoteFunction(JSON.stringify({ orderUID }));
};

const completeOrder = async (orderUID) => {
  const completeOrderFunction = functions.httpsCallable("completeOrder");
  return completeOrderFunction(JSON.stringify({ orderUID }));
};

const deleteOrder = (order) =>
  firestore
    .collection("storeGroups")
    .doc(order.storeGroupUID)
    .collection("stores")
    .doc(order.storeUID)
    .collection("orders")
    .doc(order.uid)
    .delete();

const exportOrders = async (data) => {
  const exportOrdersFunction = functions.httpsCallable("exportOrderItems");
  return exportOrdersFunction(data);
};

export default {
  getOrdersForAllStoresByStatus,
  getOrdersFromAStoreByStatus,
  getOrdersFromAGroupByStatus,
  getOrderItems,
  deleteOrder,
  saveOrder,
  completeOrder,
  updateSavedQuote,
  exportOrders,
  fetchQuotePdf,
};
