import React from 'react';

const FinesseLogo = ({...props}) => (
  <svg width="300" height="46" viewBox="0 0 300 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.2078 6.1521H6.00632C6.0072 5.6228 5.88835 5.10016 5.65864 4.6233C5.42893 4.14644 5.09433 3.72772 4.67988 3.39849C3.82859 2.62892 2.72124 2.20394 1.57367 2.20638H0.666992V1.63551H6.2078V6.1521ZM6.2078 40.438V44.9545H0.666992V44.3333H1.57367C2.705 44.3526 3.80206 43.9449 4.6463 43.1916C5.05237 42.8527 5.38312 42.4327 5.61733 41.9585C5.85154 41.4843 5.98404 40.9664 6.00632 40.438H6.2078ZM10.5061 1.63551V44.9545H6.00632V1.63551H10.5061ZM29.2105 1.63551V3.56639H10.3046V1.63551H29.2105ZM26.2387 22.6738V24.5375H10.3046V22.6738H26.2387ZM10.3046 40.438H10.5061C10.5284 40.9664 10.6609 41.4843 10.8951 41.9585C11.1293 42.4327 11.4601 42.8527 11.8661 43.1916C12.7086 43.9478 13.8069 44.3559 14.9388 44.3333H15.7783V44.9545H10.3046V40.438ZM29.2105 0.527344V2.32391L20.8154 1.63551C21.8758 1.63521 22.9354 1.57354 23.9888 1.45081C25.1137 1.33328 26.1715 1.18217 27.1453 1.01426C27.849 0.925107 28.5412 0.761896 29.2105 0.527344V0.527344ZM26.2387 18.7113V22.8081H22.209V22.6738C23.0897 22.6601 23.9337 22.319 24.5764 21.7167C24.8821 21.4519 25.1273 21.1244 25.2953 20.7565C25.4634 20.3886 25.5503 19.9888 25.5503 19.5844V18.7113H26.2387ZM26.2387 24.4032V28.5H25.5503V27.5598C25.5481 27.1557 25.4602 26.7566 25.2923 26.389C25.1245 26.0214 24.8804 25.6936 24.5764 25.4274C23.9334 24.8336 23.0839 24.5143 22.209 24.5375V24.4032H26.2387ZM29.2105 3.3817V7.51212H28.5221V6.58865C28.5235 6.18232 28.4355 5.78067 28.2643 5.41216C28.0931 5.04365 27.8429 4.71732 27.5315 4.45628C26.8843 3.85833 26.028 3.53873 25.1473 3.56639V3.3817H29.2105Z" fill="#333F48"/>
    <path d="M50.6355 6.1521H50.4173C50.4002 5.62435 50.2717 5.10618 50.0402 4.63161C49.8087 4.15704 49.4794 3.7368 49.074 3.39848C48.2374 2.62683 47.1395 2.20086 46.0014 2.20637H45.1115V1.6355H50.6523L50.6355 6.1521ZM50.6355 40.4379V44.9545H45.0947V44.3333H46.0014C47.1385 44.3568 48.2423 43.9489 49.0908 43.1916C49.497 42.854 49.8269 42.4339 50.0584 41.9591C50.29 41.4844 50.418 40.9659 50.4341 40.4379H50.6355ZM54.9339 1.6355V44.9545H50.4173V1.6355H54.9339ZM54.7156 6.1521V1.6355H60.2564V2.20637H59.4337C58.2966 2.18285 57.1928 2.59078 56.3442 3.34811C55.9389 3.68643 55.6096 4.10667 55.3781 4.58124C55.1466 5.05581 55.0181 5.57398 55.001 6.10173L54.7156 6.1521ZM54.7156 40.4379H54.9339C54.9499 40.9659 55.0779 41.4844 55.3095 41.9591C55.5411 42.4339 55.8709 42.854 56.2771 43.1916C57.1256 43.9489 58.2294 44.3568 59.3665 44.3333H60.2564V44.9545H54.7156V40.4379Z" fill="#333F48"/>
    <path d="M80.1526 40.5563V44.9554H74.3936V44.3342H75.72C76.8154 44.3554 77.8779 43.9593 78.6919 43.226C79.0882 42.9046 79.4061 42.4972 79.6213 42.0346C79.8366 41.572 79.9437 41.0665 79.9344 40.5563H80.1526ZM80.0183 0.712891L83.0574 4.1717V44.9554H79.8672V8.45323C79.8552 5.86365 79.6701 3.27778 79.3131 0.712891H80.0183ZM80.0183 0.712891L120.282 39.1459L120.903 45.8621L80.606 7.54655L80.0183 0.712891ZM83.1245 40.5563C83.1172 41.0678 83.2269 41.5743 83.4451 42.0369C83.6634 42.4996 83.9844 42.9063 84.3838 43.226C85.1969 43.9607 86.26 44.3571 87.3557 44.3342H88.6821V44.9554H82.9398V40.5563H83.1245ZM112.172 1.63636H117.914V6.03542H117.78C117.767 5.52491 117.648 5.0226 117.432 4.56021C117.215 4.09782 116.905 3.68539 116.52 3.34897C115.69 2.59484 114.603 2.18642 113.481 2.20723H112.172V1.63636ZM120.903 1.63636V37.9035C120.903 39.2131 120.903 40.4892 121.037 41.6981C121.171 42.907 121.289 44.3006 121.524 45.8285H120.903L117.78 42.4704V1.63636H120.903ZM126.444 1.63636V2.20723H125.201C124.104 2.18862 123.039 2.57748 122.212 3.2986C121.815 3.62691 121.492 4.03654 121.267 4.49993C121.041 4.96332 120.917 5.46972 120.903 5.98505H120.684V1.63636H126.444Z" fill="#333F48"/>
    <path d="M145.501 6.15235H145.282C145.283 5.62481 145.165 5.10383 144.939 4.62743C144.712 4.15104 144.382 3.73125 143.973 3.39873C143.117 2.62526 142.003 2.2 140.85 2.20662H140.027V1.58538H145.568L145.501 6.15235ZM145.501 40.4382V44.9548H140.027V44.3336H140.917C142.054 44.3571 143.158 43.9491 144.006 43.1918C144.412 42.8542 144.742 42.4341 144.974 41.9594C145.205 41.4847 145.333 40.9661 145.349 40.4382H145.501ZM149.799 1.63575V44.9548H145.282V1.63575H149.799ZM168.57 1.63575V3.56664H149.581V1.63575H168.57ZM165.531 22.674V24.5378H149.581V22.674H165.531ZM168.57 43.0407V44.9548H149.581V43.0407H168.57ZM172.802 34.931L168.772 45.0052H157.203L159.705 43.0911C161.752 43.1594 163.791 42.8109 165.699 42.0669C167.179 41.4174 168.476 40.4142 169.477 39.1453C170.482 37.8398 171.338 36.4263 172.029 34.931H172.802ZM168.57 0.527588V2.32415L160.175 1.63575C161.208 1.63312 162.239 1.57146 163.265 1.45106C164.406 1.33352 165.447 1.18241 166.421 1.01451C167.153 0.929198 167.873 0.765965 168.57 0.527588V0.527588ZM165.531 18.7115V22.8084H161.502V22.674C162.378 22.6611 163.217 22.3195 163.852 21.717C164.161 21.4543 164.408 21.1273 164.576 20.7589C164.744 20.3905 164.83 19.9896 164.826 19.5846V18.7115H165.531ZM165.531 24.4034V28.5003H164.826V27.56C164.827 27.1554 164.741 26.7553 164.573 26.3872C164.405 26.0191 164.159 25.6918 163.852 25.4276C163.214 24.8379 162.371 24.5189 161.502 24.5378V24.4034H165.531ZM168.57 3.38194V8.3183H167.882V7.24372C167.884 6.75585 167.781 6.2733 167.578 5.82965C167.375 5.386 167.077 4.99191 166.707 4.6748C165.908 3.94542 164.867 3.53855 163.785 3.53306V3.38194H168.57Z" fill="#333F48"/>
    <path d="M200.103 0.779147C200.842 0.779147 201.631 0.779147 202.487 0.896679C203.344 1.01421 204.166 1.09816 204.989 1.23249L207.189 1.66903C207.723 1.7747 208.246 1.93215 208.75 2.13916V9.00641H208.129C208.162 8.10054 207.971 7.20055 207.572 6.3863C207.174 5.57205 206.581 4.86871 205.845 4.3387C204.229 3.18159 202.275 2.5913 200.288 2.65966C198.038 2.56522 195.836 3.32721 194.126 4.79204C193.387 5.40628 192.793 6.17644 192.386 7.04729C191.98 7.91815 191.771 8.86813 191.775 9.82913C191.803 10.775 192.002 11.7081 192.363 12.5827C192.819 13.7372 193.572 14.7508 194.546 15.5211L208.196 26.7874C209.436 27.7535 210.392 29.0351 210.966 30.498C211.405 31.7274 211.638 33.0209 211.655 34.3262C211.716 35.8951 211.435 37.4585 210.832 38.9081C210.229 40.3577 209.318 41.6588 208.163 42.7214C205.552 44.9204 202.202 46.043 198.793 45.8612C197.478 45.84 196.166 45.7334 194.865 45.5422C193.376 45.3372 191.913 44.9771 190.499 44.4676C189.219 44.0212 188.034 43.3377 187.007 42.4527C186.852 41.6337 186.779 40.8013 186.788 39.9678C186.796 38.9206 186.892 37.876 187.074 36.8448C187.24 35.8288 187.493 34.829 187.829 33.8561H188.467C188.2 35.7396 188.51 37.6599 189.357 39.3633C190.168 40.9026 191.443 42.1479 193.001 42.9229C194.928 43.8188 197.064 44.1704 199.178 43.9395C201.291 43.7087 203.3 42.9042 204.989 41.6132C205.881 40.915 206.593 40.0152 207.069 38.9877C207.545 37.9602 207.77 36.8345 207.726 35.703C207.74 34.5851 207.505 33.4781 207.038 32.4625C206.563 31.4827 205.874 30.622 205.023 29.9439L191.725 18.9295C190.41 17.9332 189.375 16.6146 188.719 15.1013C188.201 13.8784 187.922 12.5675 187.897 11.2395C187.898 9.46768 188.373 7.72842 189.273 6.20242C190.256 4.53285 191.687 3.17184 193.404 2.27349C195.472 1.21077 197.779 0.696274 200.103 0.779147ZM208.767 1.40039V2.69324H203.495V1.40039H208.767Z" fill="#333F48"/>
    <path d="M239.944 0.779572C240.683 0.779572 241.472 0.779572 242.328 0.897104C243.185 1.01464 244.007 1.09859 244.83 1.23291L247.047 1.66946C247.581 1.77513 248.104 1.93258 248.608 2.13959V9.00683H247.987C248.02 8.10097 247.829 7.20098 247.43 6.38673C247.032 5.57247 246.439 4.86914 245.703 4.33912C244.087 3.18201 242.133 2.59172 240.146 2.66009C237.923 2.57341 235.749 3.32218 234.051 4.75888C233.315 5.37618 232.724 6.14695 232.318 7.0171C231.912 7.88726 231.701 8.83573 231.7 9.79598C231.728 10.7419 231.927 11.6749 232.288 12.5496C232.75 13.701 233.502 14.7133 234.471 15.4879L248.121 26.7542C249.34 27.7084 250.279 28.9726 250.841 30.4145C251.28 31.6439 251.513 32.9374 251.53 34.2427C251.591 35.8115 251.31 37.3749 250.707 38.8246C250.104 40.2742 249.193 41.5753 248.037 42.6379C245.413 44.8916 242.021 46.0463 238.567 45.8616C237.252 45.8404 235.94 45.7338 234.639 45.5426C233.15 45.3376 231.687 44.9775 230.273 44.468C228.993 44.0217 227.808 43.3381 226.781 42.4532C226.626 41.6341 226.553 40.8017 226.562 39.9682C226.57 38.9211 226.666 37.8764 226.848 36.8452C227.014 35.8293 227.267 34.8294 227.603 33.8565H228.241C227.974 35.74 228.284 37.6603 229.131 39.3637C229.942 40.903 231.217 42.1483 232.775 42.9233C234.702 43.8192 236.838 44.1708 238.952 43.94C241.065 43.7091 243.074 42.9046 244.763 41.6136C245.656 40.9103 246.369 40.0043 246.842 38.9706C247.315 37.937 247.535 36.8055 247.483 35.6699C247.503 34.5532 247.273 33.4463 246.811 32.4293C246.337 31.4496 245.648 30.5889 244.797 29.9108L231.499 18.8963C230.22 17.899 229.214 16.5934 228.577 15.1017C228.059 13.8789 227.779 12.568 227.754 11.2399C227.755 9.46811 228.231 7.72885 229.131 6.20285C230.114 4.53327 231.545 3.17226 233.262 2.27391C235.325 1.214 237.626 0.699585 239.944 0.779572ZM248.608 1.40081V2.69367H243.336V1.40081H248.608Z" fill="#333F48"/>
    <path d="M272.099 6.15235H271.898C271.899 5.62305 271.78 5.1004 271.55 4.62354C271.321 4.14668 270.986 3.72796 270.571 3.39873C269.72 2.62917 268.613 2.20418 267.465 2.20662H266.626V1.58538H272.167L272.099 6.15235ZM272.099 40.4382V44.9548H266.559V44.3336H267.465C268.597 44.3528 269.694 43.9452 270.538 43.1918C270.944 42.853 271.275 42.4329 271.509 41.9587C271.743 41.4845 271.876 40.9666 271.898 40.4382H272.099ZM276.398 1.63575V44.9548H271.898V1.63575H276.398ZM295.102 1.63575V3.56664H276.196V1.63575H295.102ZM292.13 22.674V24.5378H276.196V22.674H292.13ZM295.102 43.0407V44.9548H276.196V43.0407H295.102ZM299.333 34.931L295.32 45.0052H283.819L286.304 43.0911C288.351 43.1594 290.39 42.8109 292.298 42.0669C293.778 41.4174 295.075 40.4142 296.076 39.1453C297.086 37.8404 297.948 36.4269 298.645 34.931H299.333ZM295.102 0.527588V2.32415L286.707 1.63575C287.784 1.63741 288.861 1.57574 289.931 1.45106C291.056 1.33352 292.113 1.18241 293.071 1.01451C293.763 0.923048 294.444 0.759865 295.102 0.527588V0.527588ZM292.13 18.7115V22.8084H288.101V22.674C288.981 22.6604 289.825 22.3192 290.468 21.717C290.774 21.4522 291.019 21.1247 291.187 20.7568C291.355 20.3888 291.442 19.9891 291.442 19.5846V18.7115H292.13ZM292.13 24.4034V28.5003H291.442V27.56C291.44 27.1559 291.352 26.7568 291.184 26.3892C291.016 26.0216 290.772 25.6939 290.468 25.4276C289.825 24.8338 288.976 24.5145 288.101 24.5378V24.4034H292.13ZM295.102 3.38194V8.3183H294.498V7.24372C294.5 6.75585 294.396 6.2733 294.193 5.82965C293.99 5.386 293.693 4.99191 293.322 4.6748C292.528 3.94928 291.494 3.54269 290.418 3.53306V3.38194H295.102Z" fill="#333F48"/>
  </svg>
);

export default FinesseLogo;