export const LOGIN_ROUTE = "/login";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const CREATE_PASSWORD_ROUTE = "/create-password";
export const HOME_ROUTE = "/";
export const USER_MANAGEMENT_ROUTE = "/user-management";
export const GROUP_MANAGEMENT_ROUTE = "/group-management";
export const ADD_GROUP_ROUTE = "/group-management/add-group";
export const EDIT_GROUP_ROUTE = "/group-management/edit-group";
export const STORE_MANAGEMENT_ROUTE = "/store-management";
export const ADD_STORE_ROUTE = "/store-management/add-store";
export const EDIT_STORE_ROUTE = "/store-management/edit-store";
export const CONFIGURATIONS_ROUTE = "/configurations";
export const ADD_CONFIGURATION_ROUTE = "/configurations/add-configuration";
export const EDIT_CONFIGURATION_ROUTE = "/configurations/edit-configuration";
export const ADD_USER_ROUTE = "/user-management/add-user";
export const EDIT_USER_ROUTE = "/user-management/edit-user";
export const VIEW_ORDER_ROUTE = "/order";
export const SAVED_QUOTES_ROUTE = "/saved-quotes";
export const CREATE_ORDER_ROUTE = "/create-order";
export const SELECT_PRODUCT_ROUTE = "/create-order/select-product";
export const PRODUCT_CONFIGURATION_ROUTE =
  "/create-order/product-configuration";
export const ORDER_DETAILS_ROUTE = "/create-order/order-details";
export const ORDER_SUMMARY_ROUTE = "/create-order/order-summary";
export const SWATCH_CONFIGURATION_ROUTE = "/create-order/swatch-configuration";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const ANALYTICS_ROUTE = "/analytics";
