import { BLIND, FABRIC, SHEER } from "./product-categories";
import {
  CURTAIN,
  CUSHION,
  CUT_LENGTH,
  JARDINIERE,
  ROLLER,
  ROMAN_BLIND,
  ROMAN_BLIND_KIT,
  TIE_BACK,
  VALANCE,
  VERTICAL,
} from "./product-subcategory";

export const PRICING_CONFIGURATION_INPUTS = [
  {
    key: FABRIC,
    label: "Fabrics",
    subcategories: [
      {
        label: "Curtains",
        key: CURTAIN,
      },
      {
        label: "Roman Blinds",
        key: ROMAN_BLIND,
      },
      {
        label: "Roman Blinds Kit",
        key: ROMAN_BLIND_KIT,
      },
      {
        label: "Cut Length",
        key: CUT_LENGTH,
      },
      {
        label: "Cushions",
        key: CUSHION,
      },
      {
        label: "Valance",
        key: VALANCE,
      },
      {
        label: "Tie Backs",
        key: TIE_BACK,
      },
    ],
  },
  {
    key: BLIND,
    label: "Blinds",
    subcategories: [
      {
        label: "Vertical Blinds",
        key: VERTICAL,
      },
      {
        label: "Roller Blinds",
        key: ROLLER,
      },
    ],
  },
  {
    key: SHEER,
    label: "Sheers",
    subcategories: [
      {
        label: "Curtain",
        key: CURTAIN,
      },
      {
        label: "Jardinieres",
        key: JARDINIERE,
      },
      {
        label: "Roman Blinds",
        key: ROMAN_BLIND,
      },
      {
        label: "Cut Length",
        key: CUT_LENGTH,
      },
    ],
  },
];

export const PRODUCT_CONFIGURATION_INPUTS = [
  {
    label: "Fabric Products",
    key: FABRIC,
  },
  {
    label: "Blind Products",
    key: BLIND,
  },
  {
    label: "Sheer Products",
    key: SHEER,
  },
];
