export const DETAIL_FORM_FIELD_IDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
  TELEPHONE: "telephone",
  ADDRESS_LINE_1: "addressLine1",
  ADDRESS_LINE_2: "addressLine2",
  CITY: "city",
  POSTCODE: "postcode",
  INSTALLATION_DATE: "installationDate",
  INSTALLATION_HEIGHT: "installationHeight",
  INSTALLATION_REQUIRED: "installationRequired",
  CONSULTANT_NAME: "consultantName",
  PO_NUMBER: "poNumber",
  DELIVERY_DESTINATION: "deliveryDestination",
  SELECTED_STORE: 'selectedStore'
};

export const DESTINATION_OPTION = {
  STORE: 'store',
  CUSTOMER: 'customer'
}