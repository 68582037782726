import { ADDED, MODIFIED, REMOVED } from "constants/change-types";
import { ACTIVE } from "constants/status";
import {
  addStoreGroup,
  removeStoreGroup,
  updateStoreGroup,
  updateStoreGroupStatus,
} from "slices/storeGroupSlice";
import { firestore } from "../../firebase";

const storeGroupCollectionReference = firestore.collection("storeGroups");

const subscribeToStoreGroups = (dispatch) =>
  storeGroupCollectionReference.onSnapshot((snapshot) => {
    if (snapshot.empty) {
      dispatch(updateStoreGroupStatus(ACTIVE));
    }
    snapshot.docChanges().forEach((change) => {
      if (change.type === ADDED) {
        dispatch(addStoreGroup(change.doc.data()));
      }
      if (change.type === REMOVED) {
        dispatch(removeStoreGroup(change.doc.data()));
      }
      if (change.type === MODIFIED) {
        dispatch(updateStoreGroup(change.doc.data()));
      }
    });
  });

const createGroup = async (groupData) => {
  const newGroupRef = storeGroupCollectionReference.doc();
  groupData.uid = newGroupRef.id;
  return newGroupRef.set(groupData);
};

const updateGroup = (groupData) =>
  storeGroupCollectionReference
    .doc(groupData.uid)
    .set(groupData, { merge: true });

const getGroupData = async (groupUID) => {
  const groupSnapShot = await storeGroupCollectionReference.doc(groupUID).get();
  return groupSnapShot.data();
};

export default {
  subscribeToStoreGroups,
  createGroup,
  updateGroup,
  getGroupData,
};
