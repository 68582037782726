import { ADMIN, STORE_GROUP } from "constants/access-level";

const gbpFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

// Capatilises the first letter of the string
export function toTitleCase(value) {
  // It's possible that a number can be passed into this function so we must check if the value is a string
  return typeof value === "string"
    ? `${value[0].toUpperCase()}${value.slice(1)}`
    : value;
}

export function getStoreGroupNameFromUID(groupUID, groups) {
  const matchingGroup = groups.find((group) => group.uid === groupUID);
  return matchingGroup ? matchingGroup.name : "N/A";
}

export function getStoreNameFromUID(storeUID, stores) {
  const matchingStore = stores.find((store) => store.uid === storeUID);
  return matchingStore ? matchingStore.name : "N/A";
}

export function getStoreCountFromUID(groupUID, stores) {
  const storeForThisGroup = stores.filter(
    (store) => store.storeGroupUID === groupUID
  );
  return storeForThisGroup.length;
}

export function checkSearchQuery(searchQuery, fields) {
  if (!searchQuery) {
    return true;
  }
  return fields.some((field) =>
    field !== undefined && field.toLowerCase().includes(searchQuery.toLowerCase())
  );
}

export function convertNumberToGBP(number) {
  return gbpFormatter.format(number);
}

export function getUserTypeFromAccessLevel(accessLevel) {
  switch (accessLevel) {
    case ADMIN:
      return "Admin User";
    case STORE_GROUP:
      return "Group User";
    default:
      return null;
  }
}

export function isFormInvalid(fields) {
  return fields.some(
    (field) =>
      field === null || field === undefined || field.toString().trim() === ""
  );
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function createAddressString(address) {
  return address.filter((line) => line !== undefined && line !== "").join("\n");
}

export function calculateTotalPriceFromItemsInOrder(items) {
  return items.map((item) => item.price).reduce((a, b) => a + b, 0);
}

export function sortUsingStringDate(a, b) {
  a = a.split("/").reverse().join("");
  b = b.split("/").reverse().join("");
  return b.localeCompare(a);
}

export function areDecimalsValid(maxDecimalCount, value) {
  if (value % 1 === 0) {
    return true;
  }
  return value.toString().split(".")[1].length <= maxDecimalCount;
}

export function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000;
}

export function millisecondsToDays(milliseconds) {
  return milliseconds / 24 / 60 / 60 / 1000;
}

export default {
  toTitleCase,
  getStoreGroupNameFromUID,
  getStoreNameFromUID,
  checkSearchQuery,
  getUserTypeFromAccessLevel,
  getStoreCountFromUID,
  isFormInvalid,
  formatBytes,
  toBase64,
  createAddressString,
  calculateTotalPriceFromItemsInOrder,
  sortUsingStringDate,
  areDecimalsValid,
};
