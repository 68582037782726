import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import Icon from "components/Icon/Icon";

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  submitButtonProps: PropTypes.instanceOf(Object),
  closeButtonProps: PropTypes.instanceOf(Object),
  dismissDialog: PropTypes.func.isRequired,
  showDismissDialogButton: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  title: "",
  description: "",
  onSubmit: () => {},
  onClose: () => {},
  submitButtonProps: {
    label: "Submit",
    type: "button",
  },
  closeButtonProps: {
    label: "Cancel",
    type: "button",
    background: "outline",
  },
  showDismissDialogButton: false,
  loading: false,
};

const ConfirmActionDialog = ({
  title,
  description,
  onSubmit,
  onClose,
  submitButtonProps,
  closeButtonProps,
  dismissDialog,
  showDismissDialogButton,
  loading,
}) => (
  <div className="bg-white rounded-md w-3/12 shadow">
    <div className="flex flex-grow flex-col p-4">
      <div className="text-xl font-bold border-b pb-2 flex items-center justify-between">
        <div>{title}</div>
        {showDismissDialogButton && (
          <button onClick={dismissDialog}>
            <Icon type="Close" />
          </button>
        )}
      </div>
      <div className="pt-6">{description}</div>
    </div>
    <div className="flex p-2">
      {closeButtonProps.hide !== true && (
        <ActionButton
          className="m-2 w-full p-3"
          onClick={onClose}
          {...closeButtonProps}
          disabled={loading}
        />
      )}
      <ActionButton
        className="m-2 w-full p-3"
        onClick={onSubmit}
        {...submitButtonProps}
        disabled={loading}
      />
    </div>
  </div>
);

ConfirmActionDialog.defaultProps = defaultProps;

ConfirmActionDialog.propTypes = propTypes;

export default ConfirmActionDialog;
