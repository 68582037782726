import React from 'react';

const FinesseWhiteLogo = ({...props}) => (
  <svg width="187" height="30" viewBox="0 0 187 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.45942 4.35364H3.33363C3.33418 4.02316 3.25997 3.69685 3.11655 3.39912C2.97313 3.10139 2.76422 2.83996 2.50546 2.63441C1.97396 2.15393 1.28258 1.88859 0.566087 1.89011H0V1.53368H3.45942V4.35364ZM3.45942 25.7601V28.5801H0V28.1922H0.566087C1.27244 28.2042 1.95739 27.9497 2.48449 27.4794C2.73803 27.2678 2.94453 27.0056 3.09076 26.7095C3.23699 26.4134 3.31972 26.09 3.33363 25.7601H3.45942ZM6.1431 1.53368V28.5801H3.33363V1.53368H6.1431ZM17.8213 1.53368V2.73924H6.0173V1.53368H17.8213ZM15.9658 14.669V15.8326H6.0173V14.669H15.9658ZM6.0173 25.7601H6.1431C6.15701 26.09 6.23973 26.4134 6.38596 26.7095C6.53219 27.0056 6.7387 27.2678 6.99223 27.4794C7.51824 27.9515 8.20393 28.2063 8.91063 28.1922H9.43479V28.5801H6.0173V25.7601ZM17.8213 0.841797V1.96349L12.5797 1.53368C13.2418 1.5335 13.9034 1.495 14.561 1.41837C15.2634 1.34499 15.9238 1.25064 16.5318 1.14581C16.9712 1.09014 17.4034 0.98824 17.8213 0.841797V0.841797ZM15.9658 12.195V14.7529H13.4498V14.669C13.9997 14.6605 14.5266 14.4475 14.9279 14.0715C15.1188 13.9061 15.2719 13.7017 15.3768 13.4719C15.4817 13.2422 15.536 12.9927 15.536 12.7401V12.195H15.9658ZM15.9658 15.7488V18.3066H15.536V17.7196C15.5346 17.4673 15.4797 17.2181 15.3749 16.9886C15.2701 16.7591 15.1178 16.5545 14.9279 16.3882C14.5265 16.0175 13.9961 15.8181 13.4498 15.8326V15.7488H15.9658ZM17.8213 2.62392V5.20277H17.3915V4.6262C17.3923 4.3725 17.3373 4.12173 17.2305 3.89165C17.1236 3.66157 16.9674 3.45782 16.773 3.29484C16.3689 2.92151 15.8342 2.72196 15.2844 2.73924V2.62392H17.8213Z" fill="white"/>
    <path d="M31.1982 4.35365H31.0619C31.0513 4.02415 30.971 3.70063 30.8265 3.40433C30.6819 3.10803 30.4764 2.84565 30.2233 2.63442C29.7009 2.15264 29.0154 1.88668 28.3049 1.89012H27.7493V1.53369H31.2087L31.1982 4.35365ZM31.1982 25.7601V28.5801H27.7388V28.1922H28.3049C29.0148 28.2069 29.704 27.9522 30.2337 27.4794C30.4874 27.2686 30.6933 27.0063 30.8379 26.7099C30.9825 26.4135 31.0624 26.0898 31.0724 25.7601H31.1982ZM33.8819 1.53369V28.5801H31.0619V1.53369H33.8819ZM33.7456 4.35365V1.53369H37.205V1.89012H36.6913C35.9814 1.87543 35.2922 2.13012 34.7624 2.60297C34.5093 2.8142 34.3038 3.07658 34.1592 3.37288C34.0147 3.66918 33.9345 3.9927 33.9238 4.3222L33.7456 4.35365ZM33.7456 25.7601H33.8819C33.8919 26.0898 33.9718 26.4135 34.1164 26.7099C34.261 27.0063 34.4669 27.2686 34.7205 27.4794C35.2503 27.9522 35.9395 28.2069 36.6494 28.1922H37.205V28.5801H33.7456V25.7601Z" fill="white"/>
    <path d="M49.6274 25.8339V28.5805H46.0317V28.1926H46.8599C47.5438 28.2059 48.2072 27.9586 48.7154 27.5007C48.9629 27.3001 49.1613 27.0457 49.2957 26.7569C49.4301 26.4681 49.497 26.1524 49.4912 25.8339H49.6274ZM49.5436 0.95752L51.441 3.11704V28.5805H49.4492V5.79023C49.4417 4.17342 49.3262 2.55892 49.1033 0.95752H49.5436ZM49.5436 0.95752L74.6821 24.9533L75.0699 29.1466L49.9105 5.22414L49.5436 0.95752ZM51.483 25.8339C51.4784 26.1533 51.5468 26.4695 51.6831 26.7583C51.8194 27.0472 52.0198 27.3011 52.2692 27.5007C52.7769 27.9595 53.4406 28.207 54.1247 28.1926H54.9529V28.5805H51.3676V25.8339H51.483ZM69.6187 1.53409H73.2039V4.28066H73.1201C73.1121 3.96192 73.0381 3.6483 72.9028 3.35961C72.7674 3.07091 72.5737 2.81341 72.3338 2.60337C71.8154 2.13252 71.1366 1.87753 70.4364 1.89052H69.6187V1.53409ZM75.0699 1.53409V24.1776C75.0699 24.9953 75.0699 25.792 75.1538 26.5468C75.2377 27.3016 75.311 28.1716 75.4578 29.1256H75.0699L73.1201 27.029V1.53409H75.0699ZM78.5294 1.53409V1.89052H77.7536C77.0687 1.8789 76.4038 2.12169 75.8876 2.57192C75.6395 2.7769 75.4381 3.03265 75.297 3.32197C75.156 3.61129 75.0786 3.92746 75.0699 4.24921H74.9337V1.53409H78.5294Z" fill="white"/>
    <path d="M90.4273 4.35364H90.291C90.2913 4.02427 90.218 3.69899 90.0766 3.40155C89.9351 3.10411 89.729 2.84202 89.4733 2.63441C88.9391 2.15149 88.2435 1.88597 87.5234 1.89011H87.0098V1.50223H90.4692L90.4273 4.35364ZM90.4273 25.7601V28.5801H87.0098V28.1922H87.5654C88.2753 28.2069 88.9645 27.9522 89.4943 27.4794C89.7479 27.2686 89.9538 27.0063 90.0984 26.7099C90.243 26.4135 90.3229 26.0898 90.3329 25.7601H90.4273ZM93.1109 1.53368V28.5801H90.291V1.53368H93.1109ZM104.831 1.53368V2.73924H92.9747V1.53368H104.831ZM102.934 14.669V15.8326H92.9747V14.669H102.934ZM104.831 27.385V28.5801H92.9747V27.385H104.831ZM107.473 22.3217L104.957 28.6115H97.734L99.296 27.4165C100.574 27.4591 101.847 27.2415 103.038 26.777C103.962 26.3715 104.772 25.7451 105.397 24.9529C106.024 24.1378 106.559 23.2553 106.991 22.3217H107.473ZM104.831 0.841797V1.96349L99.5895 1.53368C100.234 1.53204 100.878 1.49354 101.518 1.41837C102.231 1.34499 102.881 1.25064 103.489 1.14581C103.946 1.09254 104.396 0.990629 104.831 0.841797V0.841797ZM102.934 12.195V14.7529H100.418V14.669C100.964 14.6609 101.488 14.4477 101.885 14.0715C102.078 13.9075 102.232 13.7033 102.337 13.4733C102.442 13.2432 102.495 12.993 102.493 12.7401V12.195H102.934ZM102.934 15.7488V18.3066H102.493V17.7196C102.494 17.4669 102.44 17.2171 102.335 16.9873C102.23 16.7575 102.077 16.5531 101.885 16.3882C101.486 16.02 100.96 15.8209 100.418 15.8326V15.7488H102.934ZM104.831 2.62392V5.70596H104.401V5.03504C104.403 4.73043 104.338 4.42915 104.211 4.15216C104.084 3.87517 103.899 3.62911 103.667 3.43112C103.169 2.97573 102.519 2.7217 101.843 2.71827V2.62392H104.831Z" fill="white"/>
    <path d="M124.519 0.999046C124.98 0.999046 125.473 0.999046 126.008 1.07243C126.542 1.14581 127.056 1.19823 127.57 1.28209L128.943 1.55465C129.277 1.62062 129.603 1.71893 129.918 1.84818V6.13577H129.53C129.551 5.57018 129.431 5.00827 129.183 4.49989C128.934 3.99151 128.564 3.55238 128.104 3.22146C127.095 2.49902 125.875 2.13047 124.634 2.17315C123.23 2.11419 121.855 2.58994 120.787 3.50451C120.326 3.88802 119.955 4.36887 119.701 4.91259C119.447 5.45631 119.317 6.04943 119.32 6.64944C119.337 7.24001 119.461 7.82256 119.686 8.36867C119.971 9.08947 120.441 9.7223 121.049 10.2032L129.572 17.2374C130.346 17.8406 130.943 18.6408 131.302 19.5541C131.576 20.3217 131.721 21.1293 131.732 21.9443C131.77 22.9238 131.595 23.8999 131.218 24.805C130.842 25.7101 130.273 26.5224 129.551 27.1858C127.921 28.5588 125.83 29.2597 123.701 29.1462C122.88 29.1329 122.061 29.0664 121.248 28.947C120.319 28.819 119.405 28.5942 118.523 28.2761C117.723 27.9974 116.984 27.5706 116.342 27.0181C116.246 26.5067 116.2 25.987 116.206 25.4666C116.211 24.8128 116.271 24.1606 116.384 23.5167C116.488 22.8824 116.646 22.2582 116.856 21.6508H117.254C117.087 22.8267 117.281 24.0257 117.81 25.0892C118.316 26.0503 119.112 26.8278 120.085 27.3116C121.288 27.871 122.622 28.0905 123.941 27.9464C125.261 27.8023 126.515 27.3 127.57 26.4939C128.126 26.058 128.571 25.4962 128.869 24.8547C129.166 24.2132 129.306 23.5103 129.279 22.8039C129.287 22.1059 129.141 21.4147 128.849 20.7807C128.552 20.1689 128.122 19.6316 127.591 19.2082L119.288 12.3313C118.467 11.7092 117.821 10.886 117.412 9.94113C117.088 9.17764 116.914 8.35916 116.898 7.53002C116.899 6.42376 117.195 5.33785 117.758 4.38509C118.371 3.34268 119.265 2.49293 120.336 1.93204C121.628 1.26853 123.068 0.947304 124.519 0.999046ZM129.928 1.38692V2.19412H126.637V1.38692H129.928Z" fill="white"/>
    <path d="M149.394 0.99919C149.855 0.99919 150.348 0.99919 150.882 1.07257C151.417 1.14595 151.931 1.19837 152.444 1.28223L153.828 1.55479C154.162 1.62077 154.488 1.71907 154.803 1.84832V6.13591H154.415C154.436 5.57033 154.316 5.00842 154.068 4.50004C153.819 3.99166 153.448 3.55252 152.989 3.22161C151.98 2.49916 150.76 2.13061 149.519 2.1733C148.132 2.11918 146.774 2.58667 145.714 3.48369C145.255 3.8691 144.885 4.35033 144.632 4.89362C144.378 5.4369 144.247 6.02908 144.246 6.62862C144.264 7.21919 144.388 7.80174 144.613 8.34784C144.902 9.06674 145.371 9.69875 145.976 10.1824L154.499 17.2165C155.26 17.8123 155.846 18.6016 156.197 19.5019C156.471 20.2694 156.616 21.077 156.627 21.892C156.665 22.8715 156.49 23.8476 156.113 24.7527C155.737 25.6578 155.168 26.4702 154.446 27.1336C152.808 28.5407 150.69 29.2617 148.534 29.1463C147.713 29.1331 146.893 29.0665 146.081 28.9471C145.152 28.8192 144.238 28.5943 143.355 28.2762C142.556 27.9975 141.816 27.5708 141.175 27.0182C141.078 26.5068 141.033 25.9871 141.039 25.4667C141.044 24.813 141.103 24.1607 141.217 23.5169C141.321 22.8826 141.478 22.2583 141.689 21.6509H142.087C141.92 22.8269 142.114 24.0258 142.643 25.0894C143.149 26.0504 143.945 26.8279 144.917 27.3118C146.121 27.8712 147.454 28.0907 148.774 27.9465C150.093 27.8024 151.348 27.3001 152.402 26.4941C152.96 26.055 153.405 25.4893 153.7 24.8439C153.995 24.1986 154.133 23.4921 154.101 22.7831C154.113 22.0859 153.969 21.3948 153.681 20.7598C153.385 20.1481 152.955 19.6107 152.423 19.1874L144.121 12.3105C143.322 11.6877 142.694 10.8726 142.297 9.94127C141.973 9.17778 141.798 8.35931 141.783 7.53016C141.784 6.42391 142.08 5.338 142.643 4.38523C143.256 3.34283 144.15 2.49307 145.221 1.93219C146.51 1.27043 147.946 0.94925 149.394 0.99919ZM154.803 1.38706V2.19426H151.511V1.38706H154.803Z" fill="white"/>
    <path d="M169.469 4.35364H169.343C169.344 4.02316 169.27 3.69685 169.126 3.39912C168.983 3.10139 168.774 2.83996 168.515 2.63441C167.984 2.15393 167.292 1.88859 166.576 1.89011H166.052V1.50223H169.511L169.469 4.35364ZM169.469 25.7601V28.5801H166.01V28.1922H166.576C167.282 28.2042 167.967 27.9497 168.494 27.4794C168.748 27.2678 168.954 27.0056 169.101 26.7095C169.247 26.4134 169.329 26.09 169.343 25.7601H169.469ZM172.153 1.53368V28.5801H169.343V1.53368H172.153ZM183.831 1.53368V2.73924H172.027V1.53368H183.831ZM181.976 14.669V15.8326H172.027V14.669H181.976ZM183.831 27.385V28.5801H172.027V27.385H183.831ZM186.473 22.3217L183.967 28.6115H176.786L178.338 27.4165C179.616 27.4591 180.889 27.2415 182.08 26.777C183.004 26.3715 183.814 25.7451 184.439 24.9529C185.07 24.1382 185.608 23.2557 186.043 22.3217H186.473ZM183.831 0.841797V1.96349L178.589 1.53368C179.262 1.53472 179.934 1.49622 180.602 1.41837C181.305 1.34499 181.965 1.25064 182.563 1.14581C182.995 1.0887 183.42 0.98682 183.831 0.841797V0.841797ZM181.976 12.195V14.7529H179.46V14.669C180.009 14.6605 180.536 14.4475 180.938 14.0715C181.129 13.9061 181.282 13.7017 181.387 13.4719C181.491 13.2422 181.546 12.9927 181.546 12.7401V12.195H181.976ZM181.976 15.7488V18.3066H181.546V17.7196C181.544 17.4673 181.49 17.2181 181.385 16.9886C181.28 16.7591 181.128 16.5545 180.938 16.3882C180.536 16.0175 180.006 15.8181 179.46 15.8326V15.7488H181.976ZM183.831 2.62392V5.70596H183.454V5.03504C183.455 4.73043 183.39 4.42915 183.264 4.15216C183.137 3.87517 182.951 3.62911 182.72 3.43112C182.224 2.97814 181.578 2.72429 180.906 2.71827V2.62392H183.831Z" fill="white"/>
  </svg>
);

export default FinesseWhiteLogo;