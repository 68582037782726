import { ADDED, MODIFIED, REMOVED } from "constants/change-types";
import {
  addUser,
  removeUser,
  updateUser,
  updateUserStatus,
} from "slices/userSlice";
import firebase from "firebase/app";
import "firebase/firestore";
import { ACTIVE } from "constants/status";
import { firestore, functions } from "../../firebase";

const prepareUser = (userData) => {
  const user = { ...userData };
  user.createdAt = user.createdAt.toDate().toLocaleDateString();
  return user;
};

const subscribeToUsers = (dispatch) =>
  firestore
    .collection("users")
    .where("accessLevel", "!=", "store")
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (snapshot.empty) {
          dispatch(updateUserStatus(ACTIVE));
        }
        const user = prepareUser(change.doc.data());
        if (change.type === ADDED) {
          dispatch(addUser(user));
        }
        if (change.type === REMOVED) {
          dispatch(removeUser(user));
        }
        if (change.type === MODIFIED) {
          dispatch(updateUser(user));
        }
      });
    });

const createUser = async (userData) => {
  const onboardFunction = functions.httpsCallable("createUserAccount");
  const payload = {
    userData,
    continueUrl: window.location.origin,
  };
  return onboardFunction(payload);
};

const updateUserDocument = async (userData) =>
  firestore
    .collection("users")
    .doc(userData.uid)
    .set(userData, { merge: true });

const removeStoreGroupUID = (userData) =>
  firestore.collection("users").doc(userData.uid).update({
    storeGroupUID: firebase.firestore.FieldValue.delete(),
  });

const deleteUser = async (userData) => {
  const deleteFunction = functions.httpsCallable("deleteUserAccount");
  return deleteFunction({ userUID: userData.uid });
};

const updateAuthAccount = async (authUpdates) => {
  const updateAuthFunction = functions.httpsCallable("updateAuthAccount");
  const payload = {
    authUpdates,
  };
  return updateAuthFunction(payload);
};

const getUserDocument = (userUID) =>
  firestore.collection("users").doc(userUID).get();

const resendOnboardingEmail = async (userUID) => {
  const resendOnboardingFunction = functions.httpsCallable("resendOnboarding");
  const payload = {
    userUID,
    continueUrl: window.location.origin,
  };
  return resendOnboardingFunction(payload);
};

export default {
  subscribeToUsers,
  createUser,
  updateUserDocument,
  deleteUser,
  removeStoreGroupUID,
  updateAuthAccount,
  getUserDocument,
  resendOnboardingEmail,
};
