import { ADMIN, STORE, STORE_GROUP } from "./access-level";
import {
  ANALYTICS_ROUTE,
  CONFIGURATIONS_ROUTE,
  CREATE_ORDER_ROUTE,
  GROUP_MANAGEMENT_ROUTE,
  HOME_ROUTE,
  SAVED_QUOTES_ROUTE,
  STORE_MANAGEMENT_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from "./routes";

export const SIDEBAR_ITEMS = {
  [ADMIN]: [
    {
      label: "Orders",
      iconName: "Bag",
      route: HOME_ROUTE,
    },
    {
      label: "Analytics",
      iconName: "Analytics",
      route: ANALYTICS_ROUTE,
    },
    {
      label: "Saved Quotes",
      iconName: "Document",
      route: SAVED_QUOTES_ROUTE,
    },
    {
      label: "User Management",
      iconName: "User",
      route: USER_MANAGEMENT_ROUTE,
    },
    {
      label: "Group Management",
      iconName: "Group",
      route: GROUP_MANAGEMENT_ROUTE,
    },
    {
      label: "Store Management",
      iconName: "Store",
      route: STORE_MANAGEMENT_ROUTE,
    },
    {
      label: "Product/Pricing Configs",
      iconName: "Pricing",
      route: CONFIGURATIONS_ROUTE,
    },
  ],
  [STORE_GROUP]: [
    {
      label: "Order History",
      iconName: "Bag",
      route: HOME_ROUTE,
    },
  ],
  [STORE]: [
    {
      label: "Order History",
      iconName: "Bag",
      route: HOME_ROUTE,
    },
    {
      label: "Saved Quotes",
      iconName: "Document",
      route: SAVED_QUOTES_ROUTE,
    },
    {
      label: "+ Create an order",
      route: CREATE_ORDER_ROUTE,
      type: "button",
    },
  ],
};

export default SIDEBAR_ITEMS;
