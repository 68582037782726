import React, { useContext, useEffect } from "react";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import OrderItemSelector from "components/OrderItemSelector/OrderItemSelector";
import { ORDER_CATEGORIES } from "constants/order-selection-items";
import { HOME_ROUTE, SELECT_PRODUCT_ROUTE } from "constants/routes";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  resetCreateOrderState,
  updateOrderProductCategory,
} from "slices/createOrderSlice";
import OrderCreationHeader from "components/OrderCreationHeader/OrderCreationHeader";
import { UserContext } from "providers/UserProvider";

const ProductSelectionPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isDunelmUser } = useContext(UserContext);

  useEffect(() => {
    dispatch(resetCreateOrderState());
  }, [dispatch]);

  const selectCategory = (productCategory) => {
    dispatch(updateOrderProductCategory({ productCategory }));
    history.push(SELECT_PRODUCT_ROUTE);
  };

  const getCategoriesForSelection = () =>
    isDunelmUser()
      ? ORDER_CATEGORIES.filter((category) => category.hideForDunelm !== true)
      : ORDER_CATEGORIES;

  return (
    <div className="flex flex-col h-full">
      <OrderCreationHeader>
        <ActionButton
          icon="BackArrow"
          iconSide="left"
          label="Back to Dashboard"
          background="outline"
          onClick={() => history.push(HOME_ROUTE)}
          className="px-14 h-full absolute"
        />
        <div className="w-full text-center text-lg font-bold">
          Create an order
        </div>
      </OrderCreationHeader>
      <OrderItemSelector
        header="Select product category to begin the order"
        instruction="Choose from the following product categories below "
        items={getCategoriesForSelection()}
        onClick={(productCategory) => selectCategory(productCategory)}
      />
    </div>
  );
};

export default ProductSelectionPage;
