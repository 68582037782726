import React, { useEffect, useState } from "react";
import ActionHeader from "components/ActionHeader/ActionHeader";
import { useHistory, useLocation } from "react-router";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import { useSelector } from "react-redux";
import { selectOrderFromUID } from "slices/orderSlice";
import { convertNumberToGBP } from "support/helpers";
import { selectStoreFromUID } from "slices/storeSlice";
import API from "services/API";
import { showErrorToast } from "services/Toasts/toastService";
import ItemsList from "components/ViewOrder/ItemsList";
import SummaryDetails from "components/ViewOrder/SummaryDetails";
import Spinner from "components/Spinner/Spinner";

const ViewOrderPage = () => {
  const location = useLocation();
  const history = useHistory();
  const orderUID = location.state ? location.state.orderUID : null;
  const order = useSelector((state) => selectOrderFromUID(state, orderUID));
  const store = useSelector((state) =>
    selectStoreFromUID(state, order.storeUID)
  );
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getItemsForOrder = async () => {
      try {
        const orderItems = [];
        const querySnapshot = await API.orders.getOrderItems(order);
        querySnapshot.forEach((doc) => orderItems.push(doc.data()));
        setItems(orderItems);
        setLoading(false);
      } catch (error) {
        showErrorToast(
          "Error.",
          "An error occured when trying to retrieve the products in this order. Please try again.",
          10000
        );
      }
    };
    getItemsForOrder();
  }, [order]);

  return (
    <div id="view-order-page" className="h-full flex flex-col">
      <ActionHeader>
        <div className="flex justify-between w-full">
          <ActionButton
            label={order.orderNumber ? `Order #${order.orderNumber}` : ''}
            onClick={() => history.goBack()}
            type="button"
            icon="BackArrow"
            iconSide="left"
            background="none"
            className="font-bold text-xl"
          />
          <div className="flex items-center">
            <div className="flex font-bold items-end">
              <div>Total Price: </div>
              <div className="text-xl ml-2">
                {convertNumberToGBP(order.price)}
              </div>
            </div>
          </div>
        </div>
      </ActionHeader>

      <div className="grid grid-cols-2 flex-grow border-t overflow-hidden">
        {loading ? (
          <div className="flex-grow flex items-center justify-center">
            <Spinner size={40} />
          </div>
        ) : (
          <ItemsList
            items={items}
            productCategory={order.productCategory}
            showAddButton={false}
          />
        )}
        <SummaryDetails store={store} order={order} showEditButtons={false} />
      </div>
    </div>
  );
};

export default ViewOrderPage;
