import React from 'react';
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import FinesseLogo from "../../components/Logo/FinesseLogo";

const ForgotPasswordPage = () => (
  <div id="forgot-password-page" className="h-full flex">
    <div className="container m-auto flex justify-center">
      <div className="w-4/12">
        <div className="flex justify-center mb-10">
          <FinesseLogo />
        </div>

        <ForgotPassword />
      </div>
    </div>
  </div>
);

export default ForgotPasswordPage;