import React, { useEffect, useState } from "react";
import Table from "components/Table/Table";
import ActionButton from "components/Buttons/ActionButton/ActionButton";
import Search from "components/Search/Search";
import ActionHeader from "components/ActionHeader/ActionHeader";
import { selectAllUsers } from "slices/userSlice";
import { IDLE } from "constants/status";
import Spinner from "components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { selectAllStoreGroups } from "slices/storeGroupSlice";
import { USERS_HEADINGS } from "constants/table-headings";
import {
  checkSearchQuery,
  getStoreGroupNameFromUID,
  getUserTypeFromAccessLevel,
} from "support/helpers";
import { ADD_USER_ROUTE, EDIT_USER_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";

const UserManagementPage = () => {
  const users = useSelector(selectAllUsers);
  const usersSubscriptionStatus = useSelector((state) => state.users.status);
  const groups = useSelector(selectAllStoreGroups);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();

  useEffect(() => {
    const preparedUsers = users
      .map((user) => ({
        key: user.uid,
        values: {
          name: user.name,
          email: user.emailAddress,
          type: getUserTypeFromAccessLevel(user.accessLevel),
          group: getStoreGroupNameFromUID(user.storeGroupUID, groups),
          createdAt: user.createdAt,
          action: (
            <ActionButton
              label="Edit"
              background="none"
              onClick={() =>
                history.push(EDIT_USER_ROUTE, { userUID: user.uid })
              }
              type="button"
              icon="Pencil"
            />
          ),
        },
      }))
      .filter((user) => {
        const searchFields = [user.values.name, user.values.group];
        return checkSearchQuery(searchQuery, searchFields);
      });
    setFilteredUsers(preparedUsers);
  }, [history, users, groups, searchQuery]);

  return (
    <div id="user-management-page" className="h-full flex flex-col">
      {usersSubscriptionStatus === IDLE ? (
        <div className="flex flex-grow justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <ActionHeader>
            <div className="flex justify-between w-full">
              <ActionButton
                label="Add user"
                onClick={() => history.push(ADD_USER_ROUTE)}
                type="button"
                icon="Add"
              />
              <div className="w-3/12 content-end flex-none">
                <Search
                  placeholder="Search for name or group"
                  onChange={(event) => setSearchQuery(event.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>
          </ActionHeader>
          <Table headings={USERS_HEADINGS} items={filteredUsers} />
        </>
      )}
    </div>
  );
};

export default UserManagementPage;
