import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ADMIN } from "constants/access-level";
import { auth } from "../firebase";
import API from "../services/API";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged(setCurrentUser);

    const getUserData = async () => {
      setLoading(true);
      const userDocument = await API.users.getUserDocument(currentUser.uid);
      const userData = userDocument.data();

      setCurrentUser((prevState) => ({
        ...prevState,
        data: userData,
      }));

      if (userData.accessLevel !== ADMIN) {
        const userGroupData = await API.storeGroups.getGroupData(
          userData.storeGroupUID
        );

        setCurrentUser((prevState) => ({
          ...prevState,
          storeGroupData: userGroupData,
        }));
      }

      setLoading(false);
    };

    if (currentUser?.uid) {
      getUserData();
    }
  }, [currentUser?.uid]);

  const getUserAccessLevel = () => currentUser?.data?.accessLevel;

  const isDunelmUser = () => currentUser?.storeGroupData?.name === "Dunelm";

  return (
    <UserContext.Provider
      value={{
        currentUser,
        getUserAccessLevel,
        isDunelmUser,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = propTypes;

export default UserProvider;
