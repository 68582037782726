import { ADDED, MODIFIED, REMOVED } from "constants/change-types";
import { ACTIVE } from "constants/status";
import {
  addConfig,
  removeConfig,
  updateConfig,
  updateConfigSliceStatus,
} from "slices/configSlice";
import { toBase64 } from "support/helpers";
import { firestore, functions, storage } from "../../firebase";

const configVersionsCollectionReference = firestore.collection(
  "configVersions"
);

const prepareConfig = (data) => {
  data.lastUpdated = data.lastUpdated.toDate().toLocaleDateString();
  return data;
};

const subscribeToConfigs = (dispatch) =>
  configVersionsCollectionReference.onSnapshot((snapshot) => {
    if (snapshot.empty) {
      dispatch(updateConfigSliceStatus(ACTIVE));
    }
    snapshot.docChanges().forEach((change) => {
      const config = prepareConfig(change.doc.data());
      if (change.type === ADDED) {
        dispatch(addConfig(config));
      }
      if (change.type === REMOVED) {
        dispatch(removeConfig(config));
      }
      if (change.type === MODIFIED) {
        dispatch(updateConfig(config));
      }
    });
  });

const updatePricingConfig = async (
  file,
  priceVariables,
  productCategory,
  productSubcategory,
  configUID
) => {
  try {
    const csvUrl = await toBase64(file);
    const generatePricingJSON = functions.httpsCallable("generatePricingJSON");
    const payload = {
      priceVariables,
      csvUrl,
      configUID,
      productCategory,
      productSubcategory,
    };
    return generatePricingJSON(payload);
  } catch (error) {
    throw error;
  }
};

const updateProductConfig = async (
  file,
  options,
  productCategory,
  configUID
) => {
  try {
    const csvUrl = await toBase64(file);
    const generateProductJSON = functions.httpsCallable("generateProductJSON");
    const payload = {
      options,
      csvUrl,
      configUID,
      productCategory,
    };
    return generateProductJSON(payload);
  } catch (error) {
    throw error;
  }
};

const createConfig = async (configData) => {
  const newConfigRef = configVersionsCollectionReference.doc();
  configData.uid = newConfigRef.id;
  await newConfigRef.set(configData);
  return configData.uid;
};

const updateConfigRecord = async (configData) =>
  configVersionsCollectionReference
    .doc(configData.uid)
    .set(configData, {merge: true})

const getConfigVersionData = async (configVersionUID) => {
  const configSnapshot = await configVersionsCollectionReference
    .doc(configVersionUID)
    .get();
  return configSnapshot.data();
};

const getConfigJsonFile = async (
  timestamp,
  configVersionUID,
  configType,
  category,
  subcategory
) => {
  const storagePath =
    configType === "pricing"
      ? `/configs/${configVersionUID}/${configType}/${category}/${subcategory}/${timestamp}.json`
      : `/configs/${configVersionUID}/${configType}/${category}/${timestamp}.json`;
  const pathReference = storage.ref(storagePath);
  const downloadUrl = await pathReference.getDownloadURL();
  const response = await fetch(downloadUrl);
  return response.json();
};

export default {
  subscribeToConfigs,
  updatePricingConfig,
  updateProductConfig,
  updateConfigRecord,
  createConfig,
  getConfigVersionData,
  getConfigJsonFile,
};
