import React from 'react';
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

const ActionHeader = ({children}) => (
  <div className="bg-white w-full py-7 px-9 min-h-100 flex">
      {children}
  </div>
);

ActionHeader.propTypes = propTypes;

export default ActionHeader;