import React from "react";
import PropTypes from "prop-types";
import { INPUT_TYPES } from "constants/form-input-types";

const propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([INPUT_TYPES.TEXT, INPUT_TYPES.NUMBER, INPUT_TYPES.PASSWORD]),
  ]),
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.string,
};

const defaultProps = {
  type: "text",
  className: "",
  value: "",
  error: null,
  label: null,
  placeholder: null,
};

const FormInput = ({
  name,
  type,
  placeholder,
  onChange,
  className,
  value,
  error,
  label,
  min,
}) => {
  const defaultClasses = ["block", "w-full", "rounded-md", "p-3", "border"];
  const additionalClasses = className.split(" ");

  const classes = [...defaultClasses, ...additionalClasses];

  return (
    <div>
      {label && (
        <label htmlFor={name} className="block mb-2 text-sm font-bold">
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={classes.join(" ")}
        style={error && { border: "solid 1px red" }}
        min={min}
      />
      {error && <p className="text-sm mt-2 text-red-600">{error}</p>}
    </div>
  );
};

FormInput.defaultProps = defaultProps;

FormInput.propTypes = propTypes;

export default FormInput;
