import React from "react";
import PropTypes from "prop-types";
import { getImage } from "support/image";

const propTypes = {
  header: PropTypes.string.isRequired,
  instruction: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  instruction: null,
};

const OrderItemSelector = ({ header, instruction, items, onClick }) => (
  <div className="flex-grow flex items-center justify-center p-9">
    <div className="container mb-32">
      <div className="text-primary text-2xl font-bold">{header}</div>
      {instruction && <div>{instruction}</div>}
      <div className="flex flex-col md:flex-row py-6 mt-6 border-t border-b items-center justify-center">
        {items.map((item) => (
          <button
            key={item.key}
            className="flex-grow ml-0 md:ml-9 first:ml-0 mt-9 md:mt-0 first:mt-0 max-w-md"
            onClick={() => onClick(item.key)}
          >
            <div
              key={item.key}
              className="flex-grow h-56 md:h-96 rounded-md shadow-sm bg-cover bg-center"
              style={{
                backgroundImage: `url(${getImage(item.key)})`,
              }}
            />
            <div className="text-center text-lg text-primary mt-6">
              {item.label}
            </div>
          </button>
        ))}
      </div>
    </div>
  </div>
);

OrderItemSelector.propTypes = propTypes;

OrderItemSelector.defaultProps = defaultProps;

export default OrderItemSelector;
